import axios from "../../axios-auth";
import router from "../../router";
const state = {
  comments: [],
  commentCompetitionId: null,
};

const mutations = {
  comments(state, data) {
    state.comments = data;
  },
  commentCompetitionId(state, data) {
    state.commentCompetitionId = data;
  },
  resetcomments(state) {
    state.comments = [];
  },
  deleteComment(state, id) {
    state.comments.map((c, index) => {
      if (c._id === id) {
        state.comments.splice(index, 1);
      }
    });
  },
};

const actions = {
  newCommentHandler({ commit, rootState, dispatch }, comment) {
    if (comment.value === "") {
    } else {
      if (typeof rootState.token !== "undefined") {
        commit("loading", true);
        const url = "/comments";
        const body = {
          video_id: comment.id,
          body: comment.value,
        };
        axios
          .post(url, body, { headers: { "X-Auth-Token": rootState.token } })
          .then((res) => {
            commit("lastComment", { value: res.data.comment, id: comment.id });
            commit("lastCommentSearch", {
              value: res.data.comment,
              id: comment.id,
            });
            commit("lastCommentSingle", {
              value: res.data.comment,
              id: comment.id,
            });
            dispatch("fetchComments", comment.id);
          })
          .catch((error) => {
            console.log("ERROR post comments: " + error);
            commit("loading", false);
          });
      } else {
        commit("status", 400);
        router.push("/error");
      }
    }
  },
  deleteCommentHandler({ commit, rootState, dispatch }, data) {
    if (typeof rootState.token !== "undefined") {
      commit("loading", true);
      const url = "/comments/" + data.commentId;
      axios
        .delete(url, { headers: { "X-Auth-Token": rootState.token } })
        .then((res) => {
          commit("deleteComment", data.commentId);
          commit("deleteLastcomment", {
            id: data.videoId,
            lastComment: res.data.lastComment,
          });
          dispatch("fetchComments", data.videoId);
          commit("loading", false);
        })
        .catch((error) => {
          console.log("ERROR deleteComment: " + error);
          commit("loading", false);
        });
    } else {
      commit("status", 400);
      router.push("/error");
    }
  },
  fetchComments({ commit, rootState }, videoId) {
    if (typeof rootState.token !== "undefined") {
      commit("loading", true);
      const url = "/comments";
      const params = {
        video_id: videoId,
      };
      axios
        .get(url, { params, headers: { "X-Auth-Token": rootState.token } })
        .then((res) => {
          commit("comments", res.data.comments);
          commit("loading", false);
        })
        .catch((error) => {
          console.log("ERROR fetchComments: " + error);
          commit("loading", false);
        });
    } else {
      commit("status", 400);
      router.push("/error");
    }
  },
  fetchSingleComments({ commit, rootState }) {
    if (typeof rootState.token !== "undefined") {
      commit("loading", true);
      let url = "/comments";
      const params = {
        video_id: rootState.singleComment,
      };
      axios
        .get(url, { params, headers: { "X-Auth-Token": rootState.token } })
        .then((res) => {
          commit("comments", res.data.comments);
          url = "/home/single";
          const params = {
            video_id: rootState.singleComment,
          };
          axios
            .get(url, { params, headers: { "X-Auth-Token": rootState.token } })
            .then((res) => {
              commit("commentCompetitionId", res.data.competition_id);
              commit("loading", false);
            })
            .catch((error) => {
              console.log("ERROR fetchSearch: " + error);
              commit("loading", false);
            });
        })
        .catch((error) => {
          console.log("ERROR fetchComments: " + error);
          commit("loading", false);
        });
    } else {
      commit("status", 400);
      router.push("/error");
    }
  },
  resetComments({ commit }) {
    commit("resetComments");
  },
};

const getters = {
  comments: (state) => {
    return state.comments;
  },
  commentCompetitionId: (state) => {
    return state.commentCompetitionId;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
