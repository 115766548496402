<template>
  <div class="content" :style="cssVariable">
    <upload></upload>
  </div>
</template>

<script>
import UploadModule from '@/components/Upload/UploadModule.vue'
export default {
  name: 'uploadView',
  data () {
    return {
    }
  },
  components: {
    Upload: UploadModule
  },
  computed: {
    cssVariable () {
      let height = this.$store.getters.screenHeight - 105
      return {
        '--height': height + 'px'
      }
    }
  }
}
</script>

<style scoped>
.content{
  overflow: hidden;
  margin: 110px 0 0 0;
  background-color: #fff;
  width: 100%;
  height: var(--height);
}
</style>
