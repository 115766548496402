<template>
  <div class="main-container">
    <Header :countdown="countdown()" />
    <div style="overflow: hidden;">
      <transition name="fade" mode="out-in" appear>
        <!-- <keep-alive> -->
        <router-view :key="$route.fullPath"></router-view>
        <!-- </keep-alive> -->
      </transition>
    </div>
    <FooterTabs :id="getId" :active="activeFooter" />
    <!-- <div v-if="getmessage"></div> -->
  </div>
</template>

<script>
import Header from "@/components/Layout/Header.vue";
import FooterTabs from "@/components/Layout/FooterTabs.vue";
export default {
  name: "mainView",
  data() {
    return {
      messages: ""
    };
  },
  created() {
    this.$store.dispatch("checkCompetition", this.$route.params.id);
    this.$store.dispatch("fetchCountdown");
  },
  watch: {
    countdown: {
      handler: "countdown",
      immediate: true
    }
  },
  computed: {
    activeFooter() {
      return this.$route.name;
    },
    bkColor() {
      return this.$store.getters.secondaryColor;
    },
    getId() {
      return this.$route.params.id;
    },
    currentComp() {
      // return this.$store.getters.currentComp
      return this.$store.getters.currentComp;
    }
  },
  components: {
    Header,
    FooterTabs
  },
  methods: {
    countdown() {
      if (this.$store.getters.countdown == null) {
        // this.$store.dispatch('fetchCountdown')
      }
      return this.$store.getters.countdown * 1000;
    }
  }
};
</script>

<style>
.main-container {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>
