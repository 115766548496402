<template>
  <div class="modal is-active is-clipped">
    <div class="modal-card" :style="height">
      <header class="modal-card-head" :style="cssVariable">
        <i class="fas fa-chevron-left" @click="closeModal" style="margin-right: 20px;font-size:24px"></i>
        <p class="modal-card-title" :style="lang === 'ar' ? {textAlign: 'right'}:{textAlign: 'left'}">{{$t('languageSelectionTitle')}}</p>
      </header>
      <section class="modal-card-body" style="padding: 15px 0;">
        <div style="text-align: start;padding: 10px 20px ;">
          <h6>{{$t('languageSelectionTitle')}}</h6>
        </div>
        <div>
          <div style="text-align: center;">
          <label class="radio" style="margin: 0 20px;">
            <input type="radio" @change="changeLang" value="0" v-model="selectedLanguage">
            English
          </label>
          <label class="radio" style="margin: 0 20px;">
            <input type="radio" @change="changeLang" value="1" v-model="selectedLanguage">
            عربي
          </label>
        </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {

  data () {
    return {
      selectedLanguage: this.$store.state.curLanguage.short === 'en' ? 0 : 1
    }
  },
  mounted () {
    this.langSelected = this.$store.state.curLanguage.long
  },
  methods: {
    closeModal () {
      this.$router.back()
    },
    changeLang (e) {
      Vue.i18n.set(this.languages[e.target.value].short)
      localStorage.setItem('lang', e.target.value)
      this.$store.dispatch('setLang', e.target.value)
    }

  },
  computed: {
    languages () {
      return this.$store.getters.allLanguages
    },
    cssVariable () {
      return {
        '--color': this.$store.getters.primaryColor,
        '--bkcolor': this.$store.getters.secondaryColor
      }
    },
    lang () {
      return this.$store.getters.lang
    },
    height () {
      return {
        '--height': this.$store.getters.screenHeight + 'px'
      }
    }
  }
}
</script>

<style scoped>
  /* custom modal style */
  .modal-card {
    max-height: var(--height) !important;
    height: var(--height);
  }
  .modal-card-head{
    background-color: var(--bkcolor)!important;
    color: var(--color)!important;
    border-radius: 0!important;
    direction: ltr;
  }
  .modal-card-title {
    color: var(--color)!important;
  }
</style>
