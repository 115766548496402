<template>
  <div style="height: 100%; width: 100%">
    <div class="upload-video-container">
      <!-- <iframe v-if="src !== '' && isMobile()" :src="src" frameborder="0" style="width: 100%;height:100%"></iframe> -->
      <video
        v-if="src !== ''"
        id="video"
        ref="video"
        :src="src"
        controls
        :style="isMobile() ? { display: 'none' } : {}"
      ></video>
      <div v-else class="upload-text-container">
        <span class="upload-text">{{ $t("uploadText") }}</span>
        <div class="upload-text-img-container">
          <div class="upload-text-img">
            <img :src="arrow" style="height: 100%" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="upload-actions-buttons"
      :style="src === '' ? { height: '42px' } : { height: '130px' }"
    >
      <div class="upload-button" v-if="src === '' && !uploading">
        <label class="file-label">
          <input
            class="file-input"
            id="file"
            type="file"
            ref="file"
            v-on:change="handleFileUpload()"
            accept="video/*"
          />
          <span style="margin: 0 auto">
            <img style="width: 40px; height: 40px" v-lazysizes :src="upload" />
          </span>
        </label>
      </div>
      <div v-else-if="!uploading">
        <div class="caption-input">
          <label style="margin: 5px; width: 97%">
            <md-field>
              <label
                :style="
                  lang === 'ar'
                    ? { color: '#D0D0D0', right: '10px', left: 'auto' }
                    : { color: '#D0D0D0', left: '10px', right: 'auto' }
                "
                >{{ $t("uploadVideoCaptionPlaceholder") }}</label
              >
              <md-textarea
                class="textarea"
                v-model="caption"
                rows="1"
                id="caption"
                md-autogrow
              ></md-textarea>
            </md-field>
          </label>
        </div>
        <div class="submit-buttons">
          <div class="submit-button-cancel" @click="cancelVideo">
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="Icons/close"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <path
                  d="M11.8766417,2.00829893 C11.4329138,2.0684958 11.0909091,2.44885362 11.0909091,2.90909091 L11.089697,11.0909091 L2.90909091,11.0909091 L2.7730982,11.1018835 C2.3164165,11.1835228 2,11.558745 2,12 L2.00829893,12.1233583 C2.0684958,12.5670862 2.44885362,12.9090909 2.90909091,12.9090909 L11.089697,12.9090909 L11.0909091,21.0909091 L11.1018835,21.2269018 C11.1835228,21.6835835 11.558745,22 12,22 L12.1233583,21.9917011 C12.5670862,21.9315042 12.9090909,21.5511464 12.9090909,21.0909091 L12.9078788,12.9090909 L21.0909091,12.9090909 L21.2269018,12.8981165 C21.6835835,12.8164772 22,12.441255 22,12 L21.9917011,11.8766417 C21.9315042,11.4329138 21.5511464,11.0909091 21.0909091,11.0909091 L12.9078788,11.0909091 L12.9090909,2.90909091 L12.8981165,2.7730982 C12.8164772,2.3164165 12.441255,2 12,2 L11.8766417,2.00829893 Z"
                  id="Combined-Shape"
                  fill="#fff"
                  transform="translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) "
                ></path>
              </g>
            </svg>
          </div>
          <div class="submit-button-ok" @click="submitVideo">
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="Icons/valid-2"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <path
                  d="M19.1738528,5.26988051 C19.4390259,4.95167283 19.9119494,4.90867979 20.2301571,5.17385285 C20.5194368,5.41491927 20.5812664,5.82768028 20.390889,6.13980959 L20.3261848,6.23015711 L9.84604647,18.8063231 L3.26988051,13.3261848 C2.95167283,13.0610117 2.90867979,12.5880882 3.17385285,12.2698805 C3.41491927,11.9806008 3.82768028,11.9187712 4.13980959,12.1091486 L4.23015711,12.1738528 L9.65301881,16.6930188 L19.1738528,5.26988051 Z"
                  id="Path-8"
                  fill="#fff"
                ></path>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div class="upload-progress-container" v-if="uploading && progress !== 0">
        <div class="upload-progress">
          <div class="logo-container" v-if="progress !== 101">
            <img
              id="logo"
              v-lazysizes
              :src="brandLogo"
              :style="
                uploading
                  ? { width: '100px' }
                  : { display: 'none', width: '200px' }
              "
            />
          </div>
          <div>
            <div v-if="progress < 100">
              <span
                v-if="lang === 'ar'"
                style="display: block"
                class="progress-number"
                >{{ progress | arabicize }}%</span
              >
              <span v-else style="display: block" class="progress-number"
                >{{ progress }}%</span
              >
              <span class="progress-text">{{ $t("uploading") }}</span>
            </div>
            <div v-if="progress === 100">
              <span
                v-if="lang === 'ar'"
                style="display: block"
                class="progress-number"
                >{{ progress | arabicize }}%</span
              >
              <span v-else style="display: block" class="progress-number"
                >{{ progress }}%</span
              >
              <span class="progress-text">{{ $t("saving") }}</span>
            </div>
            <div v-else-if="progress === 101">
              <div class="finish-upload-close" @click="backHome">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="Icons/close"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <path
                      d="M11.8766417,2.00829893 C11.4329138,2.0684958 11.0909091,2.44885362 11.0909091,2.90909091 L11.089697,11.0909091 L2.90909091,11.0909091 L2.7730982,11.1018835 C2.3164165,11.1835228 2,11.558745 2,12 L2.00829893,12.1233583 C2.0684958,12.5670862 2.44885362,12.9090909 2.90909091,12.9090909 L11.089697,12.9090909 L11.0909091,21.0909091 L11.1018835,21.2269018 C11.1835228,21.6835835 11.558745,22 12,22 L12.1233583,21.9917011 C12.5670862,21.9315042 12.9090909,21.5511464 12.9090909,21.0909091 L12.9078788,12.9090909 L21.0909091,12.9090909 L21.2269018,12.8981165 C21.6835835,12.8164772 22,12.441255 22,12 L21.9917011,11.8766417 C21.9315042,11.4329138 21.5511464,11.0909091 21.0909091,11.0909091 L12.9078788,11.0909091 L12.9090909,2.90909091 L12.8981165,2.7730982 C12.8164772,2.3164165 12.441255,2 12,2 L11.8766417,2.00829893 Z"
                      id="Combined-Shape"
                      fill="#111111"
                      transform="translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) "
                    ></path>
                  </g>
                </svg>
              </div>
              <div class="finish-upload-title">
                <span>{{ $t("finishUploadTitle") }}</span>
              </div>
              <div class="finish-upload-text">
                <span v-html="$t('finishUpload')"></span>
              </div>
              <div class="finish-upload-back">
                <button class="btn back" :style="cssVariable" @click="backHome">
                  {{ $t("gobackToHome") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UplaodImage from "../../assets/mainImage/record.png";
import Arrow from "@/assets/mainImage/arrowDown2.png";

export default {
  data() {
    return {
      file: "",
      src: "",
      caption: "",
      uploading: false,
      progressLoaded: true,
      upload: UplaodImage,
      arrow: Arrow,
    };
  },
  created() {
    this.$store.dispatch("resetProgress");
    this.$store.dispatch("checkGuest");
    // this.$store.dispatch('getVideoLimit');
  },
  methods: {
    isMobile() {
      if (/Android/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    backHome() {
      this.$router.push("/" + this.$route.params.id + "/");
    },
    handleFileUpload() {
      if (this.$refs.file.files[0]) {
        if (this.$refs.file.files[0].type.includes("video")) {
          this.file = this.$refs.file.files[0];
          this.src = URL.createObjectURL(this.file);
        } else {
          notie.alert({
            type: "error",
            text:
              this.$t("notValidVideo") +
              ", type:" +
              this.$refs.file.files[0].type,
          });
        }
      }
    },
    submitVideo() {
      if (!isFinite(this.$refs.video.duration)) {
        notie.alert({
          type: "error",
          text:
            this.$t("notValidVideo") +
            ", dutration:" +
            this.$refs.video.duration,
        });
        // alert('error please try wait for the video to load')
      } else if (this.caption.length >= 50) {
        notie.alert({ type: "error", text: this.$t("bigCaption") });
      } else if (this.caption.length === 0) {
        notie.alert({ type: "error", text: this.$t("smallCaption") });
      } else {
        let duration = Math.floor(parseFloat(this.$refs.video.duration));
        if (duration > this.videoLimit || duration < 5) {
          let message =
            "Your Video duration is more than the allowed champ. Upload a video not less than 5sec & not more than " +
            this.videoLimit +
            " sec";
          if (this.lang === "ar") {
            const arabicNums = [
              "٠",
              "١",
              "٢",
              "٣",
              "٤",
              "٥",
              "٦",
              "٧",
              "٨",
              "٩",
            ];
            let limit = this.videoLimit
              .toString()
              .replace(/[0-9]/g, function (w) {
                return arabicNums[+w];
              });
            message =
              " وقت الڤيديو بتاعك يا بطل مش مسموح بيه .. ارفع فيديو مش اقل من ٥ ثواني و مش اكتر من  " +
              limit +
              "  ثانيه ";
          }
          notie.alert({ type: "error", text: message });
          // alert('please add a video maximum 22 sec long and not less than 5 sec')
        } else {
          this.uploading = true;
          const data = {
            competition_id: this.$route.params.id,
            file: this.file,
            caption: this.caption,
          };
          this.$store.dispatch("uploadVideoHandler", data);
          this.file = "";
          this.src = "";
          this.caption = "";
          // this.uploading = false
        }
      }
    },
    cancelVideo() {
      this.file = "";
      this.src = "";
      this.caption = "";
    },
  },
  computed: {
    progress() {
      if (this.$store.getters.progress === -1 && this.uploading) {
        this.uploading = false;
      }
      return this.$store.getters.progress;
    },
    cssVariable() {
      return {
        "--color": this.$store.getters.primaryColor,
        "--bkcolor": this.$store.getters.secondaryColor,
      };
    },
    lang() {
      return this.$store.getters.lang;
    },
    brandLogo() {
      return this.$store.getters.brandLogo;
    },
    color() {
      return this.$store.getters.primaryColor;
    },
    currentComp() {
      return this.$store.getters.currentComp;
    },
    videoLimit() {
      return this.$store.getters.videoLimit;
    },
  },
  filters: {
    arabicize: function (value) {
      const arabicNums = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
      return value.toString().replace(/[0-9]/g, function (w) {
        return arabicNums[+w];
      });
    },
  },
};
</script>

<style scope>
.upload-video-container {
  width: 100%;
  height: 60%;
}
#video {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.upload-text-container {
  text-align: center;
  margin-top: 30px;
}
.upload-text {
  color: #101010;
  font-family: Cairo;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37px;
  text-align: center;
}
.upload-text-img-container {
  margin: 40px auto 20px auto;
  width: 80%;
}
.upload-text-img {
  width: 100%;
  height: 200px;
  opacity: 0.3;
}
.upload-actions-buttons {
  background-color: #f6f6f6;
  margin: 10px auto;
  position: absolute;
  bottom: 70px;
  width: 100%;
  padding: 2px;
}
.caption-input textarea {
  height: 42px !important;
  padding: 10px !important;
  border: 0.5px solid #191919 !important;
  border-radius: 6px;
}
.caption-input textarea:focus {
  margin-top: 5px !important;
}
.submit-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit-button-ok {
  width: 40%;
  margin: 0 auto;
  height: 40px;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
  background-color: green;
  cursor: pointer;
}
.submit-button-cancel {
  width: 40%;
  margin: 0 auto;
  height: 40px;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
  background-color: red;
  cursor: pointer;
}
.upload-progress-container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 200;
}
.upload-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 20px;
  width: 100%;
  text-align: center;
  z-index: 500;
}
.logo-container {
  width: 200px;
  height: 100px;
  margin: 20px auto;
}
.progress-number {
  color: #101010;
  font-family: Cairo;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37px;
  text-align: center;
}
.progress-text {
  color: #101010;
  font-family: Cairo;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 19px;
  text-align: center;
}
.finish-upload-close {
  width: 90%;
  margin: 15px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
}
.finish-upload-title {
  padding: 15px 0;
  color: #101010;
  font-family: Cairo;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37px;
  text-align: center;
}
.finish-upload-text {
  color: #6c6c6c;
  font-family: Cairo;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 35px;
}
.finish-upload-back .back {
  width: 50%;
  border-radius: 6px;
  background-color: #d83c3e;
  color: #ffffff;
  font-family: Cairo;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}
</style>
