<template>
  <div>
    <Tabs :activeTab="activeTab" :TabClicked="clicked" :tabs="tabs"></Tabs>
    <div class="content" :style="{ height: screenHeight + 'px' }">
      <div class="container" style="margin-bottom: 50px;padding: 0">
        <div class="row">
          <div class="col-12" v-if="loadingFans">
            <div>
              <loading
                :active.sync="loadingFans"
                background-color="#fff"
                :can-cancel="false"
                :is-full-page="true"
                :color="color"
              ></loading>
            </div>
          </div>
          <div class="col-12" v-if="activeTab === 0">
            <pull-to
              :on-refresh="onRefresh"
              style="width: 100%;"
              :config="{
                errorLabel: $t('pullToRefreshError'),
                startLabel: $t('pullToRefreshLabel'),
                readyLabel: $t('pullToRefreshReady'),
                loadingLabel: $t('pullToRefreshLoading'),
                pullDownHeight: 100
              }"
            >
              <following
                :followingUsers="following"
                v-if="
                  typeof following !== 'undefined' && following.length !== 0
                "
              />
              <div
                :style="{
                  textAlign: 'center',
                  width: '100%',
                  height: screenHeight - 45 + 'px'
                }"
                v-else
              >
                <img
                  v-lazysizes
                  :src="noFollowingsImg"
                  alt="no fans"
                  style="width: 100%;width: 100%;height: 100%;object-fit: cover;"
                />
                <div class="no-fans-container">
                  <span class="no-fans-title">{{ $t("noFans") }}</span>
                  <span class="no-fans-subtitle">{{ $t("noFollowing") }}</span>
                </div>
              </div>
            </pull-to>
          </div>
          <div class="col-12" v-else-if="activeTab === 1">
            <pull-to
              :on-refresh="onRefresh"
              style="width: 100%;"
              :config="{
                errorLabel: $t('pullToRefreshError'),
                startLabel: $t('pullToRefreshLabel'),
                readyLabel: $t('pullToRefreshReady'),
                loadingLabel: $t('pullToRefreshLoading'),
                pullDownHeight: 100
              }"
            >
              <followers
                :followersUsers="followers"
                v-if="
                  typeof followers !== 'undefined' && followers.length !== 0
                "
              />
              <div
                :style="{
                  textAlign: 'center',
                  width: '100%',
                  height: screenHeight - 45 + 'px'
                }"
                v-else
              >
                <img
                  v-lazysizes
                  :src="noFollowersImg"
                  alt="no fans"
                  style="width: 100%;width: 100%;height: 100%;object-fit: cover;"
                />
                <div class="no-fans-container">
                  <span class="no-fans-title">{{ $t("noFans") }}</span>
                  <span class="no-fans-subtitle">{{ $t("noFollower") }}</span>
                </div>
              </div>
            </pull-to>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PullTo from "vue-pull-refresh";
import Tabs from "@/components/Layout/Tabs.vue";
import Following from "@/components/Fans/Following.vue";
import Followers from "@/components/Fans/Followers.vue";
import NoFollowers from "@/assets/mainImage/no-followers.png";
import NoFollowings from "@/assets/mainImage/no-following.png";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "fans",
  data() {
    return {
      activeTab: 0,
      noFollowersImg: NoFollowers,
      noFollowingsImg: NoFollowings
    };
  },
  mounted() {
    this.$store.dispatch("fetchFollow");
  },
  components: {
    Tabs,
    Following,
    Followers,
    PullTo,
    Loading
  },
  computed: {
    tabs() {
      if (this.$store.getters.lang === "ar") {
        return ["متابعة", "متابعينك"];
      } else {
        return ["Following", "Followers"];
      }
    },
    color() {
      return this.$store.getters.primaryColor;
    },
    screenHeight() {
      return this.$store.getters.screenHeight - 185;
    },
    followers() {
      return this.$store.getters.followers;
    },
    following() {
      return this.$store.getters.following;
    },
    currentComp() {
      return this.$store.getters.currentComp;
    },
    loadingFans() {
      return this.$store.getters.loadingFans;
    }
  },
  methods: {
    clicked(num) {
      this.activeTab = num;
    },
    onRefresh() {
      this.$store.dispatch("fetchCountdown");
      this.$store.dispatch("fetchFollow");
    }
  }
};
</script>

<style scoped>
.content {
  overflow-y: scroll;
  /* -webkit-overflow-scrolling: touch; */
  margin: 165px 0 0 0;
  padding-top: 10px;
  height: 100%;
  background-color: transparent;
  width: 100%;
  font-family: inherit;
}
.no-fans-container {
  position: absolute;
  bottom: 10%;
  left: 0;
  width: 100%;
}
.no-fans-title {
  display: block;
  color: #101010;
  font-family: Cairo;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37px;
  text-align: center;
}
.no-fans-subtitle {
  display: block;
  color: #6c6c6c;
  font-family: Cairo;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 19px;
  text-align: center;
}
</style>
