<template>
  <div
    class="container"
    :style="{ height: screenHeight + 'px' }"
    v-if="loadingProfile"
  >
    <div class="row">
      <div class="col-12">
        <div>
          <loading
            :active.sync="loadingProfile"
            background-color="#fff"
            :can-cancel="false"
            :is-full-page="true"
            :color="color"
          ></loading>
        </div>
      </div>
    </div>
  </div>
  <div class="modal is-active is-clipped" v-else>
    <div class="modal-card" :style="height">
      <header class="modal-head" :style="cssVariable">
        <div class="modal-title"></div>
        <div class="modal-card-close" @click="closeModal">
          <span style="margin: 0 2px">{{ $t("modalCloseText") }}</span>
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Icons/close"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <path
                d="M11.8766417,2.00829893 C11.4329138,2.0684958 11.0909091,2.44885362 11.0909091,2.90909091 L11.089697,11.0909091 L2.90909091,11.0909091 L2.7730982,11.1018835 C2.3164165,11.1835228 2,11.558745 2,12 L2.00829893,12.1233583 C2.0684958,12.5670862 2.44885362,12.9090909 2.90909091,12.9090909 L11.089697,12.9090909 L11.0909091,21.0909091 L11.1018835,21.2269018 C11.1835228,21.6835835 11.558745,22 12,22 L12.1233583,21.9917011 C12.5670862,21.9315042 12.9090909,21.5511464 12.9090909,21.0909091 L12.9078788,12.9090909 L21.0909091,12.9090909 L21.2269018,12.8981165 C21.6835835,12.8164772 22,12.441255 22,12 L21.9917011,11.8766417 C21.9315042,11.4329138 21.5511464,11.0909091 21.0909091,11.0909091 L12.9078788,11.0909091 L12.9090909,2.90909091 L12.8981165,2.7730982 C12.8164772,2.3164165 12.441255,2 12,2 L11.8766417,2.00829893 Z"
                id="Combined-Shape"
                fill="#D83C3E"
                transform="translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) "
              ></path>
            </g>
          </svg>
        </div>
      </header>
      <section class="modal-body" style="padding: 0">
        <div
          class="modal-card-content"
          :style="{ maxHeight: screenHeight + 'px' }"
        >
          <div class="container" :style="{ height: screenHeight + 'px' }">
            <div class="row">
              <div class="col-12" style="padding: 0">
                <profile-component
                  :profileUser="profileUser"
                  :onRefresh="onRefresh"
                  :profileVideos="profileVideos"
                ></profile-component>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Profile from "@/components/Profile/Profile.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "singleProfile",
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("fetchUserProfile", {
      competitionId: this.$route.params.id,
      userId: this.$route.params.userId,
    });
  },
  components: {
    ProfileComponent: Profile,
    Loading,
  },
  methods: {
    closeModal() {
      let competition_id = this.$route.params.id;
      if (competition_id) {
        let data = {
          _id: competition_id,
        };
        this.$store.dispatch("setCompetition", data);
        this.$store.dispatch("removeSingleProfile");
      } else {
        this.$store.dispatch("removeSingleProfile");
        this.$router.push("/");
      }
    },
    onRefresh() {
      this.$store.dispatch("fetchUserProfile", {
        competitionId: this.$route.params.id,
        userId: this.$route.params.userId,
      });
    },
  },
  computed: {
    height() {
      return {
        "--height": this.$store.getters.screenHeight + "px",
      };
    },
    screenHeight() {
      return this.$store.getters.screenHeight - 100;
    },
    profileUser() {
      return this.$store.getters.userProfile;
    },
    profileVideos() {
      return this.$store.getters.profileVideos;
    },
    loadingProfile() {
      return this.$store.getters.loadingProfile;
    },
    color() {
      return this.$store.getters.primaryColor;
    },
  },
};
</script>

<style scoped></style>
