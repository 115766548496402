<template>
  <div class="col-12" style="padding: 0">
    <div v-if="newVideos.list.length !== 0">
      <div
        :id="video._id"
        :key="index"
        v-for="(video, index) in newVideos.list"
      >
        <pull-to
          v-if="index === 0"
          :on-refresh="onRefresh"
          style="width: 100%"
          :config="{
            errorLabel: $t('pullToRefreshError'),
            startLabel: $t('pullToRefreshLabel'),
            readyLabel: $t('pullToRefreshReady'),
            loadingLabel: $t('pullToRefreshLoading'),
            pullDownHeight: 100,
          }"
        >
          <Card
            :video="video"
            :clickLike="clickLike"
            :index="index"
            :reportHandler="reportHandler"
            :deleteHandler="deleteHandler"
            :currentUser="currentUser"
            :submitNewComment="submitNewComment"
            :isRanking="false"
          >
            <NewVideos :index="video._id" :id="video.uri" />
          </Card>
        </pull-to>
        <div v-else>
          <Card
            :video="video"
            :clickLike="clickLike"
            :index="index"
            :reportHandler="reportHandler"
            :deleteHandler="deleteHandler"
            :currentUser="currentUser"
            :submitNewComment="submitNewComment"
            :isRanking="false"
          >
            <NewVideos :index="video._id" :id="video.uri" />
          </Card>
        </div>
      </div>
    </div>
    <div v-else>
      <pull-to
        :on-refresh="onRefresh"
        style="width: 100%; min-height: 50px"
        :config="{
          errorLabel: $t('pullToRefreshError'),
          startLabel: $t('pullToRefreshLabel'),
          readyLabel: $t('pullToRefreshReady'),
          loadingLabel: $t('pullToRefreshLoading'),
          pullDownHeight: 100,
        }"
      >
      </pull-to>
    </div>
  </div>
</template>

<script>
import PullTo from "vue-pull-refresh";

export default {
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    newVideos() {
      return this.$store.getters.newVideos;
    },
  },
  methods: {
    clickLike(data) {
      if (!data.isWinner) {
        this.$store.dispatch("likeButtonHandler", data);
      }
    },
    deleteHandler(id) {
      this.$store.dispatch("deleteVideoHandler", id);
    },
    reportHandler(id) {
      this.$store.dispatch("reportVideoHandler", id);
    },
    submitNewComment(value) {
      this.$store.dispatch("newCommentHandler", value);
    },
    onRefresh() {
      this.$store.dispatch("fetchCountdown");
      this.$store.dispatch("fetchHome", { tab: "new", page: 1 });
    },
  },
  components: {
    Card: () =>
      import(
        /* webpackPrefetch: true */ "@/components/VideoCard/VideoCard.vue"
      ),
    NewVideos: () =>
      import(
        /* webpackPrefetch: true */ "@/components/VideoCard/VideoComponent.vue"
      ),
    PullTo,
  },
};
</script>

<style scoped></style>
