<template>
  <div
    :class="modalOpen ? 'modal is-active is-clipped' : 'modal'"
    style="text-align: start"
  >
    <div class="modal-card" :style="height">
      <header class="modal-head modal-head-static" :style="cssVariable">
        <svg
          class="modal-back-button"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          @click="closeModal"
        >
          <g
            id="Icons/arrows/nav-left"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <path
              d="M7.64439756,4.88308623 C7.86852739,4.72781896 8.15765524,4.70841055 8.39803458,4.824861 L8.49773807,4.88308623 L8.51566271,4.89673885 L8.61647352,4.98615721 L8.68798159,5.07332975 C8.84324885,5.29745958 8.86265726,5.58658743 8.73706062,5.83897487 L8.67432897,5.9445949 L8.60137924,6.03034874 L3.46,11.169 L15.0710678,11.1694174 C15.4162458,11.1694174 15.7069605,11.4026024 15.7942771,11.7200377 L15.8142212,11.8176468 L15.8210678,11.9194174 C15.8210678,12.2470493 15.6096225,12.5305547 15.2926736,12.6330793 L15.1832618,12.6603635 L15.0710563,12.6694174 L3.572,12.668 L8.61647352,17.7140793 L8.68798159,17.8012518 C8.89311488,18.0973637 8.85880981,18.5008402 8.6013979,18.7582521 C8.36972718,18.9899229 8.01974415,19.040877 7.72313158,18.8892562 L7.62647291,18.8311832 L7.54073773,18.7582521 L0.646446609,11.863961 L7.55722503,4.9545943 L7.64439756,4.88308623 Z M19.0710678,11.1694174 C19.4852814,11.1694174 19.8210678,11.5052038 19.8210678,11.9194174 C19.8210678,12.2470493 19.6096225,12.5305547 19.2926736,12.6330793 L19.1832618,12.6603635 L19.0710678,12.6694174 L17.5710678,12.6694174 C17.1568542,12.6694174 16.8210678,12.3336309 16.8210678,11.9194174 C16.8210678,11.5917855 17.0325131,11.3082801 17.349462,11.2057554 L17.4588738,11.1784713 L17.5710678,11.1694174 L19.0710678,11.1694174 Z"
              id="Combined-Shape"
              fill="#111111"
            ></path>
          </g>
        </svg>
        <div class="modal-title">
          <span>
            {{ $t("redeemPointTitle") }}
          </span>
        </div>
      </header>
      <section
        class="modal-body"
        style=" overflow-y: scroll;overflow-x: hidden;padding: 0;"
      >
        <div v-if="videos.length === 0">
          <h3 :style="{ color: color, textAlign: 'center', lineHeight: 3 }">
            {{ $t("noVideoToRedeem") }}
          </h3>
        </div>
        <div v-else>
          <div v-for="(video, index) in profileVideos" :key="index">
            <div
              class="video-card-container"
              :style="
                selectedId === video._id ? { backgroundColor: bkcolor } : {}
              "
            >
              <div class="video-card-radio">
                <!-- <input type="radio" name="radio" :value="video._id" v-model="selectedId" /> -->
                <button
                  :class="
                    video._id === selectedId
                      ? 'custom-radio active-radio '
                      : 'custom-radio '
                  "
                  :style="cssVariable"
                  @click="() => selectHandler(video._id)"
                >
                  <button
                    v-if="video._id === selectedId"
                    class="custom-radio-active"
                    :style="cssVariable"
                  ></button>
                </button>
              </div>
              <div class="video-card-item" :style="cssVariable">
                <div
                  style="height: 200px;width:94%;position: relative;margin: 10px auto;"
                >
                  <div
                    :id="video._id"
                    data-plyr-provider="vimeo"
                    :data-plyr-embed-id="video.uri"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="redeem-points-redeem-container">
          <button
            class="btn redeem-points-redeem"
            :style="cssVariable"
            @click="submit"
          >
            {{ $t("redeemPointSaveButton") }}
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    const controls = `<div class="plyr__controls" style="display: flex;justify-content: space-evenly;width: 100%;height: 100%;align-items: flex-end;flex-wrap: wrap;">
        <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play" style="width: 100%;height: 50px;background-color: transparent;padding: 0">
            <svg class="icon--pressed" role="presentation" width="35px" height="35px" viewBox="0 0 35 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: 0 auto;width:100%;height:100%">
              <g id="SHAGA3APP" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="pause-icon" fill="#C4C4C4" fill-rule="nonzero">
                      <path d="M34.0529,8.62266 C34.7788,11.6788 35.0329,14.7713 34.9966,17.5 C35.0329,20.2287 34.7788,23.3212 34.0529,26.3773 C34.0529,26.3773 32.9276,31.762 28.0634,33.6538 C25.7765,34.5634 21.6021,34.9636 17.5002,35 C13.3984,34.9636 9.2239,34.527 6.93702,33.6538 C2.07287,31.762 0.94758,26.3773 0.94758,26.3773 C0.185288,23.3576 -0.0325102,20.2287 0.00378941,17.5 C-0.0325102,14.7713 0.221587,11.6788 0.94758,8.62266 C0.94758,8.62266 2.07287,3.23805 6.93702,1.34615 C9.2239,0.472973 13.3984,0.0363825 17.5002,0 C21.6021,0.0363825 25.7765,0.472973 28.0634,1.34615 C32.9276,3.23805 34.0529,8.62266 34.0529,8.62266 Z M14,10 C12.8954305,10 12,10.8954305 12,12 L12,24 C12,25.1045695 12.8954305,26 14,26 C15.1045695,26 16,25.1045695 16,24 L16,12 C16,10.8954305 15.1045695,10 14,10 Z M21,10 C19.8954305,10 19,10.8954305 19,12 L19,24 C19,25.1045695 19.8954305,26 21,26 C22.1045695,26 23,25.1045695 23,24 L23,12 C23,10.8954305 22.1045695,10 21,10 Z" id="Combined-Shape"></path>
                  </g>
              </g>
            </svg>
            <svg class="icon--not-pressed" role="presentation" width="49px" height="50px" viewBox="0 0 49 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: 0 auto;width:100%;height:100%">
              <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="elements/video" transform="translate(-135.000000, -75.000000)">
                  <g id="controls/player-cion" transform="translate(135.000000, 75.000000)">
                    <path d="M35.4740153,2.30682432 C34.1451563,1.68434109 32.7340824,1.18601895 31.2515321,0.828971967 C29.6112636,0.434005524 27.8877737,0.191723645 26.1327402,0.0746095511 C15.2018677,-0.657269644 2.94683411,3.9910525 0.487102568,14.2095089 C-0.978669244,20.2987706 0.977371024,28.6165559 4.82837774,35.6591733 C6.11428378,38.0108512 7.60790794,40.2212538 9.26126364,42.1568243 C12.298512,45.7138713 15.8599214,48.3494418 19.596163,49.2487706 C31.4548878,52.1034686 44.700861,39.3296431 47.5555589,27.4705827 C50.05321,17.0937371 44.7766999,6.66420687 35.4740153,2.30682432" id="Fill-1" fill="${this.color}"></path>
                    <path d="M22.1521361,11.061321 L38.5044851,22.0489049 C40.4759616,23.3737371 40.4930757,26.261321 38.5366999,27.5676968 L22.2185791,38.4640055 C20.0155589,39.9351465 17.037371,38.3287706 17.0330086,35.6663545 L16.9987756,13.7824619 C16.994418,11.1327975 19.942069,9.57642163 22.1521361,11.061321" id="Fill-4" fill="#FFFFFF"></path>
                  </g>
                </g>
              </g>
            </svg>
        </button>
        <div style="width: 80%;">
        </div>
        <button type="button" class="plyr__control" data-plyr="fullscreen" style="background-color: rgba(17,17,17,0.8);padding:5px;width: 34px;height:34px;">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="icon--pressed" role="presentation" style="box-shadow:none;width: 100%">
                <g id="Icons/expand" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="maximize" transform="translate(3.000000, 3.000000)" fill="#fff">
                        <path d="M18,12.25 C18.3796958,12.25 18.693491,12.5321539 18.7431534,12.8982294 L18.75,13 L18.75,16 C18.75,17.4625318 17.6082954,18.6584043 16.1675223,18.7449812 L16,18.75 L13,18.75 C12.5857864,18.75 12.25,18.4142136 12.25,18 C12.25,17.6203042 12.5321539,17.306509 12.8982294,17.2568466 L13,17.25 L16,17.25 C16.6472087,17.25 17.1795339,16.7581253 17.2435464,16.1278052 L17.25,16 L17.25,13 C17.25,12.5857864 17.5857864,12.25 18,12.25 Z M0,12.25 C0.379695766,12.25 0.693490961,12.5321539 0.743153384,12.8982294 L0.75,13 L0.75,16 C0.75,16.6472087 1.24187466,17.1795339 1.87219476,17.2435464 L2,17.25 L5,17.25 C5.41421356,17.25 5.75,17.5857864 5.75,18 C5.75,18.3796958 5.46784612,18.693491 5.10177056,18.7431534 L5,18.75 L2,18.75 C0.537468162,18.75 -0.658404279,17.6082954 -0.744981206,16.1675223 L-0.75,16 L-0.75,13 C-0.75,12.5857864 -0.414213562,12.25 0,12.25 Z M5,-0.75 C5.41421356,-0.75 5.75,-0.414213562 5.75,0 C5.75,0.379695766 5.46784612,0.693490961 5.10177056,0.743153384 L5,0.75 L2,0.75 C1.35279131,0.75 0.820466101,1.24187466 0.756453609,1.87219476 L0.75,2 L0.75,5 C0.75,5.41421356 0.414213562,5.75 0,5.75 C-0.379695766,5.75 -0.693490961,5.46784612 -0.743153384,5.10177056 L-0.75,5 L-0.75,2 C-0.75,0.537468162 0.391704595,-0.658404279 1.83247767,-0.744981206 L2,-0.75 L5,-0.75 Z M16,-0.75 C17.4625318,-0.75 18.6584043,0.391704595 18.7449812,1.83247767 L18.75,2 L18.75,5 C18.75,5.41421356 18.4142136,5.75 18,5.75 C17.6203042,5.75 17.306509,5.46784612 17.2568466,5.10177056 L17.25,5 L17.25,2 C17.25,1.35279131 16.7581253,0.820466101 16.1278052,0.756453609 L16,0.75 L13,0.75 C12.5857864,0.75 12.25,0.414213562 12.25,0 C12.25,-0.379695766 12.5321539,-0.693490961 12.8982294,-0.743153384 L13,-0.75 L16,-0.75 Z" id="Shape"></path>
                    </g>
                </g>
              </svg>
            <svg class="icon--not-pressed" role="presentation" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" style="box-shadow:none; width: 100%">
              <g id="Icons/expand" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="maximize" transform="translate(3.000000, 3.000000)" fill="#fff">
                    <path d="M18,12.25 C18.3796958,12.25 18.693491,12.5321539 18.7431534,12.8982294 L18.75,13 L18.75,16 C18.75,17.4625318 17.6082954,18.6584043 16.1675223,18.7449812 L16,18.75 L13,18.75 C12.5857864,18.75 12.25,18.4142136 12.25,18 C12.25,17.6203042 12.5321539,17.306509 12.8982294,17.2568466 L13,17.25 L16,17.25 C16.6472087,17.25 17.1795339,16.7581253 17.2435464,16.1278052 L17.25,16 L17.25,13 C17.25,12.5857864 17.5857864,12.25 18,12.25 Z M0,12.25 C0.379695766,12.25 0.693490961,12.5321539 0.743153384,12.8982294 L0.75,13 L0.75,16 C0.75,16.6472087 1.24187466,17.1795339 1.87219476,17.2435464 L2,17.25 L5,17.25 C5.41421356,17.25 5.75,17.5857864 5.75,18 C5.75,18.3796958 5.46784612,18.693491 5.10177056,18.7431534 L5,18.75 L2,18.75 C0.537468162,18.75 -0.658404279,17.6082954 -0.744981206,16.1675223 L-0.75,16 L-0.75,13 C-0.75,12.5857864 -0.414213562,12.25 0,12.25 Z M5,-0.75 C5.41421356,-0.75 5.75,-0.414213562 5.75,0 C5.75,0.379695766 5.46784612,0.693490961 5.10177056,0.743153384 L5,0.75 L2,0.75 C1.35279131,0.75 0.820466101,1.24187466 0.756453609,1.87219476 L0.75,2 L0.75,5 C0.75,5.41421356 0.414213562,5.75 0,5.75 C-0.379695766,5.75 -0.693490961,5.46784612 -0.743153384,5.10177056 L-0.75,5 L-0.75,2 C-0.75,0.537468162 0.391704595,-0.658404279 1.83247767,-0.744981206 L2,-0.75 L5,-0.75 Z M16,-0.75 C17.4625318,-0.75 18.6584043,0.391704595 18.7449812,1.83247767 L18.75,2 L18.75,5 C18.75,5.41421356 18.4142136,5.75 18,5.75 C17.6203042,5.75 17.306509,5.46784612 17.2568466,5.10177056 L17.25,5 L17.25,2 C17.25,1.35279131 16.7581253,0.820466101 16.1278052,0.756453609 L16,0.75 L13,0.75 C12.5857864,0.75 12.25,0.414213562 12.25,0 C12.25,-0.379695766 12.5321539,-0.693490961 12.8982294,-0.743153384 L13,-0.75 L16,-0.75 Z" id="Shape"></path>
                </g>
              </g>
            </svg>
        </button>
      </div>`;
    this.videos[0].videos.forEach((video, index) => {
      // eslint-disable-next-line
      this.player = new Plyr(document.getElementById(video._id), {
        controls,
        urls: { vimeo: { api: "" } }
      });
      window.player = this.player;
    });
  },
  updated() {
    this.videos[0].videos.forEach((video, index) => {
      // eslint-disable-next-line
      if (video) {
        this.player.embed.element.src =
          "https://player.vimeo.com/video/" +
          video.uri +
          "?loop=false&autoplay=false&muted=false&gesture=media&playsinline=true&byline=false&portrait=false&title=false&speed=true&transparent=false";
        window.player = this.player;
      }
    });
  },
  props: ["modalOpen", "closeModal", "videos"],
  data() {
    return {
      selectedId: ""
    };
  },
  methods: {
    submit() {
      this.$store.dispatch("redeemUserPoint", this.selectedId);
    },
    selectHandler(id) {
      this.selectedId = id;
    },
    arabicize(value) {
      const arabicNums = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
      let newValue = value.toString().replace(/[0-9]/g, function(w) {
        return arabicNums[+w];
      });
      return newValue;
    }
  },
  computed: {
    pointRedeemed() {
      if (this.$store.getters.pointRedeemed) {
        this.$store.dispatch("fetchUserProfile", {
          competitionId: this.$route.params.id,
          userId: this.$route.params.userId
        });
        this.closeModal();
        this.$store.dispatch("resetPointRedeemed");
        return false;
      }
      return true;
    },
    lang() {
      return this.$store.getters.lang;
    },
    cssVariable() {
      return {
        "--color": this.$store.getters.primaryColor,
        "--bkcolor": this.$store.getters.secondaryColor
      };
    },
    height() {
      return {
        "--height": this.$store.getters.screenHeight + "px"
      };
    },
    color() {
      return this.$store.getters.primaryColor;
    },
    bkcolor() {
      return this.$store.getters.secondaryColor;
    },
    currentComp() {
      return this.$store.getters.currentComp;
    },
    profileVideos() {
      if (this.videos) {
        if (this.videos.length !== 0) {
          if (this.videos[0].videos.length !== 0) {
            return this.videos[0].videos;
          }
        }
      }
      return [];
    }
  }
};
</script>

<style scoped>
.modal-card {
  background-color: #fff;
}
.modal-head-static {
  direction: ltr;
  position: relative;
}
.modal-title {
  text-align: center !important;
  flex: 1;
}
.modal-back-button {
  position: absolute;
  top: calc(50% - 12px);
  left: 0;
}
.video-card-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}
.video-card-radio {
  flex: 1;
  text-align: center;
}
.custom-radio {
  width: 30px;
  height: 30px;
  line-height: 5px;
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  text-align: center;
}
.custom-radio-active {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border: none;
  border-radius: 50%;
}
.active-radio {
  background-color: var(--color);
}
.video-card-item {
  flex: 5;
  background-color: #fff;
  margin-bottom: 15px;
  padding: 0;
  border: 3px solid var(--color);
  border-radius: 6px;
}
.video-likes {
  text-align: end;
  font-size: 1rem;
  color: var(--bkcolor);
}

.redeem-points-redeem-container {
  text-align: center;
  margin: 20px 0;
}
.redeem-points-redeem {
  width: 50%;
  border-radius: 6px;
  background-color: var(--bkcolor);
  color: #ffffff;
  font-family: Cairo;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}
</style>
