<template>
  <div class="loading-container" :style="{ backgroundColor: bkcolor }">
    <div class="loading-container">
      <div class="loading-img-container">
        <figure class="image loading-img">
          <img :src="logo" :style="{ objectFit: 'contain', height: '100%' }" />
        </figure>
      </div>
      <div class="loading-text">
        <span>{{ $t("loadingText") }}</span>
      </div>
    </div>
    <div class="loading-powerby-container">
      <div class="loading-powerby-text">
        <span>{{ $t("powerByText") }}</span>
      </div>
      <div>
        <figure class="image loading-powerby-img">
          <img
            v-lazysizes
            :src="powerbyLogo"
            :style="{ objectFit: 'contain', height: '100%' }"
          />
        </figure>
      </div>
    </div>
  </div>
</template>
<script>
import PoweredBy from "@/assets/mainImage/Powered-by.png";
export default {
  data() {
    return {
      powerbyLogo: PoweredBy
    };
  },
  computed: {
    bkcolor() {
      return this.$store.getters.secondaryColor;
    },
    logo() {
      return this.$store.getters.competitionLogo;
    }
  }
};
</script>
<style scoped>
.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 100;
}
.loading-img-container {
  margin: 20px auto;
  margin-top: 170px;
  width: 137px;
}
.loading-powerby-container {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(17, 17, 17, 0.05);
  height: 94px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  direction: ltr;
}
.loading-powerby-img {
  height: 40px;
  width: 116px;
}
.loading-text {
  color: #ffffff;
  font-family: Cairo;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}
.loading-powerby-text {
  color: #ffffff;
  font-family: Cairo;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
}
</style>
