import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import "./registerServiceWorker";
import "./../node_modules/bulma/css/bulma.css";
import InfiniteLoading from "vue-infinite-loading";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/index.css";
import EasyRefresh from "vue-easyrefresh";
import vueAwesomeCountdown from "vue-awesome-countdown";
import VueConfetti from "vue-confetti";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import Skeleton from "vue-loading-skeleton";
import vueLazysizes from "vue-lazysizes";
import VueSocialSharing from "vue-social-sharing";

Vue.use(VueSocialSharing);
Vue.use(Skeleton);

Vue.use(VueMaterial);
Vue.use(VueConfetti);

Vue.use(EasyRefresh);
Vue.use(VueToast);

Vue.use(vueAwesomeCountdown, "vac");

Vue.use(InfiniteLoading, {
  props: {
    spinner: "default",
    distance: 10,
    forceUseInfiniteWrapper: true
    /* other props need to configure */
  },
  system: {
    throttleLimit: 1000
    /* other settings need to configure */
  }
});

Vue.directive("lazysizes", vueLazysizes);

Vue.config.devtools = true;
Vue.config.performance = true;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
