var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs is-small is-fullwidth",staticStyle:{"max-width":"1024px","margin":"0 auto","border-radius":"25px","overflow-x":"hidden"},attrs:{"id":"homeTabs"}},[_c('ul',_vm._l((_vm.tabs),function(tab,index){return _c('li',{key:index},[_c('a',{style:(_vm.activeTab === index
            ? {
                color: '#fff',
                borderColor: _vm.color,
                textTransform: 'capitalize',
                backgroundColor: _vm.color,
                borderRadius: '18px',
                fontWeight: 'bold'
              }
            : {
                textTransform: 'capitalize',
                borderColor: '#fff',
                fontWeight: 600,
                color: '#707C80',
                borderRadius: '18px'
              }),on:{"click":function () { return _vm.TabClicked(index); }}},[_c('span',[_vm._v(_vm._s(tab))])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }