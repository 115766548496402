import axios from '../../axios-auth'
import router from '../../router'

const state = {
  competitions: []
}

const mutations = {
  competitonsData (state, competitions) {
    state.competitions = competitions
  }
}

const actions = {
  fetchCompetitions ({ commit, rootState }) {
    if (typeof (rootState.token) !== 'undefined') {
      let navigator_info = window.navigator;
      let screen_info = window.screen;
      let uid = navigator_info.mimeTypes.length || '';
      uid += navigator_info.userAgent.replace(/\D+/g, '') || '';
      uid += navigator_info.plugins.length;
      uid += screen_info.height || '';
      uid += screen_info.width || '';
      uid += screen_info.pixelDepth || '';  
      let params = {
        foeihq: uid 
      }
      const url = '/init'
      commit('firstLoading', false)
      axios.get(url, { headers: { 'X-Auth-Token': rootState.token }, params })
        .then(res => {
          commit('competitonsData', res.data.competitions)
        })
        .catch(error => {
          console.log('ERROR fetchCompetition: ' + error)
          commit('loading', false)
        })
    }
  }
}

const getters = {
  competitions: state => {
    return state.competitions
  },
}

export default {
  state,
  mutations,
  actions,
  getters
}
