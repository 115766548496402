<template>
  <div>
    <Tabs :activeTab="activeTab" :TabClicked="clicked" :tabs="tabs"></Tabs>
    <loading
      :active.sync="loadingHome"
      background-color="#fff"
      :can-cancel="false"
      :is-full-page="true"
      :color="color"
    ></loading>
    <div
      class="content"
      :style="{ height: screenHeight + 'px', marginBottom: '30px;' }"
      ref="homeContainer"
      @scroll="onScroll"
    >
      <div class="container">
        <div class="row">
          <FeaturedVideos v-if="activeTab === 0" />
          <MostVideos v-if="activeTab === 1" />
          <NewVideos v-if="activeTab === 2" />
        </div>
        <div class="message-container" v-if="noMore">
          <span>{{ $t("NoMoreMessage") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/Layout/Tabs.vue";
import FeaturedVideos from "@/components/Home/FeaturedVideos.vue";
import MostVideos from "@/components/Home/MostVideos.vue";
import NewVideos from "@/components/Home/NewVideos.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "home",
  data() {
    return {
      activeTab: 0,
      page: 1,
      noMoreValue: false,
      scrollTo: 0,
      activeFooter: "home"
    };
  },
  computed: {
    tabs() {
      if (this.$store.getters.lang === "ar") {
        return ["متميز", "الترتيب", "جديد"];
      } else {
        return ["featured", "ranking", "new"];
      }
    },
    color() {
      return this.$store.getters.primaryColor;
    },
    noMore() {
      if (this.activeTab === 0) {
        if (this.featuredVideos.page > this.featuredVideos.pageCount) {
          if (this.featuredVideos.pageCount === -1) {
            this.noMoreValue = false;
            return false;
          }
          this.noMoreValue = true;
          return true;
        } else {
          this.noMoreValue = false;
          return false;
        }
      } else if (this.activeTab === 1) {
        if (this.mostVideos.page > this.mostVideos.pageCount) {
          if (this.mostVideos.pageCount === -1) {
            this.noMoreValue = false;
            return false;
          }
          this.noMoreValue = true;
          return true;
        } else {
          this.noMoreValue = false;
          return false;
        }
      } else if (this.activeTab === 2) {
        if (this.newVideos.page > this.newVideos.pageCount) {
          if (this.newVideos.pageCount === -1) {
            this.noMoreValue = false;
            return false;
          }
          this.noMoreValue = true;
          return true;
        } else {
          this.noMoreValue = false;
          return false;
        }
      }
    },
    newVideos() {
      return this.$store.getters.newVideos;
    },
    mostVideos() {
      return this.$store.getters.mostVideos;
    },
    featuredVideos() {
      return this.$store.getters.featuredVideos;
    },
    loadingHome() {
      return this.$store.getters.skeletonLoading;
    },
    screenHeight() {
      return this.$store.getters.screenHeight - 185;
    }
  },
  components: {
    Tabs,
    FeaturedVideos,
    MostVideos,
    NewVideos,
    Loading,
    FeaturedVideos
  },
  created() {
    this.activeTab = this.$store.getters.activeTab;
    if (this.activeTab === 2) {
      if (this.newVideos.list.length === 0) {
        this.$store.dispatch("fetchHome", { tab: "new", page: 1 });
      }
    } else if (this.activeTab === 1) {
      if (this.mostVideos.list.length === 0) {
        this.$store.dispatch("fetchHome", { tab: "top", page: 1 });
      }
    } else if (this.activeTab === 0) {
      if (this.featuredVideos.list.length === 0) {
        this.$store.dispatch("fetchHome", { tab: "featured", page: 1 });
      }
    }
  },
  methods: {
    clicked(num) {
      if (this.activeTab !== num) {
        this.$refs.homeContainer.scrollTop = 0;
        this.noMoreValue = false;
        this.activeTab = num;
        if (this.activeTab === 2) {
          if (this.newVideos.list.length === 0) {
            this.$store.dispatch("fetchHome", { tab: "new", page: 1 });
          }
        } else if (this.activeTab === 1) {
          if (this.mostVideos.list.length === 0) {
            this.$store.dispatch("fetchHome", { tab: "top", page: 1 });
          }
        } else if (this.activeTab === 0) {
          if (this.featuredVideos.list.length === 0) {
            this.$store.dispatch("fetchHome", { tab: "featured", page: 1 });
          }
        }
      }
    },
    loadMore() {
      if (this.activeTab === 0) {
        if (this.featuredVideos.page <= this.featuredVideos.pageCount) {
          this.$store.dispatch("fetchHome", {
            tab: "featured",
            page: this.featuredVideos.page
          });
        }
      } else if (this.activeTab === 1) {
        if (this.mostVideos.page <= this.mostVideos.pageCount) {
          this.$store.dispatch("fetchHome", {
            tab: "top",
            page: this.mostVideos.page
          });
        }
      } else if (this.activeTab === 2) {
        if (this.newVideos.page <= this.newVideos.pageCount) {
          this.$store.dispatch("fetchHome", {
            tab: "new",
            page: this.newVideos.page
          });
        }
      }
    },
    onScroll(e) {
      let scrollTop = e.target.scrollTop;
      let scrollHeight = e.target.scrollHeight;
      let offsetHeight = e.target.offsetHeight;
      let sum = scrollTop + offsetHeight;
      if (sum >= scrollHeight - 20) {
        if (!this.loadingHome) {
          this.loadMore();
        }
      }
      this.scrollTop = e.target.scrollTop;
      // this.scrollTop = this.$refs.homeContainer.scrollTop;
    }
  },
  mounted() {
    this.$refs.homeContainer.scrollTop = this.$store.getters.scrollTop;
  },
  beforeDestroy() {
    this.$store.dispatch("setScrollTop", {
      scroll: this.scrollTop,
      activeTab: this.activeTab
    });
  }
};
</script>

<style scoped>
.content {
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 165px 0 0 0;
  height: 100%;
  padding-top: 10px;
  background-color: transparent;
  /* -webkit-overflow-scrolling: touch; */
  width: 100%;
  max-width: 1024px;
}
.message-container {
  margin: 20px;
  margin-bottom: 50px;
  text-align: center;
  color: #101010;
  font-family: Cairo;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37px;
  text-align: center;
}
</style>
