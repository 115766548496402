<template>
  <div>
    <div class="video-container">
      <div class="play-button-container">
        <svg
          class="icon--not-pressed"
          role="presentation"
          width="49px"
          height="50px"
          viewBox="0 0 49 50"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="margin: 0 auto;width:100%;height:100%"
          @click="openVideo"
        >
          <g
            id="Symbols"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="elements/video"
              transform="translate(-135.000000, -75.000000)"
            >
              <g
                id="controls/player-cion"
                transform="translate(135.000000, 75.000000)"
              >
                <path
                  d="M35.4740153,2.30682432 C34.1451563,1.68434109 32.7340824,1.18601895 31.2515321,0.828971967 C29.6112636,0.434005524 27.8877737,0.191723645 26.1327402,0.0746095511 C15.2018677,-0.657269644 2.94683411,3.9910525 0.487102568,14.2095089 C-0.978669244,20.2987706 0.977371024,28.6165559 4.82837774,35.6591733 C6.11428378,38.0108512 7.60790794,40.2212538 9.26126364,42.1568243 C12.298512,45.7138713 15.8599214,48.3494418 19.596163,49.2487706 C31.4548878,52.1034686 44.700861,39.3296431 47.5555589,27.4705827 C50.05321,17.0937371 44.7766999,6.66420687 35.4740153,2.30682432"
                  id="Fill-1"
                  :fill="color"
                ></path>
                <path
                  d="M22.1521361,11.061321 L38.5044851,22.0489049 C40.4759616,23.3737371 40.4930757,26.261321 38.5366999,27.5676968 L22.2185791,38.4640055 C20.0155589,39.9351465 17.037371,38.3287706 17.0330086,35.6663545 L16.9987756,13.7824619 C16.994418,11.1327975 19.942069,9.57642163 22.1521361,11.061321"
                  id="Fill-4"
                  fill="#FFFFFF"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <img
        v-if="image.length !== 0"
        style="width:100%;height: 200px;object-fit: cover;background-color: #000"
        :src="image"
      />
      <!-- v-lazysizes -->
      <div v-else style="width:100%;height:200px;background-color: #000"></div>
    </div>
    <div :class="openVideoModal ? 'modal is-active is-clipped' : 'modal'">
      <div class="video-modal-card" :style="height">
        <section class="video-modal-body" style="padding: 0;">
          <div
            class="video-modal-content"
            :style="{ maxHeight: height + 'px' }"
          >
            <div class="container" :style="{ height: height + 'px' }">
              <div class="row" :style="{ height: height + 'px' }">
                <div
                  class="col-12"
                  style="margin:0;padding: 0;height:100%;position:relative"
                >
                  <div class="modal-card-close" @click="closeVideoModal">
                    <span style="margin: 0 2px">{{
                      $t("modalCloseText")
                    }}</span>
                    <svg
                      class="icon--pressed"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        id="Icons/close"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <path
                          d="M11.8766417,2.00829893 C11.4329138,2.0684958 11.0909091,2.44885362 11.0909091,2.90909091 L11.089697,11.0909091 L2.90909091,11.0909091 L2.7730982,11.1018835 C2.3164165,11.1835228 2,11.558745 2,12 L2.00829893,12.1233583 C2.0684958,12.5670862 2.44885362,12.9090909 2.90909091,12.9090909 L11.089697,12.9090909 L11.0909091,21.0909091 L11.1018835,21.2269018 C11.1835228,21.6835835 11.558745,22 12,22 L12.1233583,21.9917011 C12.5670862,21.9315042 12.9090909,21.5511464 12.9090909,21.0909091 L12.9078788,12.9090909 L21.0909091,12.9090909 L21.2269018,12.8981165 C21.6835835,12.8164772 22,12.441255 22,12 L21.9917011,11.8766417 C21.9315042,11.4329138 21.5511464,11.0909091 21.0909091,11.0909091 L12.9078788,11.0909091 L12.9090909,2.90909091 L12.8981165,2.7730982 C12.8164772,2.3164165 12.441255,2 12,2 L11.8766417,2.00829893 Z"
                          id="Combined-Shape"
                          fill="#D83C3E"
                          transform="translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) "
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div class="video-fullscreen">
                    <div class="plyr__video-embed" :id="index + id">
                      <iframe
                        :src="
                          `https://player.vimeo.com/video/${id}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media`
                        "
                        allowFullscreen
                        allowtransparency
                        allow="autoplay"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["id", "index"],
  data() {
    return {
      player: null,
      videoImg: null,
      openVideoModal: false
    };
  },
  created() {
    const url = `https://api.vimeo.com/videos/${this.id}/pictures`;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer c45bc0c3d9cfb0cd1e56ba3f84ca84d0"
        }
      })
      .then(res => {
        const imgs = res.data.data[0].sizes;
        this.videoImg = imgs[Math.floor(imgs.length / 2)];
      })
      .catch(err => console.log("error", err));
  },
  computed: {
    image() {
      if (this.videoImg) {
        return this.videoImg.link;
      } else {
        return "";
      }
    },
    videoId() {
      return "https://player.vimeo.com/video/" + this.id;
    },
    videoPlayer() {
      return document.getElementById(this.index + this.id);
    },
    color() {
      return this.$store.getters.primaryColor;
    },
    skeletonLoading() {
      return this.$store.getters.skeletonLoading;
    },
    height() {
      return this.$store.getters.screenHeight;
    }
  },
  methods: {
    closeVideoModal() {
      this.openVideoModal = false;
      this.player.pause();
    },
    openVideo() {
      this.openVideoModal = true;
      this.player.play();
    }
  },
  mounted() {
    this.videoUri = this.id;
    const controls = `<div class="plyr__controls" style="display: flex;justify-content: space-evenly;width: 100%;height: 100%;align-items: flex-end;flex-wrap: wrap;">
        <div style="width: 80%;">
        </div>
      </div>`;
    var element = this.videoPlayer;
    this.player = new Plyr(element, {
      // debug: true,
      controls,
      urls: { vimeo: { api: "" } }
    });
    window.player = this.player;
    this.player.on("pause", event => {
      const instance = event.detail.plyr;
    });
    this.player.on("ended", event => {
      const instance = event.detail.plyr;
      console.log("finished ", this.index);
    
    });
    this.player.on("playing", event => {
      const instance = event.detail.plyr;
      // if (!/iphone/.test(navigator.userAgent)) {
      //   instance.fullscreen.enter();
      // }
    });
  }
};
</script>

<style scoped>
.video-container {
  height: 200px;
  width: 100%;
  position: relative;
}
.play-button-container {
  position: absolute;
  left: 0;
  width: 100%;
  top: 75px;
  text-align: center;
  height: 50px;
  z-index: 30;
}
.video-fullscreen {
  width: 100%;
  height: calc(100% - 57px);
  overflow: hidden;
}
.modal-card-close {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 57px;
  padding: 20px;
  background: #000;
}
.video-modal-card {
  width: 100%;
}
</style>
