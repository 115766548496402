import axios from "../../axios-auth";
import router from "../../router";
const state = {
  new: {
    list: [],
    pageCount: -1,
    page: 1
  },
  most: {
    list: [],
    pageCount: -1,
    page: 1
  },
  featured: {
    list: [],
    pageCount: -1,
    page: 1
  },
  scrollTop: 0,
  activeTab: 0,
  profileVideos: [],
  loadingHome: false,
  firstLoading: false,
  skeletonLoading: false
};

const mutations = {
  fetchNewVideos(state, data) {
    data.list.map(d => {
      state.new.list.push(d);
    });
    state.new.page = data.page + 1;
    state.new.pageCount = data.pageCount;
  },
  fetchMostVideos(state, data) {
    data.list.map(d => {
      state.most.list.push(d);
    });
    state.most.page = data.page + 1;
    state.most.pageCount = data.pageCount;
  },
  fetchFeaturedVideos(state, data) {
    data.list.map(d => {
      state.featured.list.push(d);
    });
    state.featured.page = data.page + 1;
    state.featured.pageCount = data.pageCount;
  },
  fetchProfileVideos(state, data) {
    state.profileVideos = data;
  },
  // reset
  resetLists(state, tab) {
    if (tab === "new") {
      state.new.list = [];
      state.new.page = 0;
      state.new.pageCount = -1;
    } else if (tab === "top") {
      state.most.list = [];
      state.most.page = 0;
      state.most.pageCount = -1;
    } else if (tab === "featured") {
      state.featured.list = [];
      state.featured.page = 0;
      state.featured.pageCount = -1;
    }
  },
  resetHomeVideos(state) {
    state.new.list = [];
    state.new.page = 0;
    state.new.pageCount = -1;
    state.most.list = [];
    state.most.page = 0;
    state.most.pageCount = -1;
    state.featured.list = [];
    state.featured.page = 0;
    state.featured.pageCount = -1;
    state.profileVideos = [];
  },
  // like
  likeHandler(state, data) {
    if (state.profileVideos.length !== 0) {
      let profile = [...state.profileVideos];
      for (let i = 0; i < profile.length; i++) {
        for (let x = 0; x < profile[i].videos.length; x++) {
          if (profile[i].videos[x]._id === data.videoId) {
            if (!profile[i].videos[x].isLiked) {
              profile[i].videos[x].likes++;
              profile[i].videos[x].isLiked = true;
            }
          }
        }
      }
      state.profileVideos = [...profile];
    }
    if (state.new.list.length !== 0) {
      let videos = [...state.new.list];
      videos.map(v => {
        if (v._id === data.videoId) {
          if (!v.isLiked) {
            v.isLiked = true;
            v.likes++;
          }
        }
      });
      state.new.list = [...videos];
    }
    if (state.most.list.length !== 0) {
      let videos = [...state.most.list];
      videos.map(v => {
        if (v._id === data.videoId) {
          if (!v.isLiked) {
            v.isLiked = true;
            v.likes++;
          }
        }
      });
      state.most.list = [...videos];
    }
    if (state.featured.list.length !== 0) {
      let videos = [...state.featured.list];
      videos.map(v => {
        if (v._id === data.videoId) {
          if (!v.isLiked) {
            v.isLiked = true;
            v.likes++;
          }
        }
      });
      state.featured.list = [...videos];
    }
  },
  // comment
  lastComment(state, data) {
    if (state.profileVideos.length !== 0) {
      let profile = [...state.profileVideos];
      for (let i = 0; i < profile.length; i++) {
        let videos = [...profile[i].videos];
        videos.map(v => {
          if (v._id === data.id) {
            v.lastComment = data.value;
            v.commentsCount++;
          }
        });
        profile[i].videos = [...videos];
      }
      state.profileVideos = [...profile];
    }
    if (state.new.list.length !== 0) {
      let videos = [...state.new.list];
      videos.map(v => {
        if (v._id === data.id) {
          v.lastComment = data.value;
          v.commentsCount++;
        }
      });
      state.new.list = videos;
    }
    if (state.most.list.length !== 0) {
      let videos = [...state.most.list];
      videos.map(v => {
        if (v._id === data.id) {
          v.lastComment = data.value;
          v.commentsCount++;
        }
      });
      state.most.list = [...videos];
    }
    if (state.featured.list.length !== 0) {
      let videos = [...state.featured.list];
      videos.map(v => {
        if (v._id === data.id) {
          v.lastComment = data.value;
          v.commentsCount++;
        }
      });
      state.featured.list = [...videos];
    }
  },
  deleteLastcomment(state, data) {
    if (state.profileVideos.length !== 0) {
      let profile = [...state.profileVideos];
      for (let i = 0; i < profile.length; i++) {
        let videos = [...profile[i].videos];
        videos.map((v, index) => {
          if (v._id === data.id) {
            v.lastComment = data.lastComment;
            v.commentsCount--;
          }
        });
        profile[i].videos = [...videos];
      }
      state.profileVideos = [...profile];
    }
    if (state.new.list.length !== 0) {
      let videos = [...state.new.list];
      videos.map((v, index) => {
        if (v._id === data.id) {
          v.lastComment = data.lastComment;
          v.commentsCount--;
        }
      });
      state.new.list = [...videos];
    }
    if (state.most.list.length !== 0) {
      let videos = [...state.most.list];
      videos.map((v, index) => {
        if (v._id === data.id) {
          v.lastComment = data.lastComment;
          v.commentsCount--;
        }
      });
      state.most.list = [...videos];
    }
    if (state.featured.list.length !== 0) {
      let videos = [...state.featured.list];
      videos.map((v, index) => {
        if (v._id === data.id) {
          v.lastComment = data.lastComment;
          v.commentsCount--;
        }
      });
      state.featured.list = [...videos];
    }
  },
  // delete
  deleteVideo(state, id) {
    if (state.profileVideos.length !== 0) {
      let profile = [...state.profileVideos];
      for (let i = 0; i < profile.length; i++) {
        let videos = [...profile[i].videos];
        videos.map((v, index) => {
          if (v._id === id) {
            videos.splice(index, 1);
          }
        });
        profile[i].videos = [...videos];
      }
      state.profileVideos = [...profile];
    }
    if (state.new.list.length !== 0) {
      let videos = [...state.new.list];
      videos.map((v, index) => {
        if (v._id === id) {
          videos.splice(index, 1);
        }
      });
      state.new.list = [...videos];
    }
    if (state.most.list.length !== 0) {
      let videos = [...state.most.list];
      videos.map((v, index) => {
        if (v._id === id) {
          videos.splice(index, 1);
        }
      });
      state.most.list = [...videos];
    }
    if (state.featured.list.length !== 0) {
      let videos = [...state.featured.list];
      videos.map((v, index) => {
        if (v._id === id) {
          videos.splice(index, 1);
        }
      });
      state.featured.list = [...videos];
    }
  },
  homeFollow(state, data) {
    if (state.new.list.length !== 0) {
      let videos = [...state.new.list];
      videos.map((v, index) => {
        if (v.user_id._id === data.id) {
          v.user_id.isFollowed = data.followed;
          if (data.followed) {
            v.user_id.followerCount++;
          } else {
            v.user_id.followerCount--;
          }
        }
      });
      state.new.list = [...videos];
    }
    if (state.most.list.length !== 0) {
      let videos = [...state.most.list];
      videos.map((v, index) => {
        if (v.user_id._id === data.id) {
          v.user_id.isFollowed = data.followed;
          if (data.followed) {
            v.user_id.followerCount++;
          } else {
            v.user_id.followerCount--;
          }
        }
      });
      state.most.list = [...videos];
    }
    if (state.featured.list.length !== 0) {
      let videos = [...state.featured.list];
      videos.map((v, index) => {
        if (v.user_id._id === data.id) {
          v.user_id.isFollowed = data.followed;
          if (data.followed) {
            v.user_id.followerCount++;
          } else {
            v.user_id.followerCount--;
          }
        }
      });
      state.featured.list = [...videos];
    }
  },
  loadingHome(state, data) {
    if (!state.firstLoading || !data) {
      state.loadingHome = data;
      state.firstLoading = true;
    }
  },
  firstLoading(state, data) {
    state.firstLoading = data;
  },
  skeletonLoading(state, data) {
    state.skeletonLoading = data;
  },
  scrollTop(state, data) {
    state.scrollTop = data.scroll;
    state.activeTab = data.activeTab;
  }
};

const actions = {
  likeButtonHandler({ commit, rootState }, data) {
    if (typeof rootState.token !== "undefined") {
      if (!rootState.competitionLocked) {
        if (!rootState.loading) {
          if (!data.isLiked) {
            commit("loading", true);
            const url = "/likes";
            const newData = {
              video_id: data.id
            };
            axios
              .post(url, newData, {
                headers: { "X-Auth-Token": rootState.token }
              })
              .then(res => {
                if (res) {
                  commit("likeHandler", {
                    liked: res.data.liked,
                    videoId: data.id
                  });
                  commit("likeHandlerSearch", {
                    liked: res.data.liked,
                    videoId: data.id
                  });
                  commit("likeHandlerSingle", {
                    liked: res.data.liked,
                    videoId: data.id
                  });
                  commit("loading", false);
                }
              })
              .catch(error => {
                console.log(error);
                commit("loading", false);
              });
          } else {
            let message = "You can not dislike the video.";
            let lang = localStorage.getItem("lang");
            if (lang === "1") {
              message = "لا يمكنك سحب الايك من الفيديو";
            }
            notie.alert({ type: "error", text: message });
          }
        }
      } else {
        let message =
          "Round ended. All likes are disabled until processing & announcing the winner";
        let lang = localStorage.getItem("lang");
        if (lang === "1") {
          message = "انتهت الجوله. تم تعليق اللايكات حاليا لحين اعلان الفائز";
        }
        notie.alert({ type: "error", text: message });
      }
    } else {
      commit("status", 400);
      router.push("/error");
    }
  },
  fetchHome({ commit, rootState, state }, data) {
    if (typeof rootState.token !== "undefined") {
      const competitionId = router.history.current.params.id;
      if (competitionId) {
        commit("loadingHome", true);
        commit("skeletonLoading", true);
        if (data.page === 1) {
          commit("resetLists", data.tab);
        }
        let type = data.tab;
        let url = "/home";
        if (data.tab === "top") {
          url = "/home/top";
        } else if (data.tab === "new") {
          url = "/home/recent";
        } else if (data.tab === "featured") {
          url = "/home/featured";
        }
        const params = {
          type,
          page: data.page,
          perPage: 10,
          competition_id: competitionId
        };
        axios
          .get(url, { params, headers: { "X-Auth-Token": rootState.token } })
          .then(res => {
            commit("loadingHome", false);
            commit("skeletonLoading", false);
            let response;
            if (res.data[0].pagination.length !== 0) {
              response = {
                list: res.data[0].videos,
                pageCount: res.data[0].pagination[0].pageCount,
                page: data.page
              };
            } else {
              response = {
                list: res.data[0].videos,
                pageCount: 0,
                page: 0
              };
            }
            if (data.tab === "new") {
              commit("fetchNewVideos", response);
            } else if (data.tab === "top") {
              // console.log(res.data[0].pagination[0])
              commit("fetchMostVideos", response);
            } else if (data.tab === "featured") {
              commit("fetchFeaturedVideos", response);
            }
          })
          .catch(error => {
            console.log("ERROR fetchHome: " + error);
            commit("loading", false);
            commit("loadingHome", false);
            commit("skeletonLoading", false);
          });
      }
    } else {
      commit("status", 400);
      router.push("/error");
    }
  },
  deleteLastcommentHandler({ commit, rootState, state }, data) {
    if (typeof rootState.token !== "undefined") {
      commit("loading", true);
      commit("resetLists");
      const url = "/comments/" + data.comment_id;
      axios
        .delete(url, { headers: { "X-Auth-Token": rootState.token } })
        .then(res => {
          commit("deleteLastcomment", {
            id: data.video_id,
            lastComment: res.data.lastComment
          });
          commit("deleteLastcommentSearch", {
            id: data.video_id,
            lastComment: res.data.lastComment
          });
          commit("deleteLastcommentSingle", {
            id: data.video_id,
            lastComment: res.data.lastComment
          });
          commit("loading", false);
        })
        .catch(error => {
          console.log("ERROR fetchComments: " + error);
          commit("loading", false);
        });
    } else {
      commit("status", 400);
      router.push("/error");
    }
  },
  deleteVideoHandler({ commit, rootState, state }, video_id) {
    if (typeof rootState.token !== "undefined") {
      // commit('resetHomeVideos')
      commit("loading", true);
      const url = "/videos";
      const data = {
        video_id: video_id
      };
      axios
        .delete(url, { headers: { "X-Auth-Token": rootState.token }, data })
        .then(res => {
          if (res) {
            commit("deleteVideo", video_id);
            commit("deleteVideoSearch", video_id);
            commit("deleteVideoSingle", video_id);
            commit("loading", false);
          } else {
            commit("loading", false);
            let message = "you cannot delete winner video";
            if (rootState.curLanguage.short === "ar") {
              message = "لا يمكنك حذف فيديو الفائز";
            }
            notie.alert({ type: "error", text: message });
          }
        })
        .catch(error => {
          console.log("ERROR delete videos: " + error);
          commit("loading", false);
        });
    } else {
      commit("status", 400);
      router.push("/error");
    }
  },
  reportVideoHandler({ commit, rootState }, data) {
    if (typeof rootState.token !== "undefined") {
      const url = "/reports";
      const newData = {
        video_id: data
      };
      axios
        .post(url, newData, { headers: { "X-Auth-Token": rootState.token } })
        .then(res => {
          let message = "the video has been reported successfully";
          if (rootState.curLanguage.short === "ar") {
            message = "تم الإبلاغ عن الفيديو بنجاح";
          }
          notie.alert({ type: "success", text: message });
        })
        .catch(error => {
          console.log("ERROR: report" + error);
          commit("loading", false);
        });
    } else {
      commit("status", 400);
      router.push("/error");
    }
  },
  shareHandler({ commit, rootState }, data) {
    if (typeof rootState.token !== "undefined") {
      const url = "/profile/share";
      const newData = {
        video_id: data.id
      };
      if (data.shareResult === "true") {
        // alert("sharing");
        axios
          .post(url, newData, { headers: { "X-Auth-Token": rootState.token } })
          .then(res => {})
          .catch(error => {
            console.log("ERROR: report" + error);
            commit("loading", false);
          });
      } else {
        // alert("canceled")
      }
    } else {
      commit("status", 400);
      router.push("/error");
    }
  },
  setScrollTop({ commit }, data) {
    commit("scrollTop", { scroll: data.scroll, activeTab: data.activeTab });
  }
};

const getters = {
  newVideos: state => {
    return state.new;
  },
  mostVideos: state => {
    return state.most;
  },
  featuredVideos: state => {
    return state.featured;
  },
  profileVideos: state => {
    return state.profileVideos;
  },
  loadingHome: state => {
    return state.loadingHome;
  },
  skeletonLoading: state => {
    return state.skeletonLoading;
  },
  scrollTop: state => {
    return state.scrollTop;
  },
  activeTab: state => {
    return state.activeTab;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
