import axios from "../../axios-auth";
import router from "../../router";
const state = {
  following: [],
  followers: [],
  loadingFans: false,
};

const mutations = {
  followers(state, data) {
    state.followers = data;
  },
  following(state, data) {
    state.following = data;
  },
  resetFans(state) {
    (state.following = []), (state.followers = []);
  },
  loadingfans(state, data) {
    state.loadingFans = data;
  },
};

const actions = {
  fetchFollow({ commit, rootState }) {
    if (typeof rootState.token !== "undefined") {
      commit("loadingfans", true);
      commit("loading", true);
      const url = "/follow";
      axios
        .get(url, { headers: { "X-Auth-Token": rootState.token } })
        .then((res) => {
          commit("followers", res.data.followers);
          commit("following", res.data.following);
          commit("loadingfans", false);
          commit("loading", false);
        })
        .catch((error) => {
          console.log("ERROR: Follow" + error);
          commit("loadingfans", false);
          commit("loading", false);
        });
    } else {
      commit("status", 400);
      router.push("/error");
    }
  },
  followHandler({ commit, rootState }, id) {
    if (typeof rootState.token !== "undefined") {
      commit("loadingfans", true);
      commit("loading", true);
      const url = "/follow";
      const newData = {
        user_id: id,
      };
      axios
        .post(url, newData, { headers: { "X-Auth-Token": rootState.token } })
        .then((res) => {
          // console.log(res);
          commit("doIFollow", res.data.followed);
          commit("loadingfans", false);
          commit("loading", false);
          commit("homeFollow", { id, followed: res.data.followed });
          commit("searchFollow", { id, followed: res.data.followed });
          commit("singleFollow", { id, followed: res.data.followed });
        })
        .catch((error) => {
          console.log(error);
          commit("loadingfans", false);
          commit("loading", false);
        });
    } else {
      commit("status", 400);
      router.push("/error");
    }
  },
};

const getters = {
  followers: (state) => {
    return state.followers;
  },
  following: (state) => {
    return state.following;
  },
  loadingFans: (state) => {
    return state.loadingFans;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
