export default {
  // competition selection
  selectCompetitionsTitle: "أختار المسابقة",
  openCompetition: "ابدأ المسابقة",
  competitionIsFinished: "أنهت",
  terms: "الشروط و الاحكام",
  faq: "التعليمات",
  comingSoon: "قريبا",
  // header
  counterTitle: "الجولة تنتهي خلال",
  counterFinished: "انتهت المسابقة",
  counterProcessing: "تجهيز الفائز",
  counterWinner: "مبروك &#127881;",
  counterDay: "ي",
  counterHours: "س",
  counterMinutes: "دق",
  counterSeconds: "ث",
  mainMenuHome: "المسابقات",
  mainMenuSearch: "بحث",
  mainMenuLogin: "تسجيل الدخول",
  mainMenuLanguage: "اللغة",
  mainMenuPoints: "احصل على المزيد من النقاط",
  languageSelectionTitle: "اللغة",
  // loading screen
  loadingText: "تحميل المنافسة ...",
  // powerByText: "الراعي الرسمي: ",
  powerByText: "Powered By: ",
  // footer
  mainFooterTabHome: "الرئيسية",
  mainFooterTabFans: "مشجعين",
  mainFooterTabWinners: "فائزين",
  mainFooterTabProfile: "حسابي",
  // home
  homeMainTabNew: "جديد",
  homeMainTabMost: "الاكثر إعجاباً",
  homeMainTabFeatured: "متميز",
  menuCardShare: "نشر",
  menuCardReport: "بلغ",
  menuCardDelete: "حذف",
  RankTitle: "المركز",
  GiftTitle: "الجائزة",
  moreCommentsTitle1: "عرض الكل",
  moreCommentsTitle2: "التعليقات",
  CommentPlaceholder: "اكتب تعليق ...",
  commentsTitle: "التعليقات",
  CommentSend: "إرسال",
  NoComment: "لا توجد تعليقات",
  pullToRefreshError: "خطأ",
  pullToRefreshLabel: "سحب لتحديث",
  pullToRefreshReady: "جاهز",
  pullToRefreshLoading: "جار التحميل",
  cancelButton: "اغلق",
  copyUrlTitle: "انسخ الرابط",
  // upload
  uploadText: "ارفع الفيديو ونافس لايڤ",
  uploadTextDegla:
    "دوس على زرار الكاميرا عشان تبعت الفيديو وتشارك في سوبر دجلاوي، لازم تكون سجلت دخول على الأبليكيشن",
  uploadVideoCaptionText: "عنوان الفيديو",
  uploadVideoCaptionPlaceholder: "أدخل عنوان لهذا الفيديو",
  uploading: "تحميل",
  saving: "حفظ",
  finishUploadTitle: " تمام !!",
  finishUpload:
    "خلاص كده يا بطل الڤيديو بتاعك في بروفايلك &#128165; احنا هنراجع علي الفيديو ولو كلو تمام هتخش تنافس علي طول.. &#128170; جهز نفسك و اصحابك علشان يعملولك لايك ",
  gobackToHome: "رجوع",
  finishUploadDegla:
    "خلاص كده يا بطل الڤيديو بتاعك في بروفايلك &#128165; احنا هنراجع علي الفيديو ولو كلو تمام هتخش تنافس علي طول.. &#128170; جهز نفسك و اصحابك علشان يعملولك لايك ",
  gobackToHomeDegla: "أنا موافق",
  notValidVideo: "! لازم ترفع ڤيديو علشان تنافس بيه",
  bigCaption: "! ده اسم طويل .. خليه اقصر شويه ",
  smallCaption: "أضف عنوان للفيديو",
  // profile
  profileVideosTitle: "فيدوهات",
  profileVideoLabel: "فيدوهات",
  profileFollowingLabel: "متابعة",
  profileFollowersLabel: "متابعينك",
  profileEditProfilebutton: "تعديل",
  redeemPointsButton: "استبدال النقاط",
  redeemPointsRules: "لا يمكن استبدال النقاط في غضون 48 ساعة من نهاية الجولة",
  profileFollowingbutton: "تابع",
  profileUnfollowingbutton: "الغاء المتابعة",
  profileVideoNotApprove: "الڤيديو في انتظار الموافقه",
  // 'profileVideoWinner': 'Video is waiting for approval',
  // 'profileVideoNoRank': 'Video is waiting for approval',
  noVideos: "لا يوجد ڤيديوهات.. يلا ارفع ڤيديو و نافس و كسب لايڤ علي طول",
  // edit profile
  editProfileTitle: "تعديل حسابك",
  editProfileUploadButton: "تحميل",
  editProfileName: "الاسم بالكامل",
  editProfileEmail: "البريد الإلكتروني",
  editProfileMobile: "رقم الهاتف المحمول",
  editProfileMembership: "رقم العضوية",
  editProfileMembershipDegla: "رقم عضويتك في وادى دجلة   (اختاري)",
  editProfileUpdateButton: "حفظ",
  editProfileCancelButton: "إلغاء",
  notValidImage: "! لازم ترفع صورة",
  // redeem points
  redeemPointTitle: "اختار فيديو",
  noVideoToRedeem: "لا توجد مقاطع فيديو ، يرجى إضافة مقطع فيديو",
  redeemPointSaveButton: "استبدال",
  redeemPointCancelButton: "إلغاء",
  // search
  SearchTitle: "بحث",
  modalCloseText: "أغلق",
  SearchPlaceHolder: "أدخل اسم المستخدم للبحث",
  NoMoreMessage: "لا يوجد فيديوهات اخرى",
  NoResultsMessage: "لا يوجد فيديوهات",
  // fans
  fansMainTabsFollowing: "متابعة",
  fansMainTabsFollowers: "متابعينك",
  noFans: "لا شيء هنا!",
  noFollowing: "يبدو أنك لا تتابع أي شخص.",
  noFollower: "يبدو أنه ليس لديك متابعون",
  // login
  loginTitle: "تسجيل الدخول",
  verifyTitle: "تحقق من بريدك الإلكتروني ",
  verifySmsTitle: "تحقق من رقم هاتفك المحمول ",
  infoTitle: "أدخل معلوماتك",
  chooseLoginMethodTitle: "الرجاء تحديد إحدى الطرق التالية لتسجيل الدخول",
  smsLoginButton: "تسجيل الدخول عبر الرسائل القصيرة",
  enterMobileSubtitle: "أدخل رقم هاتفك المحمول",
  loginMobileNumber: "التليفون المحمول",
  mobileRequiredError: "لا يمكن أن يكون رقم هاتفك المحمول فارغًا",
  mobileInvalidError: "الرقم هاتفك المحمول غير صحيح",
  mobileConcurentError: "يرجى الانتظار 10 دقائق والمحاولة مرة أخرى",
  loginButton: "تقديم",
  enterCodeSubtitle: "أدخل رمز التحقق الموجود في هذا البريد الإلكتروني",
  enterSmsCodeSubtitle:
    "أدخل رمز التحقق الموجود في رسالة نصية قصيرة إلى رقم التليفون المحمول",
  codeLabel: "رمز التحقق",
  codeRequiredError: "يجب عليك إدخال رمز التحقق",
  codeNumericError: "يجب أن يكون رمز التحقق رقميًا",
  codeError: "رمز التحقق غير صحيح",
  codeButton: "التحقق",
  enterDataSubtitle: "أدخل معلوماتك أدناه",
  loginInfoName: "الاسم الكامل",
  nameRequiredError: " عليك ادخال اسمك",
  nameMaxLengthError: "يجب أن يكون الاسم الكامل 25 حرفًا",
  loginInfoEmail: "البريد الإلكتروني",
  loginWithEmailSubtitle:
    "الرجاء إدخال بريدك الإلكتروني لتسجيل الدخول أو التسجيل",
  loginEmail: "البريد الإلكتروني",
  emailRequiredError: "عليك ادخال بريدك الالكتروني",
  NotemailError: "بريدك الالكتروني غير صحيح",
  loginInfoMemberDegla: "رقم عضويتك في وادى دجلة   (اختاري)",
  loginInfoMember: "رقم عضويتك (اختاري)",
  memberInvalid: "رقم العضوية غير صحيح",
  submitLoginData: "تقديم",
  // winners
  winnerName: "فائز",
  noWinnersTitle: "لا فائزين !!",
  noWinnersSub: "لا يوجد فائزين في هذه المسابقة حتى الان",
  noWinnersRound: "لا فائزين في هذه الجولة",
  // getmorePoints
  pointsMainTitle: "احصل على المزيد من النقاط",
  PointsTitle: "اختر واحد من ما يلي: ",
  ScannerTitle: "مسح كود",
  ScannerButton: "مسح كود",
  promoCodeLabel: "البرومو كود",
  redeemButton: "استبدال النقاط",
  PromoCodeTitle: "البرومو كود",
};
