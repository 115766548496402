import axios from "axios";
import router from "./router";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

instance.defaults.headers.common["Content-Type"] = "application/json";
instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

instance.interceptors.request.use((config) => {
  NProgress.start();
  return config;
});
instance.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    NProgress.done();
    let errorObject = {
      status: error.response.status,
      url: error.config.url,
      method: error.config.method,
      headers: error.config.headers,
      params: error.config.params,
      localStorageToken: localStorage.getItem("token"),
      localStorageSlug: localStorage.getItem("sl"),
    };
    let text = ` status: ${errorObject.status}\n url: ${errorObject.url}\n method: ${errorObject.method}\n token: ${errorObject.localStorageToken}\n slug: ${errorObject.localStorageSlug}\n `;
    const url =
      "https://slack.com/api/chat.postMessage?token=xoxp-316268391537-565983672662-679901340661-643ef055ab95bb2cffff4f485174075e&channel=C04B9M3THLM&text=" +
      text;
    axios.get(url, { headers: { "Content-Type": "application/json" } });
    if (error.response.status === 469) {
      let message = "";
      let submitText;
      let cancelText;
      let lang = localStorage.getItem("lang");
      if (lang === "0") {
        message = "you need to be logged in or to be a member";
        (submitText = "go to login"), (cancelText = "back to home");
      } else {
        message = "تحتاج إلى تسجيل الدخول أو أن تكون عضوا";
        (submitText = "تسجيل الدخول"), (cancelText = "الصفحة الرئيسية");
      }
      notie.setOptions({
        overlayClickDismiss: false,
      });
      notie.confirm(
        {
          text: message,
          submitText,
          cancelText,
        },
        () => {
          let slug = localStorage.getItem("sl");
          if (slug !== "degla") {
            let dev = window.location.host.split(".")[0];
            if (dev !== "dev") {
              window.open(
                "https://" + slug + ".shaga3app.com/?resignin=true",
                "_top"
              );
            } else {
              window.open(
                "https://dev." + slug + ".shaga3app.com/?resignin=true",
                "_top"
              );
            }
          } else {
            router.push({ name: "login" });
          }
        },
        () => {
          let competetionId = localStorage.getItem("competitionId");
          if (competetionId) {
            router.push({ name: "home", params: { id: competetionId } });
          } else {
            router.push({ name: "competitions" });
          }
        }
      );
    } else if (error.response.status === 471) {
      let message = "You can not like not approved video";
      let lang = localStorage.getItem("lang");
      if (lang === "1") {
        message = "لا يمكنك اعجاب الفيديو غير الموافق عليه";
      }
      notie.alert({ type: "error", text: message });
    } else if (error.response.status === 420) {
      let competetionId = localStorage.getItem("competitionId");
      if (competetionId) {
        router.push({ name: "home", params: { id: competetionId } });
      } else {
        router.push({ name: "competitions" });
      }
      let lang = localStorage.getItem("lang");
      let message =
        "Sorry a big load now due to the current live competition & voting. Please try again at 3 pm while less traffic. Or login with your Degla membership ID";
      if (lang === "1") {
        message =
          "معلش ضغط المستخدمين دلوقتي كبير علشان التصويت و المنافسه الايڤ. ممكن تجرب الساعه ٣:٠٠ هيكون الضغط اقل. او الدخول برقم عضويه النادي .بالتوفيق";
      }
      notie.alert({ type: "error", text: message, time: 5, stay: true });
    } else if (error.response.status === 450) {
      let message = "Error!! the verification Code is incorrect";
      let lang = localStorage.getItem("lang");
      if (lang === "1") {
        message = "خطأ!! رمز التحقق غير صحيح";
      }
      notie.alert({ type: "error", text: message });
    } else if (error.response.status === 429) {
      let message = "Error!! too many trials. Please try again later";
      let lang = localStorage.getItem("lang");
      if (lang === "1") {
        message =
          "خطأ!! تم إجراء العديد من المحاولات. الرجاء معاودة المحاولة في وقت لاحق";
      }
      notie.alert({ type: "error", text: message });
    } else {
      let message = error.response.status + ": " + error.message;
      notie.alert({ type: "error", text: message });
    }
  }
);

export default instance;
