<template>
  <div
    class="tabs is-small is-fullwidth"
    id="homeTabs"
    style="max-width: 1024px;margin: 0 auto;border-radius: 25px;overflow-x: hidden;"
  >
    <ul>
      <li :key="index" v-for="(tab, index) in tabs">
        <a
          :style="
            activeTab === index
              ? {
                  color: '#fff',
                  borderColor: color,
                  textTransform: 'capitalize',
                  backgroundColor: color,
                  borderRadius: '18px',
                  fontWeight: 'bold'
                }
              : {
                  textTransform: 'capitalize',
                  borderColor: '#fff',
                  fontWeight: 600,
                  color: '#707C80',
                  borderRadius: '18px'
                }
          "
          @click="() => TabClicked(index)"
        >
          <span>{{ tab }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["activeTab", "TabClicked", "tabs"],
  data() {
    return {
      // color: localStorage.getItem('color') ? localStorage.getItem('color') : '#df1616'
    };
  },
  computed: {
    color() {
      return this.$store.getters.primaryColor;
    }
  }
};
</script>
<style scoped>
.tabs {
  top: 117px;
  left: 0;
  position: absolute;
  right: 0;
  /* z-index: 30; */
  background-color: #fff;
  width: 95%;
}
.tabs ul {
  border: none !important;
  margin: 0 20px;
}
#homeTabs a {
  width: 90px;
  height: 37px;
  margin: 0 auto;
  font-family: Cairo;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
}
.tabs li.is-active a:hover {
  border: none;
}
</style>
