<template>
  <div class="content" :style="{ maxHeight: screenHeight + 'px' }">
    <div class="container">
      <div class="row">
        <div class="col-12" v-if="loadingProfile">
          <div>
            <loading
              :active.sync="loadingProfile"
              background-color="#fff"
              :can-cancel="false"
              :is-full-page="true"
              :color="color"
            ></loading>
          </div>
        </div>
        <div class="col-12" style="padding: 0" v-else>
          <profile-component
            :profileUser="profileUser"
            :onRefresh="onRefresh"
            :profileVideos="profileVideos"
          ></profile-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Profile from "@/components/Profile/Profile.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "profile",
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("fetchCountdown");
    this.$store.dispatch("fetchUserProfile", {
      competitionId: this.$route.params.id,
      userId: this.$route.params.userId
    });
  },
  components: {
    ProfileComponent: Profile,
    Loading
  },
  methods: {
    onRefresh() {
      this.$store.dispatch("fetchCountdown");
      this.$store.dispatch("fetchUserProfile", {
        competitionId: this.$route.params.id,
        userId: this.$route.params.userId
      });
    }
  },
  computed: {
    screenHeight() {
      return this.$store.getters.screenHeight - 110;
    },
    profileUser() {
      return this.$store.getters.userProfile;
    },
    profileVideos() {
      return this.$store.getters.profileVideos;
    },
    loadingProfile() {
      return this.$store.getters.loadingProfile;
    },
    color() {
      return this.$store.getters.primaryColor;
    }
  }
};
</script>

<style scoped>
.content {
  overflow-y: scroll;
  /* -webkit-overflow-scrolling: touch; */
  margin: 110px 0 0 0;
  height: 50%;
  background-color: transparent;
  width: 100%;
}
</style>
