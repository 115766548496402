<template>
  <div style="margin-bottom: 20px">
    <div
      style="margin:10px"
      :key="index"
      v-for="(follower, index) in followersUsers"
    >
      <!-- Card content -->
      <div class="followers-container">
        <div class="followers-user-image">
          <figure class="image user-image">
            <img
              class="is-rounded"
              v-lazysizes
              :src="
                follower.follower_id.avatar !== '' &&
                follower.follower_id.avatar
                  ? follower.follower_id.avatar
                  : defaultImage
              "
              :style="{
                border: '2px solid ' + color,
                objectFit: 'cover',
                height: '100%',
                padding: '2px'
              }"
            />
          </figure>
        </div>
        <div class="followers-user-name">
          <div class="followers-name">
            <router-link
              :to="{
                name: 'profile',
                params: { userId: follower.follower_id._id, id: competitionId }
              }"
              ><h4 class="card-title" :style="cssVariable">
                {{ follower.follower_id.name }}
              </h4></router-link
            >
          </div>
        </div>
      </div>
      <hr
        style="background-color: rgba(200, 200, 200,0.5);width:100%;height:1px;margin:10px 0"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "followers",
  data() {
    return {
      // color: localStorage.getItem('color') ? localStorage.getItem('color') : '#df1616',
    };
  },
  props: ["followersUsers"],
  computed: {
    defaultImage() {
      return this.$store.getters.brandLogo;
    },
    color() {
      return this.$store.getters.primaryColor;
    },
    cssVariable() {
      return {
        "--color": this.$store.getters.primaryColor,
        "--bkcolor": this.$store.getters.secondaryColor
      };
    },
    competitionId() {
      return this.$store.getters.competitionId;
    }
  },
  methods: {}
};
</script>

<style scoped>
.followers-container {
  display: flex;
  justify-content: space-between;
}
.followers-user-image {
  width: 10%;
}
.user-image {
  width: 50px;
  height: 50px;
  margin: 0 !important;
}
.followers-user-name {
  width: 80%;
  margin: auto 0;
  text-align: start;
  vertical-align: middle;
}
.followers-name {
  text-decoration: none;
  color: #4a4a4a;
  font-size: 20px;
  font-weight: 500;
}
.card-title {
  color: #101010;
  font-family: Cairo;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
}
</style>
