<template>
  <div
    class="main-container"
    :style="
      backgroundImage
        ? {
            background: `url(${backgroundImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '100%',
          }
        : {}
    "
  >
    <div class="competition-main" id="competitionContainer">
      <div style="width: 100%; margin: 10px 0">
        <img v-lazysizes :src="logo" alt="logo" style="width: 50%" />
      </div>
      <div style="margin: 30px 0 50px 0">
        <h2 style="font-size: 24px; font-weight: 700">{{ companyName }}</h2>
      </div>
      <div class="competitons-container">
        <div
          class="competition-view"
          v-for="(competition, index) in competitions"
          :key="index"
        >
          <div
            class="single-competition-view"
            @click="competitonSelection(competition)"
            v-if="competition.isActive"
          >
            <div
              class="competition-image-container"
              :style="competition.isFinished ? { opacity: 0.5 } : {}"
            >
              <figure
                class="image competition-image"
                v-if="competition.settings && competition.settings.logos"
              >
                <img
                  class="is-rounded"
                  v-lazysizes
                  :src="
                    competition.settings.logos.competition
                      ? competition.settings.logos.competition
                      : ''
                  "
                  :style="{ objectFit: 'contain', height: '100%' }"
                />
              </figure>
            </div>
            <div
              class="competition-data"
              v-if="lang === 'ar'"
              :style="
                competition.isFinished
                  ? { margin: '5px 10px 10px 20px', opacity: 0.5 }
                  : { margin: '10px 10px 10px 20px' }
              "
            >
              <div v-if="competition.isFinished">
                <span class="finished-ribbon" :style="cssVariable">{{
                  $t("competitionIsFinished")
                }}</span>
              </div>
              <span class="competition-title">{{
                competition.name_ar | arabicize
              }}</span>
              <div class="competition-date">
                <div>
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="width: 16px; height: 16px; margin-left: 4px"
                  >
                    <g
                      id="Icons/calendar"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="calendar"
                        transform="translate(2.000000, 1.000000)"
                        fill="#fff"
                      >
                        <path
                          d="M13.75,0 C14.1296958,0 14.443491,0.282153882 14.4931534,0.648229443 L14.5,0.75 L14.5,2 L16.75,2 C18.2125318,2 19.4084043,3.1417046 19.4949812,4.58247767 L19.5,4.75 L19.5,18.75 C19.5,20.2687831 18.2687831,21.5 16.75,21.5 L16.75,21.5 L2.75,21.5 C1.23121694,21.5 0,20.2687831 0,18.75 L0,18.75 L0,4.75 C0,3.23121694 1.23121694,2 2.75,2 L2.75,2 L5,2 L5,0.75 C5,0.335786438 5.33578644,0 5.75,0 C6.12969577,0 6.44349096,0.282153882 6.49315338,0.648229443 L6.5,0.75 L6.5,2 L13,2 L13,0.75 C13,0.335786438 13.3357864,0 13.75,0 Z M18,9.5 L1.5,9.5 L1.5,18.75 C1.5,19.3972087 1.99187466,19.9295339 2.62219476,19.9935464 L2.75,20 L16.75,20 C17.4403559,20 18,19.4403559 18,18.75 L18,18.75 L18,9.5 Z M5,3.5 L2.75,3.5 C2.05964406,3.5 1.5,4.05964406 1.5,4.75 L1.5,4.75 L1.5,8 L18,8 L18,4.75 C18,4.10279131 17.5081253,3.5704661 16.8778052,3.50645361 L16.75,3.5 L14.5,3.5 L14.5,4.75 C14.5,5.16421356 14.1642136,5.5 13.75,5.5 C13.3703042,5.5 13.056509,5.21784612 13.0068466,4.85177056 L13,4.75 L13,3.5 L6.5,3.5 L6.5,4.75 C6.5,5.16421356 6.16421356,5.5 5.75,5.5 C5.37030423,5.5 5.05650904,5.21784612 5.00684662,4.85177056 L5,4.75 L5,3.5 Z"
                          id="Combined-Shape"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <div>
                  <span>
                    {{ toDateAr(competition.countdown) | arabicize }}
                  </span>
                </div>
              </div>
              <div class="competition-desc">
                <p>{{ competition.description_ar }}</p>
              </div>
              <div class="open-competition-container">
                <button
                  class="btn open-competition-button"
                  :style="cssVariable"
                  @click="competitonSelection(competition)"
                >
                  {{ $t("openCompetition") }}
                </button>
              </div>
            </div>
            <div
              class="competition-data"
              v-else
              :style="
                competition.isFinished
                  ? { margin: '5px 20px 10px 10px', opacity: 0.5 }
                  : { margin: '10px 20px 10px 10px' }
              "
            >
              <div v-if="competition.isFinished">
                <span class="finished-ribbon" :style="cssVariable">{{
                  $t("competitionIsFinished")
                }}</span>
              </div>
              <span class="competition-title">{{ competition.name }}</span>

              <div class="competition-date">
                <div>
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="width: 16px; height: 16px; margin-right: 4px"
                  >
                    <g
                      id="Icons/calendar"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="calendar"
                        transform="translate(2.000000, 1.000000)"
                        fill="#fff"
                      >
                        <path
                          d="M13.75,0 C14.1296958,0 14.443491,0.282153882 14.4931534,0.648229443 L14.5,0.75 L14.5,2 L16.75,2 C18.2125318,2 19.4084043,3.1417046 19.4949812,4.58247767 L19.5,4.75 L19.5,18.75 C19.5,20.2687831 18.2687831,21.5 16.75,21.5 L16.75,21.5 L2.75,21.5 C1.23121694,21.5 0,20.2687831 0,18.75 L0,18.75 L0,4.75 C0,3.23121694 1.23121694,2 2.75,2 L2.75,2 L5,2 L5,0.75 C5,0.335786438 5.33578644,0 5.75,0 C6.12969577,0 6.44349096,0.282153882 6.49315338,0.648229443 L6.5,0.75 L6.5,2 L13,2 L13,0.75 C13,0.335786438 13.3357864,0 13.75,0 Z M18,9.5 L1.5,9.5 L1.5,18.75 C1.5,19.3972087 1.99187466,19.9295339 2.62219476,19.9935464 L2.75,20 L16.75,20 C17.4403559,20 18,19.4403559 18,18.75 L18,18.75 L18,9.5 Z M5,3.5 L2.75,3.5 C2.05964406,3.5 1.5,4.05964406 1.5,4.75 L1.5,4.75 L1.5,8 L18,8 L18,4.75 C18,4.10279131 17.5081253,3.5704661 16.8778052,3.50645361 L16.75,3.5 L14.5,3.5 L14.5,4.75 C14.5,5.16421356 14.1642136,5.5 13.75,5.5 C13.3703042,5.5 13.056509,5.21784612 13.0068466,4.85177056 L13,4.75 L13,3.5 L6.5,3.5 L6.5,4.75 C6.5,5.16421356 6.16421356,5.5 5.75,5.5 C5.37030423,5.5 5.05650904,5.21784612 5.00684662,4.85177056 L5,4.75 L5,3.5 Z"
                          id="Combined-Shape"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <div>
                  <span>{{ toDate(competition.countdown) }}</span>
                </div>
              </div>
              <div class="competition-desc">
                <p>{{ competition.description }}</p>
              </div>
              <div class="open-competition-container">
                <button
                  class="btn open-competition-button"
                  :style="cssVariable"
                  @click="competitonSelection(competition)"
                >
                  {{ $t("openCompetition") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="getActive === 0">
        <h3>{{ $t("comingSoon") }}</h3>
      </div>
      <!--<div style="width: 100%;">
        <iframe
          :src="
            `https://www.facebook.com/plugins/like.php?href=${encodeURI(
              facebookPageUrl
            )}&width=200&layout=button_count&action=like&size=large&share=false&height=65&appId=1958558457798463`
          "
          width="200"
          height="65"
          style="border:none;overflow:hidden"
          scrolling="no"
          frameborder="0"
          allowFullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture;"
        ></iframe>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "competitionSelection",
  async created() {
    this.$store.dispatch("resetAll");
    this.$store.dispatch("fetchCompetitions");
  },

  data() {
    return {};
  },
  computed: {
    cssVariable() {
      return {
        "--color": this.$store.getters.companyPrimaryColor,
        "--color2": this.$store.getters.companySecondaryColor,
      };
    },
    color() {
      return this.$store.getters.companyPrimaryColor;
    },
    bkcolor() {
      return this.$store.getters.companySecondaryColor;
    },
    companyName() {
      return this.$store.getters.companyName;
    },
    backgroundImage() {
      return this.$store.getters.backgroundImage;
    },
    lang() {
      return this.$store.getters.lang;
    },
    competitions() {
      return this.$store.getters.competitions;
    },
    facebookPageUrl() {
      let url = this.$store.getters.companyFacebookPageUrl;
      if (url) {
        return url;
      } else {
        url = "https://www.facebook.com/shaga3app/";
        return url;
      }
    },
    getActive() {
      let counter = 0;
      if (this.competitions.length > 0) {
        this.competitions.map((c) => {
          if (c.isActive) {
            counter++;
          }
        });
      }
      return counter;
    },
    logo() {
      return this.$store.getters.companyLogo;
    },
  },
  methods: {
    competitonSelection(competition) {
      this.$store.dispatch("setCompetition", competition);
    },
    toDate(timestamp) {
      let d = new Date(timestamp);
      return d.toDateString();
    },
    toDateAr(timestamp) {
      var months = [
        "يناير",
        "فبراير",
        "مارس",
        "إبريل",
        "مايو",
        "يونيو",
        "يوليو",
        "أغسطس",
        "سبتمبر",
        "أكتوبر",
        "نوفمبر",
        "ديسمبر",
      ];
      var days = [
        "اﻷحد",
        "اﻷثنين",
        "الثلاثاء",
        "اﻷربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ];
      let d = new Date(timestamp);
      let newDate =
        days[d.getDay()] +
        " " +
        d.getDate() +
        " " +
        months[d.getMonth()] +
        " " +
        d.getFullYear();
      // return d.toDateString();
      return newDate;
    },
  },
  filters: {
    arabicize: function (value) {
      const arabicNums = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
      return value.toString().replace(/[0-9]/g, function (w) {
        return arabicNums[+w];
      });
    },
  },
};
</script>

<style scoped>
.main-container {
  background: url("../assets/mainImage/main.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.competition-main {
  height: 100%;
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  padding-top: 35px;
  overflow-y: scroll;
  /* -webkit-overflow-scrolling: touch; */
}
.competitons-container {
  display: block;
  overflow-y: scroll;
  /* -webkit-overflow-scrolling: touch; */
}
.competition-view {
  width: 100%;
}
.single-competition-view {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin: 10px auto;
  padding: 5px 10px;
  border-radius: 5px;
  position: relative;
  /* background-color: #313131; */
}
.competition-image-container {
  background: url("../assets/mainImage/backs.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.competition-image {
  width: 50px;
  height: 50px;
}
.competition-data {
  text-decoration: none;
  text-align: start;
  margin: 0 0 10px;
  flex: 2;
}
.competition-title {
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  line-height: 18px;
  color: #fff;
  margin-top: 5px;
  margin-bottom: 10px;
}
.competition-date {
  font-size: 12px;
  font-weight: 600;
  text-align: start;
  color: #fff;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.competition-desc {
  font-size: 12px;
  font-weight: 600;
  text-align: start;
  color: #fff;
  margin-bottom: 10px;
}
.open-competition-container {
  font-size: 12px;
  font-weight: 600;
  text-align: start;
  color: #fff;
  margin-bottom: 10px;
}
.open-competition-button {
  background-color: var(--color2);
  color: var(--color);
  border-radius: 25px;
}
.finished-ribbon {
  height: 16.31px;
  width: 53px;
  border-radius: 15px;
  background-color: #f01136;
  font-size: 8px;
  text-transform: uppercase;
  padding: 3px 5px;
  color: #fff;
  z-index: 10;
  margin: 5px auto;
}
</style>
