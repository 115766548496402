var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Tabs',{attrs:{"activeTab":_vm.activeTab,"TabClicked":_vm.clicked,"tabs":_vm.tabs}}),_c('div',{staticClass:"content",style:({ height: _vm.screenHeight + 'px' })},[_c('div',{staticClass:"container",staticStyle:{"margin-bottom":"50px","padding":"0"}},[_c('div',{staticClass:"row"},[(_vm.loadingFans)?_c('div',{staticClass:"col-12"},[_c('div',[_c('loading',{attrs:{"active":_vm.loadingFans,"background-color":"#fff","can-cancel":false,"is-full-page":true,"color":_vm.color},on:{"update:active":function($event){_vm.loadingFans=$event}}})],1)]):_vm._e(),(_vm.activeTab === 0)?_c('div',{staticClass:"col-12"},[_c('pull-to',{staticStyle:{"width":"100%"},attrs:{"on-refresh":_vm.onRefresh,"config":{
              errorLabel: _vm.$t('pullToRefreshError'),
              startLabel: _vm.$t('pullToRefreshLabel'),
              readyLabel: _vm.$t('pullToRefreshReady'),
              loadingLabel: _vm.$t('pullToRefreshLoading'),
              pullDownHeight: 100
            }}},[(
                typeof _vm.following !== 'undefined' && _vm.following.length !== 0
              )?_c('following',{attrs:{"followingUsers":_vm.following}}):_c('div',{style:({
                textAlign: 'center',
                width: '100%',
                height: _vm.screenHeight - 45 + 'px'
              })},[_c('img',{directives:[{name:"lazysizes",rawName:"v-lazysizes"}],staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":_vm.noFollowingsImg,"alt":"no fans"}}),_c('div',{staticClass:"no-fans-container"},[_c('span',{staticClass:"no-fans-title"},[_vm._v(_vm._s(_vm.$t("noFans")))]),_c('span',{staticClass:"no-fans-subtitle"},[_vm._v(_vm._s(_vm.$t("noFollowing")))])])])],1)],1):(_vm.activeTab === 1)?_c('div',{staticClass:"col-12"},[_c('pull-to',{staticStyle:{"width":"100%"},attrs:{"on-refresh":_vm.onRefresh,"config":{
              errorLabel: _vm.$t('pullToRefreshError'),
              startLabel: _vm.$t('pullToRefreshLabel'),
              readyLabel: _vm.$t('pullToRefreshReady'),
              loadingLabel: _vm.$t('pullToRefreshLoading'),
              pullDownHeight: 100
            }}},[(
                typeof _vm.followers !== 'undefined' && _vm.followers.length !== 0
              )?_c('followers',{attrs:{"followersUsers":_vm.followers}}):_c('div',{style:({
                textAlign: 'center',
                width: '100%',
                height: _vm.screenHeight - 45 + 'px'
              })},[_c('img',{directives:[{name:"lazysizes",rawName:"v-lazysizes"}],staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":_vm.noFollowersImg,"alt":"no fans"}}),_c('div',{staticClass:"no-fans-container"},[_c('span',{staticClass:"no-fans-title"},[_vm._v(_vm._s(_vm.$t("noFans")))]),_c('span',{staticClass:"no-fans-subtitle"},[_vm._v(_vm._s(_vm.$t("noFollower")))])])])],1)],1):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }