<template>
  <div class="promo-component-container" style="text-align: center">
    <div >
      <md-field class="promo-component-input" >
        <label :style="lang === 'ar'? {right: '10px'}: {left:  '10px'}">{{$t('promoCodeLabel')}}</label>
        <md-input
          :style="cssVariable"
          class="input"
          type="text"
          :placeholder="$t('promoCodeLabel')"
          v-model="code"
        />
      </md-field>
    </div>
    <button class="btn promo-component-button" :style="cssVariable" @click="getCodeHandler">
      {{ $t("redeemButton") }}
    </button>
  </div>
</template>

<script>
export default {
  props: ["getCode"],
  data() {
    return {
      code: "",
      error: ""
    };
  },
  methods: {
    getCodeHandler() {
      if (this.code.length !== 0) {
        this.getCode(this.code);
      } else {
        let message = "";
        let lang = localStorage.getItem("lang");
        if (lang === "0") {
          message = "ERROR enter the Promo code";
        } else {
          message = "خطء ادخل الكود";
        }
        notie.alert({ type: "error", text: message });
      }
    }
  },
  computed: {
    cssVariable() {
      return {
        "--color": this.$store.getters.primaryColor,
        "--bkcolor": this.$store.getters.secondaryColor
      };
    },
    lang() {
      return this.$store.getters.lang;
    }
  },
  components: {}
};
</script>
<style scoped>
.promo-component-container {
  text-align: center; 
  margin-top: 30px; 
}

.promo-component-input {
    border-radius: 6px;
    background-color: #F6F6F6;
    padding: 0 10px;
    padding-top: 20px!important;
    margin-bottom: 10px!important;
    margin-top: 20px!important;
  }
  .promo-component-input label {
    color: #707C80;
    font-family: Cairo!important;
    font-size: 12px!important;
    letter-spacing: 0!important;
    line-height: 23px!important;
  }
  .promo-component-input .input {
    color: #101010;
    font-family: Cairo!important;
    font-size: 15px!important;
    letter-spacing: 0.21px!important;
    line-height: 29px!important;
    box-shadow: none!important;
    margin-bottom: 10px!important;
  }

.promo-component-button {
  width: 70%;
  border-radius: 6px;
  background-color: var(--bkcolor);
  color: #FFFFFF;
  font-family: Cairo;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}
</style>
