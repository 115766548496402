import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import CompetitionSelection from "./views/CompetitionSelection.vue";
import Main from "./views/Main.vue";
import Lang from "./views/LanguageSwitcher.vue";
import GetMorePoints from "./views/GetMorePoints.vue";
import Search from "./views/Search.vue";
import Terms from "./views/Terms.vue";
import Faq from "./views/Faq.vue";
import SingleVideo from "./views/singleVideo.vue";
import SingleComment from "./views/singleComment.vue";
import SingleProfile from "./views/singleProfile.vue";
import LoginPage from "./views/LoginPage.vue";
import UserInfo from "./views/UserInfo.vue";
import Fans from "./views/Fans.vue";
import Upload from "./views/Upload.vue";
import Winners from "./views/Winners.vue";
import Profile from "./views/Profile.vue";
// import Video from "./views/Video.vue";
import Comments from "./views/Comments.vue";
import Me from "./views/Me.vue";
import ErrorPage from "./views/Error.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "competitons",
      component: CompetitionSelection
    },
    { path: "/lang", name: "lang", component: Lang },
    { path: "/points", name: "points", component: GetMorePoints },
    { path: "/search", name: "search", component: Search },
    { path: "/terms", name: "terms", component: Terms },
    { path: "/faq", name: "faq", component: Faq },
    { path: "/single-video", name: "singleVideo", component: SingleVideo },
    {
      path: "/single-comment",
      name: "singleComment",
      component: SingleComment
    },
    {
      path: "/single-profile/:id/:userId",
      name: "singleProfile",
      component: SingleProfile
    },
    // { path: "/video/:vid/:u", name: "videoPlayer", component: Video },
    { path: "/comments/:vid", name: "comments", component: Comments },
    { path: "/login", name: "login", component: LoginPage },
    { path: "/info", name: "userInfo", component: UserInfo },
    { path: "/error", name: "error-page", component: ErrorPage },
    {
      path: "/:id/",
      name: "mainView",
      component: Main,
      children: [
        { path: "/:id/", name: "home", component: Home },
        { path: "/:id/fans", name: "fans", component: Fans },
        { path: "/:id/upload", name: "uploadView", component: Upload },
        { path: "/:id/winners", name: "winners", component: Winners },
        { path: "/:id/profile/:userId", name: "profile", component: Profile },
        { path: "/:id/me/:userId", name: "me", component: Me }
      ]
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
