var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-bottom":"20px"}},_vm._l((_vm.followingUsers),function(following,index){return _c('div',{key:index,staticStyle:{"margin":"10px"}},[_c('div',{staticClass:"following-container"},[_c('div',{staticClass:"following-user-image"},[_c('figure',{staticClass:"image user-image"},[_c('img',{directives:[{name:"lazysizes",rawName:"v-lazysizes"}],staticClass:"is-rounded",style:({
              border: '2px solid ' + _vm.color,
              objectFit: 'cover',
              height: '100%',
              padding: '2px'
            }),attrs:{"src":following.following_id.avatar !== '' &&
              following.following_id.avatar
                ? following.following_id.avatar
                : _vm.defaultImage}})])]),_c('div',{staticClass:"following-user-name"},[_c('div',{staticClass:"following-name"},[_c('router-link',{attrs:{"to":{
              name: 'profile',
              params: {
                userId: following.following_id._id,
                id: _vm.competitionId
              }
            }}},[_c('h4',{staticClass:"card-title",style:(_vm.cssVariable)},[_vm._v(" "+_vm._s(following.following_id.name)+" ")])])],1)])]),_c('hr',{staticStyle:{"background-color":"rgba(200, 200, 200,0.5)","width":"100%","height":"1px","margin":"10px 0"}})])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }