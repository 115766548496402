<template>
  <div class="modal is-active is-clipped">
    <div class="modal-card" :style="height">
      <header class="modal-head" :style="cssVariable">
         <div class="modal-title">
          <span>{{ $t("pointsMainTitle") }}</span>
        </div>
        <div class="modal-card-close" @click="closeModal">
          <span style="margin: 0 2px">{{ $t("modalCloseText") }}</span>
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Icons/close"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <path
                d="M11.8766417,2.00829893 C11.4329138,2.0684958 11.0909091,2.44885362 11.0909091,2.90909091 L11.089697,11.0909091 L2.90909091,11.0909091 L2.7730982,11.1018835 C2.3164165,11.1835228 2,11.558745 2,12 L2.00829893,12.1233583 C2.0684958,12.5670862 2.44885362,12.9090909 2.90909091,12.9090909 L11.089697,12.9090909 L11.0909091,21.0909091 L11.1018835,21.2269018 C11.1835228,21.6835835 11.558745,22 12,22 L12.1233583,21.9917011 C12.5670862,21.9315042 12.9090909,21.5511464 12.9090909,21.0909091 L12.9078788,12.9090909 L21.0909091,12.9090909 L21.2269018,12.8981165 C21.6835835,12.8164772 22,12.441255 22,12 L21.9917011,11.8766417 C21.9315042,11.4329138 21.5511464,11.0909091 21.0909091,11.0909091 L12.9078788,11.0909091 L12.9090909,2.90909091 L12.8981165,2.7730982 C12.8164772,2.3164165 12.441255,2 12,2 L11.8766417,2.00829893 Z"
                id="Combined-Shape"
                fill="#D83C3E"
                transform="translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) "
              ></path>
            </g>
          </svg>
        </div>  
          
      </header>
      <section class="modal-body" style="padding: 15px 20px;">
        <div v-if="active === 0">
          <div style="text-align: start;padding: 10px 20px;">
            <h6 style="text-transform: uppercase;">{{ $t("PointsTitle") }}</h6>
          </div>
          <div class="points-items-container">
            <div class="points-item" @click="() => chooseItem(1)" v-if="qrCode">
              <i class="fas fa-qrcode" :style="cssVariable"></i>
              <h3>{{ $t("ScannerTitle") }}</h3>
            </div>
            <div class="points-item" @click="() => chooseItem(2)" v-if="promoCode">
              <i class="fas fa-key" :style="cssVariable"></i>
              <h3>{{ $t("PromoCodeTitle") }}</h3>
            </div>
            <!-- <div class="points-item" @click="() => chooseItem(1)">
              <i class="fas fa-qrcode" :style="cssVariable"></i>
              <h3>{{ $t("ScannerTitle") }}</h3>
            </div>
            <div class="points-item" @click="() => chooseItem(1)">
              <i class="fas fa-qrcode" :style="cssVariable"></i>
              <h3>{{ $t("ScannerTitle") }}</h3>
            </div> -->
          </div>
        </div>
        <div v-if="active === 1">
          <div style="text-align: start;padding: 10px 20px;color: #fff">
            <i
              class="fas fa-chevron-left"
              @click="() => chooseItem(0)"
              style="margin-right: 20px;font-size:24px"
            ></i>
            <span style="text-transform: uppercase;">{{
              $t("ScannerTitle")
            }}</span>
          </div>
          <qr-scanner :getCode="getCodeHandler" />
        </div>
        <div v-if="active === 2">
          <!-- <div class="promo-code-title">
            <span>{{ $t("PromoCodeTitle") }}</span>
          </div> -->
          <promo-code :getCode="getPromoCodeHandler" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import QrScanner from "../components/GetPoints/QrCodeScanner.vue";
import PromoCode from "../components/GetPoints/PromoCode.vue";
export default {
  data() {
    return {
      active: 2
    };
  },
  created() {
    this.$store.dispatch('fetchMethods', {competitionId:this.$route.params.id})
  },
  methods: {
    closeModal() {
      this.$router.back();
    },
    chooseItem(val) {
      this.active = val;
    },
    getPromoCodeHandler(code) {
      this.$store.dispatch('promoCodeRedeem', {code})
    }
  },
  computed: {
    cssVariable() {
      return {
        "--color": this.$store.getters.primaryColor,
        "--bkcolor": this.$store.getters.secondaryColor
      };
    },
    lang() {
      return this.$store.getters.lang;
    },
    promoCode() {
      return this.$store.getters.promoCode;
    },
    qrCode() {
      return this.$store.getters.qrCode;
    },
    height() {
      return {
        "--height": this.$store.getters.screenHeight + "px"
      };
    }
  },
  components: {
    QrScanner,
    PromoCode
  }
};
</script>

<style scoped>
/* custom modal style */

.points-items-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.points-item {
  width: 30%;
  margin: 10px;
  padding: 5px;
  text-align: center;
}
.points-item i {
  background-color: var(--bkcolor);
  color: var(--color);
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 50%;
}
.promo-code-title {
  color: #101010;
  font-family: Cairo;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37px;
  text-align: center;
}
</style>
