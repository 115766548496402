<template>
  <div class="login-main" :style="cssVariable">
    <div class="company-logo">
      <img alt="Logo" v-lazysizes :src="logo" />
    </div>
    <div>
      <h4>{{ $t("loginTitle") }}</h4>
    </div>
    <div class="login-form">
      <div>
        <div style="text-align: center;margin: 10px 0;">
          <h6>{{ $t("enterDataSubtitle") }}</h6>
        </div>
        <md-field class="custom-info-input">
          <label
            class="label"
            :style="lang === 'ar' ? { right: '0' } : { left: '0' }"
            >{{ $t("loginInfoName") }}</label
          >
          <div style="width: 100%;">
            <md-input
              class="input"
              type="text"
              :placeholder="$t('loginInfoName')"
              @input="nameTouch = true"
              v-model="form.name"
              style="width: 100%;box-shadow: none;"
            />
          </div>
        </md-field>
        <div>
          <p v-if="!$v.form.name.required && nameTouch" class="help is-danger">
            {{ $t("nameRequiredError") }}
          </p>
          <p
            v-else-if="!$v.form.name.maxLength && nameTouch"
            class="help is-danger"
          >
            {{ $t("nameMaxLengthError") }}
          </p>
        </div>
        <md-field class="custom-info-input">
          <label
            class="label"
            :style="lang === 'ar' ? { right: '0' } : { left: '0' }"
            >{{ $t("loginInfoEmail") }}</label
          >
          <md-input
            class="input"
            type="email"
            :placeholder="$t('loginInfoEmail')"
            @input="emailTouch = true"
            v-model="form.email"
            style="width: 100%;box-shadow: none;"
          />
        </md-field>
        <div>
          <p
            v-if="!$v.form.email.required && emailTouch"
            class="help is-danger"
          >
            {{ $t("emailRequiredError") }}
          </p>
          <p
            v-else-if="!$v.form.email.email && emailTouch"
            class="help is-danger"
          >
            {{ $t("NotemailError") }}
          </p>
        </div>
        <md-field class="custom-info-input" v-if="isDegla">
          <label
            class="label"
            :style="lang === 'ar' ? { right: '0' } : { left: '0' }"
            >{{!isDegla? $t("loginInfoMember") :$t("loginInfoMemberDegla") }}</label
          >
          <md-input
            class="input"
            type="number"
            :placeholder="!isDegla? $t('loginInfoMember') :$t('loginInfoMemberDegla')"
            v-model="form.member"
            style="width: 100%;box-shadow: none;"
          />
        </md-field>
        <div>
          <p v-if="memberInvalid" class="help is-danger">
            {{ $t("memberInvalid") }}
          </p>
        </div>
        <div style="width: 100%;text-align: center">
          <button
            class="btn button-submit"
            :style="cssVariable"
            @click="sendInfoHandler"
          >
            {{ $t("submitLoginData") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import { validationMixin } from "vuelidate";
import { required, email, numeric, maxLength } from "vuelidate/lib/validators";
export default {
  name: "login",
  mixins: [validationMixin],
  created() {},
  data() {
    return {
      emailTouch: false,
      nameTouch: false,
      memberInvalid: false,
      form: {
        name: "",
        email: "",
        member: ""
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      name: {
        required,
        maxLength: maxLength(25)
      }
    }
  },
  computed: {
    isDegla() {
      if(localStorage.getItem('sl') === 'degla'){
        return true;
      }
      else { return false}
    },
    error() {
      return this.$store.getters.loginError;
    },
    cssVariable() {
      return {
        "--color": this.$store.getters.primaryColor,
        "--color2": this.$store.getters.secondaryColor
      };
    },
    lang() {
      return this.$store.getters.lang;
    },
    logo() {
      return this.$store.getters.brandLogo;
    }
  },
  methods: {
    sendInfoHandler() {
      this.emailTouch = true;
      this.nameTouch = true;
      if (this.$v.form.email.required && this.$v.form.email.email) {
        if (this.$v.form.name.maxLength && this.$v.form.name.required) {
          if (
            this.form.member.substring(0, 5) === "00400" ||
            this.form.member.substring(0, 5) === "00100" ||
            this.form.member.length === 0
          ) {
            if (this.form.member.length > 5 || this.form.member.length === 0) {
              this.$store.dispatch("sendInfo", {
                email: this.form.email,
                name: this.form.name,
                membership_id: this.form.member
              });
              this.memberInvalid = false;
            } else {
              this.memberInvalid = true;
            }
          } else {
            this.memberInvalid = true;
          }
        }
      }
    }
  },
  components: {
    VueTelInput
  }
};
</script>

<style>
.login-main {
  background-color: #fff;
  height: 100%;
  color: #191919;
  text-align: center;
  font-size: 1.5rem;
  padding-top: 35px;
  overflow-y: scroll;
  /* -webkit-overflow-scrolling: touch; */
}
.company-logo {
  width: 30%;
  margin: 20px auto;
  margin-bottom: 30px;
}
.login-form {
  padding: 10px;
  font-size: 12px;
  text-align: start;
}
.button-submit {
  background-color: transparent;
  box-shadow: none;
  color: var(--color);
  width: 80%;
  font-weight: 700;
  border-radius: 5px;
  margin-top: 20px;
}


.custom-info-input {
  border-radius: 6px;
  background-color: #f6f6f6;
  padding: 0 10px;
  padding-top: 20px !important;
  margin-bottom: 10px !important;
  margin-top: 20px !important;
}
</style>
