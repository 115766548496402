import Vue from "vue";
import Vuex from "vuex";
import Home from "./modules/home";
import Profile from "./modules/profile";
import Winners from "./modules/winners";
import Fans from "./modules/fans";
import Points from "./modules/points";
import Competition from "./modules/competition";
import UploadVideo from "./modules/upload";
import Comments from "./modules/comments";
import Search from "./modules/search";
import Single from "./modules/single";
import Login from "./modules/login";
import router from "../router";
import vuexI18n from "vuex-i18n";

import translationsEn from "../locale/en";
import translationsAr from "../locale/ar";

import axios from "../axios-auth";
// import router from '../router';
// import globalAxios from 'axios';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    company: {
      _id: "",
      name: "",
      slug: "",
      options: {
        url: "",
        logos: {
          brand: "",
          header: "",
        },
        primaryColor: "",
        secondaryColor: "",
        facebookPageUrl: "",
      },
    },
    user: {
      _id: "",
      company_id: "",
      name: "",
      uuid: "",
    },
    status: 0,
    curLanguage: {
      short: "en",
      long: "English",
    },
    languages: [
      {
        short: "en",
        long: "English",
      },
      {
        short: "ar",
        long: "Arabic",
      },
    ],
    isUploading: false,
    videoLimit: 0,
    videosPerUser: 0,
    screenHeight: 0,
    loading: false,
    token: null,
    countdown: null,
    competitionSelected: null,
    singleVideo: null,
    singleComment: null,
    singleProfile: null,
    selectedCompetition: null,
    competitionIsFinished: false,
    competitionLocked: false,
  },
  mutations: {
    screenHeight(state, initalHeigh) {
      state.screenHeight = initalHeigh;
    },
    loading(state, loading) {
      state.loading = loading;
    },
    competitionIsFinished(state, data) {
      state.competitionIsFinished = data;
    },
    competitionLocked(state, data) {
      state.competitionLocked = data;
    },
    companyData(state, data) {
      state.company = data;
      localStorage.setItem("sl", data.slug);
    },
    userData(state, data) {
      state.user = data;
    },
    language(state, lang) {
      state.curLanguage = lang;
    },
    status(state, status) {
      state.status = status;
    },
    token(state, token) {
      state.token = token;
    },
    countdown(state, count) {
      state.countdown = count;
    },
    competitionSelected(state, id) {
      state.competitionSelected = id;
    },
    videoLimit(state, num) {
      state.videoLimit = num;
    },
    videosPerUser(state, num) {
      state.videosPerUser = num;
    },
    isUploading(state, val) {
      state.isUploading = val;
    },
    singleVideoData(state, data) {
      state.singleVideo = data;
    },
    removeSingleVideoData(state) {
      state.singleVideo = null;
    },
    singleCommentData(state, data) {
      state.singleComment = data;
    },
    removeSingleComment(state) {
      state.singleComment = null;
    },
    singleProfileData(state, data) {
      state.singleProfile = {
        profile: data.profile,
        competitionId: data.competitionId,
      };
    },
    removeSingleProfile(state) {
      state.singleProfile = null;
    },
    selectedCompetitionData(state, competition) {
      state.selectedCompetition = competition;
    },
  },
  actions: {
    screenHeighInit({ commit }, height) {
      if (height !== 0) {
        commit("screenHeight", height);
      } else {
        commit("error", "could not define height for the screen");
      }
    },
    resetMessage({ commit }) {
      commit("message", "");
      commit("messageType", "");
    },
    setLang({ commit, state }, lang) {
      if (typeof lang === "undefined") {
        commit("language", state.languages[0]);
      } else {
        commit("language", state.languages[lang]);
      }
    },
    login({ commit }, data) {
      commit("loading", true);
      let token = localStorage.getItem("token");
      let slug = localStorage.getItem("sl");
      if (typeof data.token !== "undefined" || token) {
        let currentToken = data.token;
        commit("companyData", []);
        commit("userData", []);
        commit("competitonsData", []);
        if (typeof data.token === "undefined") {
          currentToken = token;
        }
        let navigator_info = window.navigator;
        let screen_info = window.screen;
        let uid = navigator_info.mimeTypes.length || "";
        uid += navigator_info.userAgent.replace(/\D+/g, "") || "";
        uid += navigator_info.plugins.length;
        uid += screen_info.height || "";
        uid += screen_info.width || "";
        uid += screen_info.pixelDepth || "";
        let params = {
          foeihq: uid,
        };
        const url = "/init";
        axios
          .get(url, { headers: { "X-Auth-Token": currentToken }, params })
          .then((res) => {
            if (
              res.data.user.uuid === "guest" &&
              token &&
              slug === res.data.company.slug
            ) {
              axios
                .get(url, { headers: { "X-Auth-Token": token }, params })
                .then((res) => {
                  commit("companyData", res.data.company);
                  commit("userData", res.data.user);
                  commit("competitonsData", res.data.competitions);
                  let competitionId = localStorage.getItem("competitionId");
                  if (
                    typeof competitionId !== "undefined" &&
                    competitionId &&
                    competitionId !== ""
                  ) {
                    let competitions = res.data.competitions;
                    competitions.map((c) => {
                      if (c._id === competitionId) {
                        commit("competitionSelected", c._id);
                        commit("selectedCompetitionData", c);
                        commit("videoLimit", c.videoLimit);
                        commit("videosPerUser", c.videosPerUser);
                      }
                    });
                  }
                  commit("status", 200);
                  localStorage.setItem("token", token);
                  commit("token", token);
                  commit("loading", false);
                })
                .catch((error) => {
                  console.log("error login: " + error);
                });
            } else {
              commit("companyData", res.data.company);
              commit("userData", res.data.user);
              commit("competitonsData", res.data.competitions);
              commit("status", 200);
              commit("token", currentToken);
              localStorage.setItem("token", currentToken);
              commit("loading", false);
            }
          })
          .catch((error) => {
            console.log("error login:" + error);
            localStorage.removeItem("token");
            commit("status", 400);
            router.push({ name: "error-page" });
            commit("loading", false);
          });
      } else {
        commit("status", 400);
        router.push("/error");
        commit("loading", false);
      }
    },
    fetchCountdown({ commit, state }) {
      if (state.token) {
        const competitionId = router.history.current.params.id;
        commit("countdown", "");
        if (competitionId) {
          const url = "/countdown";
          const params = {
            competition_id: competitionId,
          };
          axios
            .get(url, { params, headers: { "X-Auth-Token": state.token } })
            .then((res) => {
              commit("countdown", res.data.countdown);
              commit("competitionIsFinished", res.data.isFinished);
              commit("competitionLocked", res.data.locked);
            })
            .catch((error) => {
              console.log("ERROR fetchCountdown: " + error);
              commit("loading", false);
            });
        }
      }
    },
    checkNewCountDown({ commit, state, dispatch }) {
      if (state.token) {
        const competitionId = router.history.current.params.id;
        let now = Math.floor(new Date().getTime());
        commit("countdown", "");
        if (competitionId) {
          const url = "/countdown";
          const params = {
            competition_id: competitionId,
          };
          axios
            .get(url, { params, headers: { "X-Auth-Token": state.token } })
            .then((res) => {
              let countDown = res.data.countdown * 1000;
              if (countDown > now) {
                commit("resetHomeVideos");
                router.push({ name: "winners", params: { id: competitionId } });
                commit("competitionLocked", false);
                commit("countdown", res.data.countdown);
              } else {
                if (!res.data.isFinished) {
                  setTimeout(() => {
                    dispatch("checkNewCountDown");
                  }, 10000);
                }
              }
            })
            .catch((error) => {
              console.log("ERROR fetchCountdown: " + error);
              commit("loading", false);
            });
        }
      }
    },
    resetAll({ commit, state }) {
      commit("resetHomeVideos");
      // commit('resetWinners');
      commit("resetFans");
      if (state.singleVideo) {
        router.push("/single-video");
      } else if (state.singleComment) {
        router.push("/single-comment");
      } else if (state.singleProfile) {
        router.push({
          name: "singleProfile",
          params: {
            id: state.singleProfile.competitionId,
            userId: state.singleProfile.profile,
          },
        });
      }
    },
    setCompetition({ commit, state }, data) {
      if (data._id !== "") {
        localStorage.setItem("competitionId", data._id);
        commit("competitionSelected", data._id);
        commit("selectedCompetitionData", data);
        commit("videoLimit", data.videoLimit);
        commit("videosPerUser", data.videosPerUser);
        let user = state.user;
        if (
          typeof user.name === "undefined" &&
          typeof user.email === "undefined" &&
          !user.member
        ) {
          router.push("/info");
        } else {
          router.push({ name: "home", params: { id: data._id } });
        }
      }
    },
    checkCompetition({ commit, state }, id) {
      let f = 0;
      let competitions = [...state.Competition.competitions];
      competitions.map((c) => {
        if (c._id === id) {
          f = 1;
          commit("competitionSelected", c._id);
          commit("selectedCompetitionData", c);
          commit("videoLimit", c.videoLimit);
          commit("videosPerUser", c.videosPerUser);
        }
      });
      if (f === 0) {
        router.push("/");
      }
    },
    saveSingleVideo({ commit }, data) {
      commit("singleVideoData", data);
      sessionStorage.setItem("show", 0);
      sessionStorage.setItem("vid", data);
    },
    removeSingle({ commit }) {
      sessionStorage.setItem("show", 1);
      commit("removeSingle");
      commit("removeSingleVideoData");
    },
    saveSingleComment({ commit }, data) {
      commit("singleCommentData", data);
      sessionStorage.setItem("showC", 0);
      sessionStorage.setItem("com", data);
    },
    removeSingleComment({ commit }) {
      sessionStorage.setItem("showC", 1);
      commit("removeSingleComment");
    },
    saveSingleProfile({ commit }, data) {
      commit("singleProfileData", data);
      sessionStorage.setItem("showP", 0);
      sessionStorage.setItem("pro", data.profile);
      sessionStorage.setItem("compid", data.competitionId);
    },
    removeSingleProfile({ commit }) {
      sessionStorage.setItem("showP", 1);
      commit("removeSingleProfile");
    },
  },
  getters: {
    shareMessageEn: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.shareMessageEn) {
          if (state.selectedCompetition.shareMessageEn.length !== 0) {
            return state.selectedCompetition.shareMessageEn;
          }
        }
      }
      return "the video's description had been copied successfully please paste it in the share's text to gain more likes";
    },
    shareMessageAr: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.shareMessageAr) {
          if (state.selectedCompetition.shareMessageAr.length !== 0) {
            return state.selectedCompetition.shareMessageAr;
          }
        }
      }
      return "تم نسخ وصف الفيديو بنجاح ، يرجى لصقه في نص المشاركة لكسب المزيد من الإعجابات";
    },
    socialQuoteEn: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.socialQuoteEn) {
          if (state.selectedCompetition.socialQuoteEn.length !== 0) {
            return state.selectedCompetition.socialQuoteEn;
          }
        }
      }
      return "Cheer & like my video inside this competition please so i can win";
    },
    socialQuoteAr: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.socialQuoteAr) {
          if (state.selectedCompetition.socialQuoteAr.length !== 0) {
            return state.selectedCompetition.socialQuoteAr;
          }
        }
      }
      return "يلا شجعوني و تعملوا لايك علي الفيديو بتاعي علشان اكسب";
    },
    socialHashtag: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.socialHashtag) {
          if (state.selectedCompetition.socialHashtag.length !== 0) {
            return state.selectedCompetition.socialHashtag;
          }
        }
      }
      return "shaga3app";
    },
    companyPrimaryColor: (state) => {
      return state.company.options.primaryColor;
    },
    selectedCompetitionIsFinished: (state) => {
      return state.selectedCompetition.isFinished;
    },
    companySecondaryColor: (state) => {
      return state.company.options.secondaryColor;
    },
    companyLogo: (state) => {
      return state.company.options.logos.brand;
    },
    companyFacebookPageUrl: (state) => {
      return state.company.options.facebookPageUrl;
    },
    competitionLogo: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.settings) {
          if (state.selectedCompetition.settings.logos) {
            if (state.selectedCompetition.settings.logos.competition) {
              return state.selectedCompetition.settings.logos.competition;
            }
          }
        }
      }
      return state.company.options.logos.brand;
    },
    primaryColor: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.settings) {
          if (state.selectedCompetition.settings.primaryColor) {
            return state.selectedCompetition.settings.primaryColor;
          }
        }
      }
      return state.company.options.primaryColor;
    },
    secondaryColor: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.settings) {
          if (state.selectedCompetition.settings.secondaryColor) {
            return state.selectedCompetition.settings.secondaryColor;
          }
        }
      }
      return state.company.options.secondaryColor;
    },
    brandLogo: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.settings) {
          if (state.selectedCompetition.settings.logos) {
            if (state.selectedCompetition.settings.logos.brand) {
              return state.selectedCompetition.settings.logos.brand;
            }
          }
        }
      }
      return state.company.options.logos.brand;
    },
    homeLogo: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.settings) {
          if (state.selectedCompetition.settings.logos) {
            if (state.selectedCompetition.settings.logos.home) {
              return state.selectedCompetition.settings.logos.home;
            } else if (state.selectedCompetition.settings.logos.brand) {
              return state.selectedCompetition.settings.logos.brand;
            }
          }
        }
      }
      return state.company.options.logos.brand;
    },
    headerLogo: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.settings) {
          if (state.selectedCompetition.settings.logos) {
            if (state.selectedCompetition.settings.logos.header) {
              return state.selectedCompetition.settings.logos.header;
            }
          }
        }
      }
      return state.company.options.logos.header;
    },
    fansLogo: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.settings) {
          if (state.selectedCompetition.settings.logos) {
            if (state.selectedCompetition.settings.logos.fans) {
              return state.selectedCompetition.settings.logos.fans;
            }
          }
        }
      }
      return state.company.options.logos.fans;
    },
    profileLogo: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.settings) {
          if (state.selectedCompetition.settings.logos) {
            if (state.selectedCompetition.settings.logos.profile) {
              return state.selectedCompetition.settings.logos.profile;
            }
          }
        }
      }
      return state.company.options.logos.profile;
    },
    uploadLogo: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.settings) {
          if (state.selectedCompetition.settings.logos) {
            if (state.selectedCompetition.settings.logos.upload) {
              return state.selectedCompetition.settings.logos.upload;
            }
          }
        }
      }
      return state.company.options.logos.upload;
    },
    powerLogo: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.settings) {
          if (state.selectedCompetition.settings.logos) {
            if (state.selectedCompetition.settings.logos.power) {
              return state.selectedCompetition.settings.logos.power;
            }
          }
        }
      }
      return "";
    },
    winnersLogo: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.settings) {
          if (state.selectedCompetition.settings.logos) {
            if (state.selectedCompetition.settings.logos.winners) {
              return state.selectedCompetition.settings.logos.winners;
            }
          }
        }
      }
      return state.company.options.logos.winners;
    },
    companyUrl: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.settings) {
          if (state.selectedCompetition.settings.url) {
            return state.selectedCompetition.settings.url;
          }
        }
      }
      return state.company.options.url;
    },
    powerUrl: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.settings) {
          if (state.selectedCompetition.settings.powerUrl) {
            return state.selectedCompetition.settings.powerUrl;
          }
        }
      }
      return "";
    },
    currentUser: (state) => {
      return state.user._id;
    },
    currentUserAvatar: (state) => {
      return state.user.avatar;
    },
    loading: (state) => {
      return state.loading;
    },
    lang: (state) => {
      return state.curLanguage.short;
    },
    screenHeight: (state) => {
      return state.screenHeight;
    },
    status: (state) => {
      return state.status;
    },
    allLanguages: (state) => {
      return state.languages;
    },
    countdown: (state) => {
      return state.countdown;
    },
    competitionId: (state) => {
      return localStorage.getItem("competitionId");
    },
    isGuest: (state) => {
      return state.user.uuid === "guest";
    },
    isUploading: (state) => {
      return state.isUploading;
    },
    currentComp: (state) => {
      return state.company.slug;
    },
    companyName: (state) => {
      return state.company.name;
    },
    backgroundImage: (state) => {
      if (state.company.backgroundImage) {
        return state.company.backgroundImage;
      } else {
        return null;
      }
    },
    companyTerms: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.terms) {
          return state.selectedCompetition.terms;
        }
      }
      return state.company.terms;
    },
    companyTermsAr: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.terms_ar) {
          return state.selectedCompetition.terms_ar;
        }
      }
      return state.company.terms_ar;
    },
    companyFaq: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.faq) {
          return state.selectedCompetition.faq;
        }
      }
      return state.company.faq;
    },
    companyFaqAr: (state) => {
      if (state.selectedCompetition) {
        if (state.selectedCompetition.faq_ar) {
          return state.selectedCompetition.faq_ar;
        }
      }
      return state.company.faq_ar;
    },
    videoLimit: (state) => {
      return state.videoLimit;
    },
    competitionIsFinished: (state) => {
      return state.competitionIsFinished;
    },
    competitionLocked: (state) => {
      return state.competitionLocked;
    },
    singleComment: (state) => {
      return state.singleComment;
    },
  },
  modules: {
    Home,
    Profile,
    Winners,
    Fans,
    Points,
    Competition,
    UploadVideo,
    Comments,
    Search,
    Single,
    Login,
  },
});

Vue.use(vuexI18n.plugin, store);
Vue.i18n.add("en", translationsEn);
Vue.i18n.add("ar", translationsAr);

export default store;
