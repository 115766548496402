<template>
  <nav
    class="navbar is-fixed-bottom"
    role="navigation"
    aria-label="main navigation"
    id="footer"
    :style="{
      padding: 0,
      maxWidth: '1024px',
      margin: '0 auto'
    }"
  >
    <div class="footer-list">
      <ul class="footer-list-items">
        <!-- home -->
        <li
          class="footer-list-item"
          :style="
            active === 'home'
              ? { borderTop: '1px solid ' + secondaryColor }
              : { borderTop: '1px solid #fff' }
          "
        >
          <router-link
            :to="{ name: 'home', params: { id: id } }"
            :event="isUploading ? '' : 'click'"
          >
            <div style="margin:0 auto;width: 50px;padding: 2px 0;">
              <img
                class="footer-image"
                :style="active === 'home' ? {} : { filter: 'grayscale(1)' }"
                v-lazysizes
                :src="home"
                v-if="home && home !== ''"
              />
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                v-else
              >
                <g
                  id="Icons/home"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="home"
                    transform="translate(3.000000, 2.000000)"
                    :fill="active === 'home' ? secondaryColor : '#B7B7B7'"
                  >
                    <path
                      d="M2,20.75 C0.481216938,20.75 -0.75,19.5187831 -0.75,18 L-0.75,18 L-0.75,7 C-0.75,6.76855718 -0.643145367,6.55007799 -0.46045546,6.40798584 L-0.46045546,6.40798584 L8.53954454,-0.592014163 C8.8103774,-0.802661946 9.1896226,-0.802661946 9.46045546,-0.592014163 L9.46045546,-0.592014163 L18.4604555,6.40798584 C18.6431454,6.55007799 18.75,6.76855718 18.75,7 L18.75,7 L18.75,18 C18.75,19.5187831 17.5187831,20.75 16,20.75 L16,20.75 Z M9,0.95 L0.75,7.366 L0.75,18 C0.75,18.6472087 1.24187466,19.1795339 1.87219476,19.2435464 L1.87219476,19.2435464 L2,19.25 L5.25,19.25 L5.25,12 C5.25,11.6203042 5.53215388,11.306509 5.89822944,11.2568466 L6,11.25 L12,11.25 C12.3796958,11.25 12.693491,11.5321539 12.7431534,11.8982294 L12.75,12 L12.75,19.25 L16,19.25 C16.6472087,19.25 17.1795339,18.7581253 17.2435464,18.1278052 L17.25,18 L17.25,7.367 L9,0.95 Z M11.25,12.75 L6.75,12.75 L6.75,19.25 L11.25,19.25 L11.25,12.75 Z"
                      id="Combined-Shape"
                    ></path>
                  </g>
                </g>
              </svg>
              <span
                class="footer-link-text"
                :style="
                  active === 'home'
                    ? { color: secondaryColor }
                    : { color: '#B7B7B7' }
                "
                >{{ $t("mainFooterTabHome") }}</span
              >
            </div>
          </router-link>
        </li>
        <!-- followers -->
        <li
          class="footer-list-item"
          :style="
            active === 'fans'
              ? { borderTop: '1px solid ' + secondaryColor }
              : { borderTop: '1px solid #fff' }
          "
        >
          <router-link
            :to="{ name: 'fans', params: { id: id } }"
            :event="isUploading ? '' : 'click'"
          >
            <div style="margin:0 auto; width: 50px;padding: 2px 0;">
              <img
                class="footer-image"
                :style="active === 'fans' ? {} : { filter: 'grayscale(1)' }"
                v-lazysizes
                :src="fans"
                v-if="fans && fans !== ''"
              />
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                v-else
              >
                <g
                  id="Icons/fans"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="thumbs-up"
                    transform="translate(1.000000, 1.000000)"
                    :fill="active === 'fans' ? secondaryColor : '#B7B7B7'"
                  >
                    <path
                      d="M3,21.75 C1.53746816,21.75 0.341595721,20.6082954 0.255018794,19.1675223 L0.25,19 L0.25,12 C0.25,10.5374682 1.3917046,9.34159572 2.83247767,9.25501879 L3,9.25 L5.512,9.25 L9.31464134,0.69539615 C9.41997034,0.458405892 9.63877654,0.295142158 9.8903915,0.258029197 L10,0.25 C12.0710678,0.25 13.75,1.92893219 13.75,4 L13.75,4 L13.75,7.25 L18.6515072,7.25004809 C19.3910588,7.24167306 20.0997807,7.53139717 20.6205598,8.04870635 L20.6205598,8.04870635 L20.7578127,8.1953803 C21.2872408,8.80422269 21.5225842,9.61484915 21.4013358,10.4136715 L21.4013358,10.4136715 L20.0215218,19.4124521 C19.8159129,20.7682601 18.6428622,21.7654065 17.28,21.7503028 L17.28,21.7503028 L3,21.75 Z M5.249,20.25 L5.249,10.75 L3,10.75 C2.35279131,10.75 1.8204661,11.2418747 1.75645361,11.8721948 L1.75,12 L1.75,19 C1.75,19.6472087 2.24187466,20.1795339 2.87219476,20.2435464 L3,20.25 L5.249,20.25 Z M10.465,1.798 L6.75,10.158 L6.75,20.25 L17.2883807,20.2502557 C17.8701979,20.2566783 18.3735143,19.8622414 18.5141766,19.307555 L18.5141766,19.307555 L18.5386642,19.1863285 L19.9184928,10.1874519 C19.9735278,9.82486149 19.8665535,9.45639492 19.6259044,9.17964838 C19.3852552,8.90290183 19.0352126,8.74579901 18.66,8.75 L18.66,8.75 L13,8.75 C12.5857864,8.75 12.25,8.41421356 12.25,8 L12.25,8 L12.25,4 C12.25,2.96446609 11.5504449,2.09232203 10.5981391,1.83037217 L10.5981391,1.83037217 L10.465,1.798 Z"
                      id="Combined-Shape"
                    ></path>
                  </g>
                </g>
              </svg>
              <span
                class="footer-link-text"
                :style="
                  active === 'fans'
                    ? { color: secondaryColor }
                    : { color: '#B7B7B7' }
                "
                >{{ $t("mainFooterTabFans") }}</span
              >
            </div>
          </router-link>
        </li>
        <!-- upload -->
        <li class="footer-list-item">
          <router-link
            :to="{ name: 'uploadView' }"
            :event="isUploading ? '' : 'click'"
          >
            <div
              style="position: absolute;text-align: center;width: 60px;height: 60px;top: -20px;left: calc(50% - 30px);"
              v-if="upload && upload !== ''"
            >
              <img :style="{ margin: '0 auto' }" v-lazysizes :src="upload" />
            </div>
            <div
              style="position: absolute;text-align: center;width: 60px;height: 60px;top: -20px;left: calc(50% - 30px);"
              v-else
            >
              <svg
                width="55"
                height="55"
                viewBox="0 0 55 55"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="margin: 0 auto;height:60px"
              >
                <circle cx="27.5" cy="27.5" r="27.5" :fill="secondaryColor" />
                <path
                  d="M19.7983 25.2587C20.5534 25.2587 21.1656 24.6465 21.1656 23.8914C21.1656 23.1362 20.5534 22.5241 19.7983 22.5241C19.0431 22.5241 18.431 23.1362 18.431 23.8914C18.431 24.6465 19.0431 25.2587 19.7983 25.2587Z"
                  :fill="active === 'uploadView' ? primaryColor : '#fff'"
                />
                <path
                  d="M37.0107 19.0912H34.3029L34.0617 18.5818C33.5791 17.6166 32.6139 17 31.5416 17H23.9544C22.882 17 21.8901 17.6166 21.4343 18.5818L21.193 19.0912H18.4584C16.555 19.0912 15 20.6461 15 22.5496V33.5416C15 35.445 16.555 37 18.4584 37H36.9839C38.8874 37 40.4424 35.445 40.4424 33.5416V22.5496C40.4692 20.6461 38.9142 19.0912 37.0107 19.0912ZM38.5925 33.5684C38.5925 34.4531 37.8686 35.1769 36.9839 35.1769H18.4584C17.5737 35.1769 16.8499 34.4531 16.8499 33.5684V22.5764C16.8499 21.6917 17.5737 20.9678 18.4584 20.9678H21.7292C22.0777 20.9678 22.3995 20.7534 22.5603 20.4584L23.0697 19.4397C23.2306 19.118 23.5523 18.9035 23.9276 18.9035H31.5147C31.8901 18.9035 32.2118 19.118 32.3727 19.4397L32.882 20.4584C33.0429 20.7802 33.3646 20.9678 33.7131 20.9678H36.9839C37.8686 20.9678 38.5925 21.6917 38.5925 22.5764V33.5684Z"
                  :fill="active === 'uploadView' ? primaryColor : '#fff'"
                />
                <path
                  d="M27.736 21.6908C24.5724 21.6908 21.9987 24.2645 21.9987 27.428C21.9987 30.5916 24.5724 33.1921 27.736 33.1921C30.8995 33.1921 33.4732 30.6184 33.4732 27.4548C33.4732 24.2913 30.8995 21.6908 27.736 21.6908ZM27.736 31.3154C25.5912 31.3154 23.8486 29.5728 23.8486 27.428C23.8486 25.2833 25.5912 23.5674 27.736 23.5674C29.8808 23.5674 31.6234 25.3101 31.6234 27.4548C31.6234 29.5996 29.8808 31.3154 27.736 31.3154Z"
                  :fill="active === 'uploadView' ? primaryColor : '#fff'"
                />
              </svg>
            </div>
          </router-link>
        </li>
        <!-- winners -->
        <li
          class="footer-list-item"
          :style="
            active === 'winners'
              ? { borderTop: '1px solid ' + secondaryColor }
              : { borderTop: '1px solid #fff' }
          "
        >
          <router-link
            :to="{ name: 'winners', params: { id: id } }"
            :event="isUploading ? '' : 'click'"
          >
            <div style="margin:0 auto; width: 50px;padding: 2px 0;">
              <img
                class="footer-image"
                :style="active === 'winners' ? {} : { filter: 'grayscale(1)' }"
                v-lazysizes
                :src="winners"
                v-if="winners && winners !== ''"
              />
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                v-else
              >
                <g
                  id="Icons/award"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="award"
                    transform="translate(5.000000, 1.000000)"
                    :fill="active === 'winners' ? secondaryColor : '#B7B7B7'"
                  >
                    <path
                      d="M7,-0.75 C11.2802068,-0.75 14.75,2.71979319 14.75,7 C14.75,9.56006993 13.5086972,11.8302181 11.5951299,13.241406 L12.7434848,21.9013578 C12.8214971,22.4893513 12.2251566,22.9165409 11.7049806,22.6899865 L11.6141282,22.6431197 L7,19.874 L2.38587182,22.6431197 C1.87721289,22.9483151 1.24824489,22.5706628 1.24959295,22.0032522 L1.25652928,21.9012514 L2.40586248,13.2421377 C0.491732,11.8310029 -0.75,9.56051247 -0.75,7 C-0.75,2.71979319 2.71979319,-0.75 7,-0.75 Z M10.1911524,14.0646015 C9.21797036,14.5048903 8.13760939,14.75 7,14.75 C5.86277887,14.75 4.78276709,14.5050576 3.80984403,14.0650522 L2.948,20.556 L6.61412818,18.3568803 C6.81770972,18.2347314 7.0647912,18.2172815 7.28080129,18.3045308 L7.38587182,18.3568803 L11.051,20.556 Z M7,0.75 C3.54822031,0.75 0.75,3.54822031 0.75,7 C0.75,10.4517797 3.54822031,13.25 7,13.25 C8.21975457,13.25 9.35789906,12.9005857 10.3197995,12.296391 C10.3555222,12.2666853 10.3948955,12.2405137 10.4367953,12.2181455 C12.1322183,11.1021833 13.25,9.18162761 13.25,7 C13.25,3.54822031 10.4517797,0.75 7,0.75 Z"
                      id="Combined-Shape"
                    ></path>
                  </g>
                </g>
              </svg>
              <span
                class="footer-link-text"
                :style="
                  active === 'winners'
                    ? { color: secondaryColor }
                    : { color: '#B7B7B7' }
                "
                >{{ $t("mainFooterTabWinners") }}</span
              >
            </div>
          </router-link>
        </li>
        <!-- profile -->
        <li
          class="footer-list-item"
          :style="
            active === 'me'
              ? { borderTop: '1px solid ' + secondaryColor }
              : { borderTop: '1px solid #fff' }
          "
        >
          <router-link
            :to="{ name: 'me', params: { id: id, userId: currentUser } }"
            :event="isUploading ? '' : 'click'"
          >
            <div style="margin:0 auto; width: 50px;padding: 2px 0;">
              <img
                class="footer-image"
                :style="active === 'me' ? {} : { filter: 'grayscale(1)' }"
                v-lazysizes
                :src="profile"
                v-if="profile && profile !== ''"
              />
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                v-else
              >
                <g
                  id="Icons/account"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path
                    d="M16,14.25 C18.5504817,14.25 20.6314697,16.2601413 20.7451121,18.782572 L20.75,19 L20.75,21 C20.75,21.4142136 20.4142136,21.75 20,21.75 C19.6203042,21.75 19.306509,21.4678461 19.2568466,21.1017706 L19.25,21 L19.25,19 C19.25,17.2669685 17.8935452,15.8507541 16.1844239,15.7551448 L16,15.75 L8,15.75 C6.26696854,15.75 4.85075407,17.1064548 4.75514479,18.8155761 L4.75,19 L4.75,21 C4.75,21.4142136 4.41421356,21.75 4,21.75 C3.62030423,21.75 3.30650904,21.4678461 3.25684662,21.1017706 L3.25,21 L3.25,19 C3.25,16.4495183 5.26014129,14.3685303 7.782572,14.2548879 L8,14.25 L16,14.25 Z M12,2.25 C14.6233526,2.25 16.75,4.37664744 16.75,7 C16.75,9.62335256 14.6233526,11.75 12,11.75 C9.37664744,11.75 7.25,9.62335256 7.25,7 C7.25,4.37664744 9.37664744,2.25 12,2.25 Z M12,3.75 C10.2050746,3.75 8.75,5.20507456 8.75,7 C8.75,8.79492544 10.2050746,10.25 12,10.25 C13.7949254,10.25 15.25,8.79492544 15.25,7 C15.25,5.20507456 13.7949254,3.75 12,3.75 Z"
                    id="Combined-Shape"
                    :fill="active === 'me' ? secondaryColor : '#B7B7B7'"
                  ></path>
                </g>
              </svg>
              <span
                class="footer-link-text"
                :style="
                  active === 'me'
                    ? { color: secondaryColor }
                    : { color: '#B7B7B7' }
                "
                >{{ $t("mainFooterTabProfile") }}</span
              >
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
export default {
  props: ["id", "active"],
  data() {
    return {
      // color: localStorage.getItem('color') ? localStorage.getItem('color'):"#df1616",
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    home() {
      return this.$store.getters.homeLogo;
    },
    fans() {
      return this.$store.getters.fansLogo;
    },
    upload() {
      return this.$store.getters.uploadLogo;
    },
    winners() {
      return this.$store.getters.winnersLogo;
    },
    profile() {
      return this.$store.getters.profileLogo;
    },
    lang() {
      return this.$store.getters.lang;
    },
    primaryColor() {
      return this.$store.getters.primaryColor;
    },
    secondaryColor() {
      return this.$store.getters.secondaryColor;
    },
    isUploading() {
      return this.$store.getters.isUploading;
    },
    currentComp() {
      return this.$store.getters.currentComp;
    }
  }
};
</script>
<style scoped>
#footer {
  height: 50px;
  min-height: 50px;
  padding: 8px;
}
.footer-list {
  width: 100%;
}
.footer-image {
  height: 30px;
  display: block;
  margin: 0 auto;
}
.footer-list-items {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
.footer-list-icon {
  display: block;
  flex: 1;
}
.footer-list-item {
  height: 50px;
  flex: 1;
  text-align: center;
}
.footer-list-item a i {
  color: #4a4a4a;
  font-size: 20px;
  text-align: center;
}
.footer-list-item a span {
  color: #4a4a4a;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 10px;
  /* margin-top: 5px; */
  display: block;
}

a:not(.md-button):hover {
  text-decoration: none !important;
}
</style>
