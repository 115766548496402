<template>
  <div class="page-container">
    <!-- <div class="logo">
      <img alt="Logo" :src="logoImage">
    </div> -->
  </div>
</template>

<script>
// import Logo from '../assets/mainImage/ico_logo_red.png'
export default {
  data () {
    return {
      // logoImage: Logo
    }
  }
}
</script>

<style scoped>
  .page-container {
    margin: 50% 0 0 0 ;
    text-align: center;
    padding: 20px;
  }
  /* .logo {
    width: 50%;
    max-width: 200px;
    margin: 0 auto;
    text-align: center;
  }
  .logo img {
    width: 100%;
  } */
</style>
