<template>
  <!-- <div v-if="!isMobile()">
    <div style="margin: 200px 0; text-align: center;font-size: 3rem;">
      <h1>SHAGA3APP</h1>
    </div>
  </div> -->
  <div id="app" :style="cssVariables">
    <div v-if="status === 0">
      <landing-page />
    </div>
    <!-- <div v-else-if="openTut">
      <tutorial-page :tutorialData="tutData" />
    </div> -->
    <div style="height: 100%" v-else>
      <Loading v-if="isLoading" />
      <!-- <loading :active.sync="isloading" background-color="#000" :can-cancel="false" :is-full-page="true" :color="color"></loading> -->
      <transition name="fade" mode="out-in" appear>
        <!-- <keep-alive> -->
        <router-view></router-view>
        <!-- </keep-alive> -->
      </transition>
    </div>
  </div>
</template>

<script>
import LandingPage from "@/views/LandingPage.vue";
import Loading from "@/components/Loading/Loading.vue";
// import TutorialPage from "@/views/Tutorial.vue";
// import HomeImg from "@/assets/mainImage/home.png";
// import HomeImgAR from "@/assets/mainImage/homeAr.png";
// import ProfileImg from "@/assets/mainImage/profile.png";
// import ProfileImgAR from "@/assets/mainImage/profileAr.png";
// import Loading from 'vue-loading-overlay'
import "vue-loading-overlay/dist/vue-loading.css";
import Vue from "vue";
export default {
  data() {
    return {
      // homeImg: HomeImg,
      // homeImgAr: HomeImgAR,
      // profileImg: ProfileImg,
      // profileImgAr: ProfileImgAR,
      // tutData: null,
      // openTut: false
    };
  },
  mounted() {
    window.screen.orientation.addEventListener(
      "change",
      this.orientationChange
    );
  },
  created() {
    // facebook login redirect
    if (this.$route.query.code) {
      this.$router.push({
        name: "login",
        query: { code: this.$route.query.code },
      });
    }
    // single video view
    if (this.$route.query.v) {
      const show = sessionStorage.getItem("show");
      const vid = sessionStorage.getItem("vid");
      let videoId = this.$route.query.v;
      if (vid === videoId) {
        if (show !== "1") {
          this.$store.dispatch("saveSingleVideo", videoId);
        }
      } else {
        this.$store.dispatch("saveSingleVideo", videoId);
      }
    }
    //single profile View
    if (this.$route.query.p && this.$route.query.cid) {
      const showP = sessionStorage.getItem("showP");
      const pro = sessionStorage.getItem("pro");
      const compid = sessionStorage.getItem("compid");
      let profile = this.$route.query.p;
      let competitionId = this.$route.query.cid;
      if (pro === profile && compid === competitionId) {
        if (showP !== "1") {
          this.$store.dispatch("saveSingleProfile", { profile, competitionId });
        }
      } else {
        this.$store.dispatch("saveSingleProfile", { profile, competitionId });
      }
    }
    //single commment view
    if (this.$route.query.c) {
      const showC = sessionStorage.getItem("showC");
      const com = sessionStorage.getItem("com");
      let comment = this.$route.query.c;
      if (com === comment) {
        if (showC !== "1") {
          this.$store.dispatch("saveSingleComment", comment);
        }
      } else {
        this.$store.dispatch("saveSingleComment", comment);
      }
    }
    this.$store.dispatch("login", { token: this.$route.query.token });
    this.$store.dispatch("screenHeighInit", window.innerHeight);
    if (this.$route.query.language) {
      if (this.$route.query.language === "ar") {
        localStorage.setItem("lang", 1);
        this.$store.dispatch("setLang", 1);
      } else {
        localStorage.setItem("lang", 0);
        this.$store.dispatch("setLang", 0);
      }
    } else {
      if (
        localStorage.getItem("lang") === "0" ||
        localStorage.getItem("lang") === "1"
      ) {
        this.$store.dispatch("setLang", localStorage.getItem("lang"));
      } else {
        localStorage.setItem("lang", 0);
        this.$store.dispatch("setLang", 0);
      }
    }
    Vue.i18n.set(this.$store.getters.lang);
  },
  methods: {
    orientationChange() {
      if (window.screen.orientation.type !== "portrait-primary") {
        let message = "";
        let lang = localStorage.getItem("lang");
        if (lang === "0") {
          message =
            "please use the app on the portrait mode for better user experience";
        } else {
          message = "يرجى استخدام التطبيق في الوضع الرأسي لتجربة مستخدم أفضل";
        }
        notie.alert({ type: "error", text: message });
      }
    },
    closeTutorial() {
      this.openTut = false;
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    Loading,
    LandingPage,
    // TutorialPage
  },
  computed: {
    color() {
      return this.$store.getters.primaryColor;
    },
    isLoading() {
      return this.$store.getters.loadingHome;
    },
    cssVariables() {
      if (this.$store.getters.lang === "ar") {
        return {
          "--lang": "rtl",
        };
      } else {
        return {
          "--lang": "ltr",
        };
      }
    },
    status() {
      return this.$store.getters.status;
    },
  },
  // watch: {
  //   $route(to, from) {
  //     let homeTut = localStorage.getItem("homeTut");
  //     // let profileTut = localStorage.getItem("profileTut");
  //     let meTut = localStorage.getItem("meTut");
  //     let lang = localStorage.getItem("lang");
  //     if (!homeTut && to.name === "home") {
  //       if (lang === "1") {
  //         this.tutData = {
  //           close: this.closeTutorial,
  //           image: this.homeImgAr,
  //           name: "home"
  //         };
  //       } else {
  //         this.tutData = {
  //           close: this.closeTutorial,
  //           image: this.homeImg,
  //           name: "home"
  //         };
  //       }
  //       this.openTut = true;
  //     }
  //     // if (!profileTut && to.name === "profile") {
  //     //   if (lang === "1") {
  //     //     this.tutData = {
  //     //       close: this.closeTutorial,
  //     //       image: this.profileImgAr,
  //     //       name: "profile"
  //     //     };
  //     //   } else {
  //     //     this.tutData = {
  //     //       close: this.closeTutorial,
  //     //       image: this.profileImg,
  //     //       name: "profile"
  //     //     };
  //     //   }
  //     //   this.openTut = true;
  //     // }
  //     if (!meTut && to.name === "me") {
  //       if (lang === "1") {
  //         this.tutData = {
  //           close: this.closeTutorial,
  //           image: this.profileImgAr,
  //           name: "me"
  //         };
  //       } else {
  //         this.tutData = {
  //           close: this.closeTutorial,
  //           image: this.profileImg,
  //           name: "me"
  //         };
  //       }
  //       this.openTut = true;
  //     }
  //   }
  // }
};
</script>

<style>
html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
#app {
  font-family: "Cairo";
  font-weight: normal;
  font-style: normal;
  width: 100%;
  direction: var(--lang);
  overflow-x: hidden;
  height: 100%;
}
.plyr {
  height: 100% !important;
}
*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.pull-down-header {
  background-color: #fff !important;
}

.pull-down-content {
  color: #101010 !important;
}
.pull-down-content--label {
  color: #101010;
  font-family: Cairo;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 29px;
}
.pull-down-content--icon {
  background: url("./assets/mainImage/arrow.png") no-repeat center center !important;
  border-radius: 15px;
  padding: 15px !important;
}
/* custom modal style */
.modal {
  z-index: 70 !important;
}
.modal-card {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: var(--height) !important;
  height: var(--height);
}
.modal-head {
  height: 50px;
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-title {
  color: #101010;
  font-family: Cairo;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37px;
}
.modal-card-close {
  color: #d83c3e;
  font-family: Cairo;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  text-align: right;
}
.modal-body {
  background: #fff;
}

.modal-card-content {
  overflow-y: scroll;
  /* -webkit-overflow-scrolling: touch; */
  /* height: 50%; */
  padding-top: 5px;
  width: 100%;
  max-width: 1024px;
}
</style>
