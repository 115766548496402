<template>
  <div
    :class="modalOpen ? 'modal is-active is-clipped' : 'modal'"
    style="text-align: start"
  >
    <div class="modal-card" :style="height">
      <header class="modal-head modal-head-static" :style="cssVariable">
        <svg
          class="modal-back-button"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          @click="closeModal"
        >
          <g
            id="Icons/arrows/nav-left"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <path
              d="M7.64439756,4.88308623 C7.86852739,4.72781896 8.15765524,4.70841055 8.39803458,4.824861 L8.49773807,4.88308623 L8.51566271,4.89673885 L8.61647352,4.98615721 L8.68798159,5.07332975 C8.84324885,5.29745958 8.86265726,5.58658743 8.73706062,5.83897487 L8.67432897,5.9445949 L8.60137924,6.03034874 L3.46,11.169 L15.0710678,11.1694174 C15.4162458,11.1694174 15.7069605,11.4026024 15.7942771,11.7200377 L15.8142212,11.8176468 L15.8210678,11.9194174 C15.8210678,12.2470493 15.6096225,12.5305547 15.2926736,12.6330793 L15.1832618,12.6603635 L15.0710563,12.6694174 L3.572,12.668 L8.61647352,17.7140793 L8.68798159,17.8012518 C8.89311488,18.0973637 8.85880981,18.5008402 8.6013979,18.7582521 C8.36972718,18.9899229 8.01974415,19.040877 7.72313158,18.8892562 L7.62647291,18.8311832 L7.54073773,18.7582521 L0.646446609,11.863961 L7.55722503,4.9545943 L7.64439756,4.88308623 Z M19.0710678,11.1694174 C19.4852814,11.1694174 19.8210678,11.5052038 19.8210678,11.9194174 C19.8210678,12.2470493 19.6096225,12.5305547 19.2926736,12.6330793 L19.1832618,12.6603635 L19.0710678,12.6694174 L17.5710678,12.6694174 C17.1568542,12.6694174 16.8210678,12.3336309 16.8210678,11.9194174 C16.8210678,11.5917855 17.0325131,11.3082801 17.349462,11.2057554 L17.4588738,11.1784713 L17.5710678,11.1694174 L19.0710678,11.1694174 Z"
              id="Combined-Shape"
              fill="#111111"
            ></path>
          </g>
        </svg>
        <div class="modal-title">
          <span>
            {{ $t("editProfileTitle") }}
          </span>
        </div>
      </header>
      <section
        class="modal-body"
        v-if="user"
        style="padding: 0;overflow: scroll;padding-bottom: 500px"
      >
        <div>
          <div class="profile-image-container">
            <div class="profile-image">
              <figure class="image" style="width: 100%;height: 100%;margin:0;">
                <img
                  class="is-rounded"
                  v-lazysizes
                  :src="
                    src
                      ? src
                      : user.avatar !== '' && user.avatar
                      ? user.avatar
                      : defaultImage
                  "
                  style="object-fit: cover;height: 100%;background-color:#707C80"
                />
              </figure>
            </div>
            <div style="flex:1">
              <div>
                <label class="file-label" style="width: auto;height:auto">
                  <input
                    class="file-input"
                    id="file"
                    type="file"
                    ref="image"
                    @change="handleFileUpload"
                    accept="image/*"
                  />
                  <span class=" upload-image-button">
                    <span class="upload-button-icon">
                      <svg
                        width="16px"
                        height="16px"
                        viewBox="0 0 24 24"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          id="Icons/delete"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            id="trash-2"
                            transform="translate(3.000000, 2.000000)"
                            fill="#111111"
                          >
                            <path
                              d="M11,-0.75 C12.4625318,-0.75 13.6584043,0.391704595 13.7449812,1.83247767 L13.75,2 L13.75,3.25 L18,3.25 C18.4142136,3.25 18.75,3.58578644 18.75,4 C18.75,4.37969577 18.4678461,4.69349096 18.1017706,4.74315338 L18,4.75 L16.75,4.75 L16.75,18 C16.75,19.4625318 15.6082954,20.6584043 14.1675223,20.7449812 L14,20.75 L4,20.75 C2.53746816,20.75 1.34159572,19.6082954 1.25501879,18.1675223 L1.25,18 L1.25,4.75 L0,4.75 C-0.414213562,4.75 -0.75,4.41421356 -0.75,4 C-0.75,3.62030423 -0.467846118,3.30650904 -0.101770557,3.25684662 L0,3.25 L4.25,3.25 L4.25,2 C4.25,0.537468162 5.3917046,-0.658404279 6.83247767,-0.744981206 L7,-0.75 L11,-0.75 Z M15.25,4.75 L2.75,4.75 L2.75,18 C2.75,18.6472087 3.24187466,19.1795339 3.87219476,19.2435464 L4,19.25 L14,19.25 C14.6472087,19.25 15.1795339,18.7581253 15.2435464,18.1278052 L15.25,18 L15.25,4.75 Z M7,8.25 C7.37969577,8.25 7.69349096,8.53215388 7.74315338,8.89822944 L7.75,9 L7.75,15 C7.75,15.4142136 7.41421356,15.75 7,15.75 C6.62030423,15.75 6.30650904,15.4678461 6.25684662,15.1017706 L6.25,15 L6.25,9 C6.25,8.58578644 6.58578644,8.25 7,8.25 Z M11,8.25 C11.3796958,8.25 11.693491,8.53215388 11.7431534,8.89822944 L11.75,9 L11.75,15 C11.75,15.4142136 11.4142136,15.75 11,15.75 C10.6203042,15.75 10.306509,15.4678461 10.2568466,15.1017706 L10.25,15 L10.25,9 C10.25,8.58578644 10.5857864,8.25 11,8.25 Z M11,0.75 L7,0.75 C6.35279131,0.75 5.8204661,1.24187466 5.75645361,1.87219476 L5.75,2 L5.75,3.25 L12.25,3.25 L12.25,2 C12.25,1.35279131 11.7581253,0.820466101 11.1278052,0.756453609 L11,0.75 Z"
                              id="Combined-Shape"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <span class="upload-button-text">
                      {{ $t("editProfileUploadButton") }}
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <form class="edit-profile-container" autocomplete="off">
          <md-field class="edit-profile-field">
            <label
              :style="lang === 'ar' ? { right: '10px' } : { left: '10px' }"
              >{{ $t("editProfileName") }}</label
            >
            <md-input
              name="name"
              class="input "
              type="text"
              :placeholder="$t('editProfileName')"
              v-model="user.name"
            />
          </md-field>
          <div class="edit-profile-error">
            <p v-if="nameInvalid" class="help is-danger">
              {{ $t("nameRequiredError") }}
            </p>
          </div>
          <md-field class="edit-profile-field">
            <label
              :style="lang === 'ar' ? { right: '10px' } : { left: '10px' }"
              >{{ $t("editProfileEmail") }}</label
            >
            <md-input
              :style="cssVariable"
              class="input"
              type="email"
              :placeholder="$t('editProfileEmail')"
              v-model="user.email"
              :disabled="
                userProps.email === '' || !userProps.email ? false : true
              "
            />
          </md-field>
          <div class="edit-profile-error">
            <p v-if="emailInvalid" class="help is-danger">
              {{ $t("NotemailError") }}
            </p>
          </div>
          <md-field class="edit-profile-field">
            <label
              :style="lang === 'ar' ? { right: '10px' } : { left: '10px' }"
              >{{ $t("editProfileMobile") }}</label
            >
            <md-input
              :style="cssVariable"
              class="input"
              type="tel"
              :disabled="user.member ? false : true"
              :placeholder="$t('editProfileMobile')"
              v-model="user.phone"
            />
          </md-field>
          <md-field class="edit-profile-field" v-if="!user.member">
            <label
              :style="lang === 'ar' ? { right: '10px' } : { left: '10px' }"
              >{{
                currentComp === "degla"
                  ? $t("editProfileMembershipDegla")
                  : $t("editProfileMembership")
              }}</label
            >
            <md-input
              :style="cssVariable"
              class="input"
              type="text"
              :placeholder="
                currentComp === 'degla'
                  ? $t('editProfileMembershipDegla')
                  : $t('editProfileMembership')
              "
              v-model="user.membership_id"
            />
          </md-field>
          <div v-if="!user.member" class="edit-profile-error">
            <p v-if="memberInvalid" class="help is-danger">
              {{ $t("memberInvalid") }}
            </p>
          </div>
        </form>
        <div class="edit-profile-save-container">
          <button
            class="btn edit-profile-save"
            :style="cssVariable"
            @click="submit"
          >
            {{ $t("editProfileUpdateButton") }}
          </button>
        </div>
      </section>
      <!-- <footer class="modal-card-foot" :style="cssVariable">
        <button class="btn save-button" :style="cssVariable" @click="submit">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9522 2.97515L8.28747 9.63992L6.23974 7.30242C5.81474 6.81946 5.08065 6.78083 4.5977 7.20583C4.11474 7.63083 4.07611 8.36492 4.50111 8.84787L7.37952 12.1126C7.59202 12.3445 7.90111 12.499 8.2102 12.499C8.22952 12.499 8.22952 12.499 8.24883 12.499C8.55792 12.499 8.8477 12.3831 9.0602 12.1513L16.5943 4.61719C17.0386 4.17287 17.0386 3.43878 16.5943 2.97515C16.15 2.53083 15.4159 2.53083 14.9522 2.97515Z" 
            :fill="bkcolor"/>
            <path d="M15.8409 7.34091C15.2034 7.34091 14.6818 7.8625 14.6818 8.5C14.6818 8.77045 14.6625 9.04091 14.6239 9.31136C14.2375 12.3636 11.5909 14.6818 8.5 14.6818C5.1 14.6818 2.31818 11.9 2.31818 8.5C2.31818 5.1 5.1 2.31818 8.5 2.31818C9.60114 2.31818 10.683 2.60795 11.6102 3.16818C12.1705 3.49659 12.8659 3.30341 13.1943 2.7625C13.5227 2.20227 13.3295 1.50682 12.7886 1.17841C11.4943 0.405682 10.0068 0 8.5 0C3.80568 0 0 3.80568 0 8.5C0 13.1943 3.80568 17 8.5 17C12.75 17 16.3818 13.8318 16.9227 9.60114C16.9614 9.23409 17 8.86705 17 8.5C17 7.8625 16.4784 7.34091 15.8409 7.34091Z" 
            :fill="bkcolor"/>
          </svg>
          {{$t('editProfileUpdateButton')}}
        </button>
        <button class="btn cancel-button" :style="cssVariable" @click="closeModal">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.14083 6.49829L12.6578 1.98131C13.1095 1.52961 13.1095 0.790471 12.6578 0.338773C12.2061 -0.112924 11.467 -0.112924 11.0153 0.338773L6.49829 4.85575L1.98131 0.338773C1.52961 -0.112924 0.790471 -0.112924 0.338773 0.338773C-0.112924 0.790471 -0.112924 1.52961 0.338773 1.98131L4.85575 6.49829L0.338773 11.0153C-0.112924 11.467 -0.112924 12.2061 0.338773 12.6578C0.571466 12.8905 0.85891 13 1.16004 13C1.46117 13 1.74862 12.8905 1.98131 12.6578L6.49829 8.14083L11.0153 12.6578C11.248 12.8905 11.5354 13 11.8365 13C12.1377 13 12.4251 12.8905 12.6578 12.6578C13.1095 12.2061 13.1095 11.467 12.6578 11.0153L8.14083 6.49829Z" 
            :fill="bkcolor"/>
          </svg>

          {{$t('editProfileCancelButton')}}
        </button>
      </footer> -->
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.user = { ...this.userProps };
  },
  // updated() {
  //   if(this.user !== this.userProps){
  //     this.user = {...this.userProps}
  //   }
  // },
  props: ["modalOpen", "closeModal", "userProps"],
  data() {
    return {
      file: null,
      src: null,
      memberInvalid: false,
      emailInvalid: false,
      nameInvalid: false,
      user: null
    };
  },
  methods: {
    deglaMemberValidation() {
      if (typeof this.user.membership_id === "undefined") {
        return true;
      } else if (
        this.user.membership_id.substring(0, 5) === "00400" ||
        this.user.membership_id.substring(0, 5) === "00100" ||
        this.user.membership_id.length === 0
      ) {
        if (
          this.user.membership_id.length > 5 ||
          this.user.membership_id.length === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    validationEmail(email) {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (email.match(mailformat)) {
        return true;
      } else {
        return false;
      }
    },
    submit() {
      let data = null;
      let name = null;
      let email = null;
      let phone = null;
      let membership_id = null;
      // name not null or empty
      if (typeof this.user.name === "undefined") {
        this.nameInvalid = true;
        return false;
      } else if (this.user.name.length === 0) {
        this.nameInvalid = true;
        return false;
      } else {
        this.nameInvalid = false;
        if (this.user.name !== this.userProps.name) {
          name = this.user.name;
        }
      }
      // email not null
      if (typeof this.user.email === "undefined") {
        this.emailInvalid = true;
        return false;
      } else if (this.user.email !== this.userProps.email) {
        if (!this.validationEmail(this.user.email)) {
          this.emailInvalid = true;
          return false;
        } else {
          this.emailInvalid = false;
          email = this.user.email;
        }
      }
      if (this.user.phone !== this.userProps.phone) {
        if (typeof this.user.phone !== "undefined") {
          if (this.user.phone.length !== 0) {
            if (this.user.member) {
              phone = this.user.phone;
            }
          }
        }
      }
      // membership if exist must be valid
      if (!this.user.member) {
        if (!this.deglaMemberValidation()) {
          this.memberInvalid = true;
          return false;
        } else {
          this.memberInvalid = false;
          membership_id = this.user.membership_id;
        }
      }
      data = {
        name,
        email,
        membership_id,
        phone,
        file: this.file
      };
      this.$store.dispatch("updateUserProfile", data);
    },
    handleFileUpload() {
      if (this.$refs.image.files[0].type.includes("image")) {
        this.file = this.$refs.image.files[0];
        this.src = URL.createObjectURL(this.file);
      } else {
        notie.alert({ type: "error", text: this.$t("notValidImage") });
      }
    }
  },
  computed: {
    defaultImage() {
      return this.$store.getters.brandLogo;
    },
    lang() {
      return this.$store.getters.lang;
    },
    cssVariable() {
      return {
        "--color": this.$store.getters.primaryColor,
        "--bkcolor": this.$store.getters.secondaryColor
      };
    },
    height() {
      return {
        "--height": this.$store.getters.screenHeight + "px"
      };
    },
    color() {
      return this.$store.getters.primaryColor;
    },
    bkcolor() {
      return this.$store.getters.secondaryColor;
    },
    currentComp() {
      return this.$store.getters.currentComp;
    }
  }
};
</script>

<style scoped>
.modal-card {
  background-color: #fff;
}
.modal-head-static {
  direction: ltr;
  position: relative;
}
.modal-title {
  text-align: center !important;
  flex: 1;
}
.modal-back-button {
  position: absolute;
  top: calc(50% - 12px);
  left: 0;
}
.profile-image-container {
  margin: 25px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-image {
  width: 90px;
  height: 90px;
}
.upload-image-button {
  height: 43px;
  width: auto;
  margin: 0 10px;
  padding: 10px;
  background-color: #f6f6f6;
  border-radius: 6px;
}
.upload-button-icon {
  margin: 0 4px;
  vertical-align: top;
}
.upload-button-text {
  margin: auto 4px;
  height: 100%;
  color: #101010;
  font-family: Cairo;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
}
.edit-profile-container {
  margin: 30px 20px;
}
.edit-profile-field {
  border-radius: 6px;
  background-color: #f6f6f6;
  padding: 0 10px;
  padding-top: 20px !important;
  margin-bottom: 10px !important;
  margin-top: 20px !important;
}
.edit-profile-field label {
  color: #707c80;
  font-family: Cairo !important;
  font-size: 12px !important;
  letter-spacing: 0 !important;
  line-height: 23px !important;
}
.edit-profile-field .input {
  color: #101010;
  font-family: Cairo !important;
  font-size: 15px !important;
  letter-spacing: 0.21px !important;
  line-height: 29px !important;
  box-shadow: none !important;
  margin-bottom: 10px !important;
}
.edit-profile-error {
  margin: 10px;
  margin-top: -10px;
  font-family: Cairo;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 23px;
}
.edit-profile-save-container {
  text-align: center;
}
.edit-profile-save {
  width: 50%;
  border-radius: 6px;
  background-color: var(--bkcolor);
  color: #ffffff;
  font-family: Cairo;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}
</style>
