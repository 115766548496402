<template>
  <div style="height: 100%; width: 100%">
    <div class="main" :style="cssVariable" v-if="status === 0">
      <div class="company-logo">
        <img alt="Logo" v-lazysizes :src="logo" />
      </div>
      <div style="width: 100%; margin: 10px auto">
        <h4 style="font-size: 24px; font-weight: 700;text-align: center">
          {{ $t("loginTitle") }}
        </h4>
      </div>
      <div class="login-form">
        <div style="text-align: center; margin: 10px 0">
          <h6>{{ $t("enterMobileSubtitle") }}</h6>
        </div>
        <div style="text-align: center; margin: 10px 0; font-size: 1rem">
          <span>{{ $t("enterMobileSubtitle") }}</span>
        </div>
        <div style="display: block; margin-top: 30px">
          <div>
            <label
              class="label"
              :style="lang === 'ar' ? { right: '0' } : { left: '0' }"
              >{{ $t("loginMobileNumber") }}</label
            >
            <vue-tel-input
              :onlyCountries="['eg']"
              :valid-characters-only="true"
              placeholder="0122345677"
              @input="onInput"
            ></vue-tel-input>
          </div>
          <p
            v-if="!$v.form.mobile.required && mobileTouch"
            class="help is-danger"
          >
            {{ $t("mobileRequiredError") }}
          </p>
          <p v-if="!mobile.valid && mobileTouch" class="help is-danger">
            {{ $t("mobileInvalidError") }}
          </p>
          <p v-if="error" class="error">{{ $t("mobileConcurentError") }}</p>
        </div>
        <div style="width: 100%; text-align: center">
          <div
            class="button-submit"
            :style="cssVariable"
            @click="sendPhoneHandler"
          >
            <span>{{ $t("loginButton") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="verification-main" v-else-if="status === 1">
      <div class="company-logo">
        <img alt="Logo" v-lazysizes :src="logo" />
      </div>
      <div>
        <h4 style="font-size: 24px; font-weight: 700">
          {{ $t("verifySmsTitle") }}
        </h4>
      </div>
      <div class="login-form">
        <div style="text-align: center; margin: 10px 0">
          <h6>{{ $t("enterSmsCodeSubtitle") }}</h6>
          <span>{{ form.email }}</span>
        </div>
        <md-field class="custom-login-input" style="display: block">
          <label
            class="label"
            :style="lang === 'ar' ? { right: '0' } : { left: '0' }"
            >{{ $t("codeLabel") }}</label
          >
          <div style="width: 100%">
            <md-input
              type="number"
              :placeholder="$t('codeLabel')"
              @input="codeTouch = true"
              v-model="form.code"
              style="width: 100%"
            />
          </div>
          <p v-if="!$v.form.code.required && codeTouch" class="help is-danger">
            {{ $t("codeRequiredError") }}
          </p>
          <p v-if="!$v.form.code.numeric && codeTouch" class="help is-danger">
            {{ $t("codeNumericError") }}
          </p>
          <p v-if="error" class="help is-danger">{{ $t("codeError") }}</p>
        </md-field>
        <div style="width: 100%; text-align: center">
          <div
            class="button-submit"
            :style="cssVariable"
            @click="sendCodeHandler"
          >
            <span>{{ $t("codeButton") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="info-main" :style="cssVariable" v-else-if="status === 2">
      <div class="company-logo">
        <img alt="Logo" v-lazysizes :src="logo" />
      </div>
      <div>
        <h4 style="font-size: 24px; font-weight: 700">
          {{ $t("infoTitle") }}
        </h4>
      </div>
      <div class="login-form">
        <md-field class="custom-login-input">
          <label
            class="label"
            :style="lang === 'ar' ? { right: '0' } : { left: '0' }"
            >{{ $t("loginInfoName") }}</label
          >
          <div style="width: 100%">
            <md-input
              class="input"
              type="text"
              :placeholder="$t('loginInfoName')"
              @input="nameTouch = true"
              v-model="form.name"
              style="width: 100%;box-shadow: none;"
            />
          </div>
        </md-field>
        <div>
          <p v-if="!$v.form.name.required && nameTouch" class="help is-danger">
            {{ $t("nameRequiredError") }}
          </p>
          <p
            v-else-if="!$v.form.name.maxLength && nameTouch"
            class="help is-danger"
          >
            {{ $t("nameMaxLengthError") }}
          </p>
        </div>
        <md-field class="custom-login-input">
          <label
            class="label"
            :style="lang === 'ar' ? { right: '0' } : { left: '0' }"
            >{{ $t("loginInfoEmail") }}</label
          >
          <md-input
            class="input"
            type="email"
            :placeholder="$t('loginInfoEmail')"
            @input="emailTouch = true"
            v-model="form.email"
            style="width: 100%;box-shadow: none;"
          />
        </md-field>
        <div>
          <p
            v-if="!$v.form.email.required && emailTouch"
            class="help is-danger"
          >
            {{ $t("emailRequiredError") }}
          </p>
          <p
            v-else-if="!$v.form.email.email && emailTouch"
            class="help is-danger"
          >
            {{ $t("NotemailError") }}
          </p>
        </div>
        <md-field class="custom-login-input" v-if="isDegla">
          <label
            class="label"
            :style="lang === 'ar' ? { right: '0' } : { left: '0' }"
            >{{
              !isDegla ? $t("loginInfoMember") : $t("loginInfoMemberDegla")
            }}</label
          >
          <md-input
            class="input"
            type="number"
            :placeholder="
              !isDegla ? $t('loginInfoMember') : $t('loginInfoMemberDegla')
            "
            v-model="form.member"
            style="width: 100%;box-shadow: none;"
          />
        </md-field>
        <div>
          <p v-if="memberInvalid" class="help is-danger">
            {{ $t("memberInvalid") }}
          </p>
        </div>
        <div style="width: 100%; text-align: center">
          <div
            class="button-submit"
            :style="cssVariable"
            @click="sendInfoHandler"
          >
            <span>{{ $t("submitLoginData") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import { validationMixin } from "vuelidate";
import { required, email, numeric, maxLength } from "vuelidate/lib/validators";
export default {
  name: "sms-login",
  mixins: [validationMixin],
  mounted() {
    this.$store.dispatch("resetError");
  },
  data() {
    return {
      codeTouch: false,
      emailTouch: false,
      mobileTouch: false,
      nameTouch: false,
      memberInvalid: false,
      mobile: {
        valid: false,
        country: undefined
      },
      form: {
        name: "",
        email: "",
        mobile: "",
        member: "",
        code: ""
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      mobile: {
        required
      },
      name: {
        required,
        maxLength: maxLength(25)
      },
      code: {
        required,
        numeric
      }
    }
  },
  computed: {
    isDegla() {
      if (localStorage.getItem("sl") === "degla") {
        return true;
      } else {
        return false;
      }
    },
    isGuest() {
      if (this.$store.getters.isGuest) {
        return this.$store.getters.isGuest;
      } else {
        this.$router.push("/");
      }
    },
    status() {
      return this.$store.getters.loginStatus;
    },
    error() {
      return this.$store.getters.loginError;
    },
    cssVariable() {
      return {
        "--color": this.$store.getters.primaryColor,
        "--color2": this.$store.getters.secondaryColor
      };
    },
    lang() {
      return this.$store.getters.lang;
    },
    logo() {
      return this.$store.getters.brandLogo;
    }
  },
  methods: {
    onInput(formattedNumber, { number, valid, country }) {
      this.mobileTouch = true;
      this.form.mobile = number.e164;
      this.mobile.valid = valid;
      this.mobile.country = country.dialCode;
    },
    sendPhoneHandler() {
      if (this.mobile.valid && this.mobile.country === "20") {
        this.$store.dispatch("sendPhone", this.form.mobile);
      }
      this.mobileTouch = true;
    },
    sendCodeHandler() {
      this.codeTouch = true;
      if (this.$v.form.code.required && this.$v.form.code.numeric) {
        this.$store.dispatch("sendSmsCode", this.form.code);
      }
    },
    sendInfoHandler() {
      this.emailTouch = true;
      this.nameTouch = true;
      if (this.$v.form.email.required && this.$v.form.email.email) {
        if (this.$v.form.name.maxLength && this.$v.form.name.required) {
          if (
            this.form.member.substring(0, 5) === "00400" ||
            this.form.member.substring(0, 5) === "00100" ||
            this.form.member.length === 0
          ) {
            if (this.form.member.length > 5 || this.form.member.length === 0) {
              this.$store.dispatch("sendInfo", {
                email: this.form.email,
                name: this.form.name,
                membership_id: this.form.member
              });
              this.memberInvalid = false;
            } else {
              this.memberInvalid = true;
            }
          } else {
            this.memberInvalid = true;
          }
        }
      }
    },
    backHome() {
      let competetionId = localStorage.getItem("competitionId");
      if (competetionId) {
        this.$router.push({ name: "home", params: { id: competetionId } });
      } else {
        this.$router.push({ name: "competitions" });
      }
    }
  },
  components: {
    VueTelInput
  }
};
</script>

<style scoped>
/* common css classes */
main {
  background: #fff;
  height: 100%;
  color: #101010;
  text-align: center;
  font-size: 1.5rem;
  padding-top: 35px;
  overflow-y: scroll;
  /* -webkit-overflow-scrolling: touch; */
}
.close-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 30px;
  padding: 20px;
}
.company-logo {
  width: 50%;
  margin: 20px auto;
  margin-bottom: 30px;
}
.company-logo img {
  height: 100px;
  width: 100%;
  object-fit: contain;
}
.login-form {
  padding: 10px;
  font-size: 12px;
  text-align: start;
}
.button-submit {
  color: var(--color2);
  background-color: var(--color);
  width: 80%;
  font-weight: 700;
  margin: 0 auto;
  margin-top: 20px;
  font-size: 14px;
  padding: 10px;
}
.label {
  padding-left: 10px;
}
.custom-login-input {
  border-radius: 6px;
  background-color: #f6f6f6;
  padding: 20px 10px 10px;
  margin-bottom: 10px !important;
  margin-top: 20px !important;
}

/* code verification screen */
.verification-main {
  background: #fff;
  height: 100%;
  color: #101010;
  text-align: center;
  font-size: 1.5rem;
  overflow-y: scroll;
  /* -webkit-overflow-scrolling: touch; */
}

/* information screen */
.info-main {
  background: #fff;
  height: 100%;
  color: #101010;
  text-align: center;
  font-size: 1.5rem;
  padding-top: 35px;
  overflow-y: scroll;
  /* -webkit-overflow-scrolling: touch; */
}
</style>
