<template>
  <div style="margin-bottom: 20px">
    <div
      class="card promoting-card"
      style="
        margin: 10px 0;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid #b7b7b7;
      "
      :key="index"
      v-for="(winner, index) in winners"
    >
      <!-- Card content -->
      <div class="card-body" style="padding: 0">
        <!-- Content -->
        <!-- Title -->
        <div class="winner-data-container">
          <div class="winner-user-name" v-if="winner.user_id">
            <figure class="image user-image">
              <img
                v-if="winner.user_id.avatar !== '' && winner.user_id.avatar"
                class="is-rounded"
                v-lazysizes
                :src="winner.user_id.avatar"
                :style="{
                  border: '2px solid ' + color,
                  objectFit: 'cover',
                  height: '100%',
                  padding: '2px',
                }"
                @error="userImageErrorHandler"
              />
              <img
                v-else
                class="is-rounded"
                v-lazysizes
                :src="defaultImage"
                :style="{
                  border: '2px solid ' + color,
                  objectFit: 'cover',
                  height: '100%',
                }"
              />
            </figure>
            <router-link
              :to="{
                name: 'profile',
                params: { userId: winner.user_id._id, id: competitionId },
              }"
            >
              <span class="card-title">{{ winner.user_id.name }}</span>
            </router-link>
          </div>
          <div class="winner-user-name" v-else>
            <span class="card-title">{{ $t("winnerName") }}</span>
          </div>
          <!-- try -->
          <!-- <div v-if="logos"> -->
          <div
            class="winner-use-gift"
            v-if="logos && logos.length > 0 && logos[index]"
          >
            <figure class="image user-gift-image">
              <img
                v-lazysizes
                :src="logos[index].url"
                @error="prizeImageErrorHandler"
                style="width: 100%"
              />
            </figure>
            <!-- <span>#{{ round}}</span> -->
          </div>
          <!-- <div class="winner-use-gift" v-else>
            <p>#{{round}}</p>
          </div> -->
          <!-- </div> -->
        </div>
        <!-- video -->
        <div
          style="height: 200px; width: 100%; position: relative"
          v-if="winner.video_id"
        >
          <div
            :class="lang === 'ar' ? 'ribbon-right' : 'ribbon-left'"
            :style="cssVariable"
          >
            <span class="ribbon-text" v-if="lang === 'ar'"
              >{{ $t("RankTitle") }} {{ arabicize(winner.rank) }}</span
            >
            <span class="ribbon-text" v-else>{{
              $t("RankTitle") + " " + winner.rank
            }}</span>
          </div>
          <WinnersVideos
            :index="winner.video_id._id"
            :id="winner.video_id.uri"
          />
          <!-- <div
            :id="round + index"
            data-plyr-provider="vimeo"
            :data-plyr-embed-id="winner.video_id.uri"
          ></div> -->
        </div>
        <div class="winner-user-info">
          <div class="winner-user-caption">
            <p>{{ winner.video_id.caption }}</p>
          </div>
          <div class="winner-user-likes" :style="cssVariable">
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              style="height: 20px; width: 20px"
            >
              <g
                id="Icons/favorites-filled"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <path
                  d="M17.25,2 C15.4228563,2.00212494 13.6778916,2.7595689 12.4285714,4.09285714 C10.575025,2.11462434 7.70116276,1.46934993 5.17982124,2.46527982 C2.65847973,3.46120972 1.00133468,5.89623201 1,8.60714286 C1,15.1857143 11.6107143,21.6428571 12.0714286,21.8964286 C12.2978089,22.0324379 12.5807625,22.0324379 12.8071429,21.8964286 C13.2464286,21.6428571 23.8571429,15.1857143 23.8571429,8.60714286 C23.8532061,4.95975052 20.8973923,2.00393675 17.25,2 Z"
                  id="Shape"
                  :fill="color"
                ></path>
              </g>
            </svg>
            <span style="margin: 0 2px">{{ winner.likes }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrizeImage from "@/assets/mainImage/prize.png";
export default {
  name: "Card",
  data() {
    return {
      player: null,
      prizeDefault: PrizeImage,
    };
  },
  props: ["winners", "round", "logos"],
  computed: {
    defaultImage() {
      return this.$store.getters.brandLogo;
    },
    color() {
      return this.$store.getters.primaryColor;
    },
    lang() {
      return this.$store.getters.lang;
    },
    cssVariable() {
      return {
        "--color": this.$store.getters.primaryColor,
        "--bkcolor": this.$store.getters.secondaryColor,
      };
    },
    competitionId() {
      return this.$store.getters.competitionId;
    },
  },
  components: {
    WinnersVideos: () =>
      import(
        /* webpackPrefetch: true */ "@/components/VideoCard/VideoComponent.vue"
      ),
  },
  methods: {
    userImageErrorHandler(e) {
      e.target.src = this.defaultImage;
    },
    prizeImageErrorHandler(e) {
      e.target.src = this.prizeDefault;
    },
    arabicize(value) {
      const arabicNums = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
      let newValue = value.toString().replace(/[0-9]/g, function (w) {
        return arabicNums[+w];
      });
      return newValue;
    },
  },
  // mounted() {
  //   const controls = `<div class="plyr__controls" style="display: flex;justify-content: space-evenly;width: 100%;height: 100%;align-items: flex-end;flex-wrap: wrap;">
  //       <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play" style="width: 100%;height: 50px;background-color: transparent;padding: 0">
  //           <svg class="icon--pressed" role="presentation" width="35px" height="35px" viewBox="0 0 35 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: 0 auto;width:100%;height:100%">
  //             <g id="SHAGA3APP" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  //                 <g id="pause-icon" fill="#C4C4C4" fill-rule="nonzero">
  //                     <path d="M34.0529,8.62266 C34.7788,11.6788 35.0329,14.7713 34.9966,17.5 C35.0329,20.2287 34.7788,23.3212 34.0529,26.3773 C34.0529,26.3773 32.9276,31.762 28.0634,33.6538 C25.7765,34.5634 21.6021,34.9636 17.5002,35 C13.3984,34.9636 9.2239,34.527 6.93702,33.6538 C2.07287,31.762 0.94758,26.3773 0.94758,26.3773 C0.185288,23.3576 -0.0325102,20.2287 0.00378941,17.5 C-0.0325102,14.7713 0.221587,11.6788 0.94758,8.62266 C0.94758,8.62266 2.07287,3.23805 6.93702,1.34615 C9.2239,0.472973 13.3984,0.0363825 17.5002,0 C21.6021,0.0363825 25.7765,0.472973 28.0634,1.34615 C32.9276,3.23805 34.0529,8.62266 34.0529,8.62266 Z M14,10 C12.8954305,10 12,10.8954305 12,12 L12,24 C12,25.1045695 12.8954305,26 14,26 C15.1045695,26 16,25.1045695 16,24 L16,12 C16,10.8954305 15.1045695,10 14,10 Z M21,10 C19.8954305,10 19,10.8954305 19,12 L19,24 C19,25.1045695 19.8954305,26 21,26 C22.1045695,26 23,25.1045695 23,24 L23,12 C23,10.8954305 22.1045695,10 21,10 Z" id="Combined-Shape"></path>
  //                 </g>
  //             </g>
  //           </svg>
  //           <svg class="icon--not-pressed" role="presentation" width="49px" height="50px" viewBox="0 0 49 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: 0 auto;width:100%;height:100%">
  //             <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  //               <g id="elements/video" transform="translate(-135.000000, -75.000000)">
  //                 <g id="controls/player-cion" transform="translate(135.000000, 75.000000)">
  //                   <path d="M35.4740153,2.30682432 C34.1451563,1.68434109 32.7340824,1.18601895 31.2515321,0.828971967 C29.6112636,0.434005524 27.8877737,0.191723645 26.1327402,0.0746095511 C15.2018677,-0.657269644 2.94683411,3.9910525 0.487102568,14.2095089 C-0.978669244,20.2987706 0.977371024,28.6165559 4.82837774,35.6591733 C6.11428378,38.0108512 7.60790794,40.2212538 9.26126364,42.1568243 C12.298512,45.7138713 15.8599214,48.3494418 19.596163,49.2487706 C31.4548878,52.1034686 44.700861,39.3296431 47.5555589,27.4705827 C50.05321,17.0937371 44.7766999,6.66420687 35.4740153,2.30682432" id="Fill-1" fill="${this.color}"></path>
  //                   <path d="M22.1521361,11.061321 L38.5044851,22.0489049 C40.4759616,23.3737371 40.4930757,26.261321 38.5366999,27.5676968 L22.2185791,38.4640055 C20.0155589,39.9351465 17.037371,38.3287706 17.0330086,35.6663545 L16.9987756,13.7824619 C16.994418,11.1327975 19.942069,9.57642163 22.1521361,11.061321" id="Fill-4" fill="#FFFFFF"></path>
  //                 </g>
  //               </g>
  //             </g>
  //           </svg>
  //       </button>
  //       <div style="width: 80%;">
  //       </div>
  //       <button type="button" class="plyr__control" data-plyr="fullscreen" style="background-color: rgba(17,17,17,0.8);padding:5px;width: 34px;height:34px;">
  //           <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="icon--pressed" role="presentation" style="box-shadow:none;width: 100%">
  //               <g id="Icons/expand" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  //                   <g id="maximize" transform="translate(3.000000, 3.000000)" fill="#fff">
  //                       <path d="M18,12.25 C18.3796958,12.25 18.693491,12.5321539 18.7431534,12.8982294 L18.75,13 L18.75,16 C18.75,17.4625318 17.6082954,18.6584043 16.1675223,18.7449812 L16,18.75 L13,18.75 C12.5857864,18.75 12.25,18.4142136 12.25,18 C12.25,17.6203042 12.5321539,17.306509 12.8982294,17.2568466 L13,17.25 L16,17.25 C16.6472087,17.25 17.1795339,16.7581253 17.2435464,16.1278052 L17.25,16 L17.25,13 C17.25,12.5857864 17.5857864,12.25 18,12.25 Z M0,12.25 C0.379695766,12.25 0.693490961,12.5321539 0.743153384,12.8982294 L0.75,13 L0.75,16 C0.75,16.6472087 1.24187466,17.1795339 1.87219476,17.2435464 L2,17.25 L5,17.25 C5.41421356,17.25 5.75,17.5857864 5.75,18 C5.75,18.3796958 5.46784612,18.693491 5.10177056,18.7431534 L5,18.75 L2,18.75 C0.537468162,18.75 -0.658404279,17.6082954 -0.744981206,16.1675223 L-0.75,16 L-0.75,13 C-0.75,12.5857864 -0.414213562,12.25 0,12.25 Z M5,-0.75 C5.41421356,-0.75 5.75,-0.414213562 5.75,0 C5.75,0.379695766 5.46784612,0.693490961 5.10177056,0.743153384 L5,0.75 L2,0.75 C1.35279131,0.75 0.820466101,1.24187466 0.756453609,1.87219476 L0.75,2 L0.75,5 C0.75,5.41421356 0.414213562,5.75 0,5.75 C-0.379695766,5.75 -0.693490961,5.46784612 -0.743153384,5.10177056 L-0.75,5 L-0.75,2 C-0.75,0.537468162 0.391704595,-0.658404279 1.83247767,-0.744981206 L2,-0.75 L5,-0.75 Z M16,-0.75 C17.4625318,-0.75 18.6584043,0.391704595 18.7449812,1.83247767 L18.75,2 L18.75,5 C18.75,5.41421356 18.4142136,5.75 18,5.75 C17.6203042,5.75 17.306509,5.46784612 17.2568466,5.10177056 L17.25,5 L17.25,2 C17.25,1.35279131 16.7581253,0.820466101 16.1278052,0.756453609 L16,0.75 L13,0.75 C12.5857864,0.75 12.25,0.414213562 12.25,0 C12.25,-0.379695766 12.5321539,-0.693490961 12.8982294,-0.743153384 L13,-0.75 L16,-0.75 Z" id="Shape"></path>
  //                   </g>
  //               </g>
  //             </svg>
  //           <svg class="icon--not-pressed" role="presentation" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" style="box-shadow:none; width: 100%">
  //             <g id="Icons/expand" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  //               <g id="maximize" transform="translate(3.000000, 3.000000)" fill="#fff">
  //                   <path d="M18,12.25 C18.3796958,12.25 18.693491,12.5321539 18.7431534,12.8982294 L18.75,13 L18.75,16 C18.75,17.4625318 17.6082954,18.6584043 16.1675223,18.7449812 L16,18.75 L13,18.75 C12.5857864,18.75 12.25,18.4142136 12.25,18 C12.25,17.6203042 12.5321539,17.306509 12.8982294,17.2568466 L13,17.25 L16,17.25 C16.6472087,17.25 17.1795339,16.7581253 17.2435464,16.1278052 L17.25,16 L17.25,13 C17.25,12.5857864 17.5857864,12.25 18,12.25 Z M0,12.25 C0.379695766,12.25 0.693490961,12.5321539 0.743153384,12.8982294 L0.75,13 L0.75,16 C0.75,16.6472087 1.24187466,17.1795339 1.87219476,17.2435464 L2,17.25 L5,17.25 C5.41421356,17.25 5.75,17.5857864 5.75,18 C5.75,18.3796958 5.46784612,18.693491 5.10177056,18.7431534 L5,18.75 L2,18.75 C0.537468162,18.75 -0.658404279,17.6082954 -0.744981206,16.1675223 L-0.75,16 L-0.75,13 C-0.75,12.5857864 -0.414213562,12.25 0,12.25 Z M5,-0.75 C5.41421356,-0.75 5.75,-0.414213562 5.75,0 C5.75,0.379695766 5.46784612,0.693490961 5.10177056,0.743153384 L5,0.75 L2,0.75 C1.35279131,0.75 0.820466101,1.24187466 0.756453609,1.87219476 L0.75,2 L0.75,5 C0.75,5.41421356 0.414213562,5.75 0,5.75 C-0.379695766,5.75 -0.693490961,5.46784612 -0.743153384,5.10177056 L-0.75,5 L-0.75,2 C-0.75,0.537468162 0.391704595,-0.658404279 1.83247767,-0.744981206 L2,-0.75 L5,-0.75 Z M16,-0.75 C17.4625318,-0.75 18.6584043,0.391704595 18.7449812,1.83247767 L18.75,2 L18.75,5 C18.75,5.41421356 18.4142136,5.75 18,5.75 C17.6203042,5.75 17.306509,5.46784612 17.2568466,5.10177056 L17.25,5 L17.25,2 C17.25,1.35279131 16.7581253,0.820466101 16.1278052,0.756453609 L16,0.75 L13,0.75 C12.5857864,0.75 12.25,0.414213562 12.25,0 C12.25,-0.379695766 12.5321539,-0.693490961 12.8982294,-0.743153384 L13,-0.75 L16,-0.75 Z" id="Shape"></path>
  //               </g>
  //             </g>
  //           </svg>
  //       </button>
  //     </div>`;
  //   this.winners.forEach((winner, index) => {
  //     this.player = new Plyr(document.getElementById(this.round + index), {
  //       controls,
  //       urls: { vimeo: { api: "" } }
  //     });
  //     window.player = this.player;
  //   });
  // },
  // updated() {
  //   this.winners.forEach((winner, index) => {
  //     if (typeof this.player.embed === "undefined") {
  //       const controls = `<div class="plyr__controls" style="display: flex;justify-content: space-evenly;width: 100%;height: 100%;align-items: flex-end;flex-wrap: wrap;">
  //         <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play" style="width: 100%;height: 50px;background-color: transparent;padding: 0">
  //             <svg class="icon--pressed" role="presentation" width="35px" height="35px" viewBox="0 0 35 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: 0 auto;width:100%;height:100%">
  //               <g id="SHAGA3APP" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  //                   <g id="pause-icon" fill="#C4C4C4" fill-rule="nonzero">
  //                       <path d="M34.0529,8.62266 C34.7788,11.6788 35.0329,14.7713 34.9966,17.5 C35.0329,20.2287 34.7788,23.3212 34.0529,26.3773 C34.0529,26.3773 32.9276,31.762 28.0634,33.6538 C25.7765,34.5634 21.6021,34.9636 17.5002,35 C13.3984,34.9636 9.2239,34.527 6.93702,33.6538 C2.07287,31.762 0.94758,26.3773 0.94758,26.3773 C0.185288,23.3576 -0.0325102,20.2287 0.00378941,17.5 C-0.0325102,14.7713 0.221587,11.6788 0.94758,8.62266 C0.94758,8.62266 2.07287,3.23805 6.93702,1.34615 C9.2239,0.472973 13.3984,0.0363825 17.5002,0 C21.6021,0.0363825 25.7765,0.472973 28.0634,1.34615 C32.9276,3.23805 34.0529,8.62266 34.0529,8.62266 Z M14,10 C12.8954305,10 12,10.8954305 12,12 L12,24 C12,25.1045695 12.8954305,26 14,26 C15.1045695,26 16,25.1045695 16,24 L16,12 C16,10.8954305 15.1045695,10 14,10 Z M21,10 C19.8954305,10 19,10.8954305 19,12 L19,24 C19,25.1045695 19.8954305,26 21,26 C22.1045695,26 23,25.1045695 23,24 L23,12 C23,10.8954305 22.1045695,10 21,10 Z" id="Combined-Shape"></path>
  //                   </g>
  //               </g>
  //             </svg>
  //             <svg class="icon--not-pressed" role="presentation" width="49px" height="50px" viewBox="0 0 49 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: 0 auto;width:100%;height:100%">
  //               <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  //                 <g id="elements/video" transform="translate(-135.000000, -75.000000)">
  //                   <g id="controls/player-cion" transform="translate(135.000000, 75.000000)">
  //                     <path d="M35.4740153,2.30682432 C34.1451563,1.68434109 32.7340824,1.18601895 31.2515321,0.828971967 C29.6112636,0.434005524 27.8877737,0.191723645 26.1327402,0.0746095511 C15.2018677,-0.657269644 2.94683411,3.9910525 0.487102568,14.2095089 C-0.978669244,20.2987706 0.977371024,28.6165559 4.82837774,35.6591733 C6.11428378,38.0108512 7.60790794,40.2212538 9.26126364,42.1568243 C12.298512,45.7138713 15.8599214,48.3494418 19.596163,49.2487706 C31.4548878,52.1034686 44.700861,39.3296431 47.5555589,27.4705827 C50.05321,17.0937371 44.7766999,6.66420687 35.4740153,2.30682432" id="Fill-1" fill="${this.color}"></path>
  //                     <path d="M22.1521361,11.061321 L38.5044851,22.0489049 C40.4759616,23.3737371 40.4930757,26.261321 38.5366999,27.5676968 L22.2185791,38.4640055 C20.0155589,39.9351465 17.037371,38.3287706 17.0330086,35.6663545 L16.9987756,13.7824619 C16.994418,11.1327975 19.942069,9.57642163 22.1521361,11.061321" id="Fill-4" fill="#FFFFFF"></path>
  //                   </g>
  //                 </g>
  //               </g>
  //             </svg>
  //         </button>
  //         <div style="width: 80%;">
  //         </div>
  //         <button type="button" class="plyr__control" data-plyr="fullscreen" style="background-color: rgba(17,17,17,0.8);padding:5px;width: 34px;height:34px;">
  //             <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="icon--pressed" role="presentation" style="box-shadow:none;width: 100%">
  //                 <g id="Icons/expand" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  //                     <g id="maximize" transform="translate(3.000000, 3.000000)" fill="#fff">
  //                         <path d="M18,12.25 C18.3796958,12.25 18.693491,12.5321539 18.7431534,12.8982294 L18.75,13 L18.75,16 C18.75,17.4625318 17.6082954,18.6584043 16.1675223,18.7449812 L16,18.75 L13,18.75 C12.5857864,18.75 12.25,18.4142136 12.25,18 C12.25,17.6203042 12.5321539,17.306509 12.8982294,17.2568466 L13,17.25 L16,17.25 C16.6472087,17.25 17.1795339,16.7581253 17.2435464,16.1278052 L17.25,16 L17.25,13 C17.25,12.5857864 17.5857864,12.25 18,12.25 Z M0,12.25 C0.379695766,12.25 0.693490961,12.5321539 0.743153384,12.8982294 L0.75,13 L0.75,16 C0.75,16.6472087 1.24187466,17.1795339 1.87219476,17.2435464 L2,17.25 L5,17.25 C5.41421356,17.25 5.75,17.5857864 5.75,18 C5.75,18.3796958 5.46784612,18.693491 5.10177056,18.7431534 L5,18.75 L2,18.75 C0.537468162,18.75 -0.658404279,17.6082954 -0.744981206,16.1675223 L-0.75,16 L-0.75,13 C-0.75,12.5857864 -0.414213562,12.25 0,12.25 Z M5,-0.75 C5.41421356,-0.75 5.75,-0.414213562 5.75,0 C5.75,0.379695766 5.46784612,0.693490961 5.10177056,0.743153384 L5,0.75 L2,0.75 C1.35279131,0.75 0.820466101,1.24187466 0.756453609,1.87219476 L0.75,2 L0.75,5 C0.75,5.41421356 0.414213562,5.75 0,5.75 C-0.379695766,5.75 -0.693490961,5.46784612 -0.743153384,5.10177056 L-0.75,5 L-0.75,2 C-0.75,0.537468162 0.391704595,-0.658404279 1.83247767,-0.744981206 L2,-0.75 L5,-0.75 Z M16,-0.75 C17.4625318,-0.75 18.6584043,0.391704595 18.7449812,1.83247767 L18.75,2 L18.75,5 C18.75,5.41421356 18.4142136,5.75 18,5.75 C17.6203042,5.75 17.306509,5.46784612 17.2568466,5.10177056 L17.25,5 L17.25,2 C17.25,1.35279131 16.7581253,0.820466101 16.1278052,0.756453609 L16,0.75 L13,0.75 C12.5857864,0.75 12.25,0.414213562 12.25,0 C12.25,-0.379695766 12.5321539,-0.693490961 12.8982294,-0.743153384 L13,-0.75 L16,-0.75 Z" id="Shape"></path>
  //                     </g>
  //                 </g>
  //               </svg>
  //             <svg class="icon--not-pressed" role="presentation" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" style="box-shadow:none; width: 100%">
  //               <g id="Icons/expand" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  //                 <g id="maximize" transform="translate(3.000000, 3.000000)" fill="#fff">
  //                     <path d="M18,12.25 C18.3796958,12.25 18.693491,12.5321539 18.7431534,12.8982294 L18.75,13 L18.75,16 C18.75,17.4625318 17.6082954,18.6584043 16.1675223,18.7449812 L16,18.75 L13,18.75 C12.5857864,18.75 12.25,18.4142136 12.25,18 C12.25,17.6203042 12.5321539,17.306509 12.8982294,17.2568466 L13,17.25 L16,17.25 C16.6472087,17.25 17.1795339,16.7581253 17.2435464,16.1278052 L17.25,16 L17.25,13 C17.25,12.5857864 17.5857864,12.25 18,12.25 Z M0,12.25 C0.379695766,12.25 0.693490961,12.5321539 0.743153384,12.8982294 L0.75,13 L0.75,16 C0.75,16.6472087 1.24187466,17.1795339 1.87219476,17.2435464 L2,17.25 L5,17.25 C5.41421356,17.25 5.75,17.5857864 5.75,18 C5.75,18.3796958 5.46784612,18.693491 5.10177056,18.7431534 L5,18.75 L2,18.75 C0.537468162,18.75 -0.658404279,17.6082954 -0.744981206,16.1675223 L-0.75,16 L-0.75,13 C-0.75,12.5857864 -0.414213562,12.25 0,12.25 Z M5,-0.75 C5.41421356,-0.75 5.75,-0.414213562 5.75,0 C5.75,0.379695766 5.46784612,0.693490961 5.10177056,0.743153384 L5,0.75 L2,0.75 C1.35279131,0.75 0.820466101,1.24187466 0.756453609,1.87219476 L0.75,2 L0.75,5 C0.75,5.41421356 0.414213562,5.75 0,5.75 C-0.379695766,5.75 -0.693490961,5.46784612 -0.743153384,5.10177056 L-0.75,5 L-0.75,2 C-0.75,0.537468162 0.391704595,-0.658404279 1.83247767,-0.744981206 L2,-0.75 L5,-0.75 Z M16,-0.75 C17.4625318,-0.75 18.6584043,0.391704595 18.7449812,1.83247767 L18.75,2 L18.75,5 C18.75,5.41421356 18.4142136,5.75 18,5.75 C17.6203042,5.75 17.306509,5.46784612 17.2568466,5.10177056 L17.25,5 L17.25,2 C17.25,1.35279131 16.7581253,0.820466101 16.1278052,0.756453609 L16,0.75 L13,0.75 C12.5857864,0.75 12.25,0.414213562 12.25,0 C12.25,-0.379695766 12.5321539,-0.693490961 12.8982294,-0.743153384 L13,-0.75 L16,-0.75 Z" id="Shape"></path>
  //                 </g>
  //               </g>
  //             </svg>
  //         </button>
  //       </div>`;
  //       this.winners.forEach((winner, index) => {
  //         this.player = new Plyr(document.getElementById(this.round + index), {
  //           controls,
  //           urls: { vimeo: { api: "" } }
  //         });
  //         window.player = this.player;
  //       });
  //     } else if (winner.video_id) {
  //       this.player.embed.element.src =
  //         "https://player.vimeo.com/video/" +
  //         winner.video_id.uri +
  //         "?loop=false&autoplay=false&muted=false&gesture=media&playsinline=true&byline=false&portrait=false&title=false&speed=true&transparent=false";
  //       window.player = this.player;
  //       this.player.on("ended", event => {
  //       });
  //     }
  //   });
  // }
};
</script>

<style scoped>
/* winner data */
.winner-data-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 10px 20px;
}
/* name */
.winner-user-name {
  text-decoration: none;
  text-transform: capitalize;
  width: 100%;
  text-align: start;
  display: flex;
  align-items: center;
}
.winner-user-name a {
  flex: 2;
  display: block;
  text-decoration: none;
}
.card-title {
  color: #101010;
  font-family: Cairo;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
}

.user-image {
  width: 35px;
  height: 35px;
  display: block;
  margin: 0 5px 0 0 !important;
}
/* gift */
.winner-use-gift {
  text-align: end;
  width: 10%;
  font-size: 0.9rem;
}
.user-gift-image {
  width: 30px;
  display: inline-block;
  margin: 5px !important;
}
.winner-user-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  margin: 10px 20px;
}
.winner-user-caption {
  width: 80%;
}
.winner-user-caption p {
  text-align: start;
  color: #101010;
  font-family: Cairo;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 26px;
}
/* like */
.winner-user-likes {
  width: 20%;
  text-align: end;
  color: #101010;
  font-family: Cairo;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 23px;
}
.likes-image {
  margin: 0 2px;
}
/* video */
/* video section */
.ribbon-right {
  width: auto;
  height: 34px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  padding: 5px 8px;
  border-radius: 15px;
  text-align: left;
  background-color: rgba(17, 17, 17, 0.8);
  text-align: start;
}
.ribbon-left {
  width: auto;
  height: 34px;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 10;
  padding: 5px 8px;
  border-radius: 15px;
  text-align: left;
  background-color: rgba(17, 17, 17, 0.8);
  text-align: start;
}
.ribbon-text {
  color: #ffffff;
  font-family: Cairo;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  margin: 0 3px;
}
</style>
