import axios from "../../axios-auth";
import router from "../../router";
const state = {
  loginStatus: 0,
  user: null,
  phone: null,
  email: "",
  code: "",
  loginError: false
};

const mutations = {
  loginStatus(state, data) {
    state.loginStatus = data;
  },
  email(state, data) {
    state.email = data;
  },
  phone(state, data) {
    state.phone = data;
  },
  user(state, data) {
    state.user = data;
  },
  loginError(state, data) {
    state.loginError = data;
  }
};

const actions = {
  sendPhone({ commit, rootState }, phone) {
    commit("loading", true);
    if (typeof rootState.token !== "undefined") {
      const url = "/guestAuth/requestSmsCode";
      let data = {
        phone
      };
      axios
        .post(url, data, {
          headers: {
            "X-Auth-Token": rootState.token
          }
        })
        .then(res => {
          if (res) {
            let message =
              "Enter the code you received on your phone Number here";
            let lang = localStorage.getItem("lang");
            if (lang === "1") {
              message = "دخل رمز التعريف الذي تلقيته على تلفونك";
            }
            notie.alert({ type: "info", text: message, time: 5 });
            commit("loginStatus", 1);
            commit("phone", phone);
          } else {
            commit("loginError", true);
          }
          commit("loading", false);
        })
        .catch(error => {
          console.log(error);
          commit("loading", false);
          console.log("send email error: " + error);
        });
    } else {
      commit("status", 400);
      router.push("/error");
      commit("loading", false);
    }
  },
  sendSmsCode({ commit, rootState, state, dispatch }, code) {
    commit("loading", true);
    if (typeof rootState.token !== "undefined") {
      const url = "/guestAuth/confirmSmsCode";
      let data = {
        phone: state.phone,
        code
      };
      axios
        .post(url, data, { headers: { "X-Auth-Token": rootState.token } })
        .then(res => {
          if (res) {
            if (!res.data.error) {
              commit("userData", res.data.user);
              commit("token", res.data.token);
              localStorage.setItem("token", res.data.token);

              if (res.data.user.name) {
                dispatch("login", res.data.token);
                let competetionId = localStorage.getItem("competitionId");
                if (competetionId) {
                  router.push({ name: "home", params: { id: competetionId } });
                } else {
                  router.push({ name: "competitions" });
                }
              } else {
                commit("loginStatus", 2);
              }
            } else {
              // commit("loginError", true);
            }
            commit("loading", false);
          }
        })
        .catch(error => {
          console.log(error);
          commit("loading", false);
          console.log("send code error: " + error);
        });
    } else {
      commit("status", 400);
      router.push("/error");
      commit("loading", false);
    }
  },
  sendEmail({ commit, rootState }, email) {
    commit("loading", true);
    if (typeof rootState.token !== "undefined") {
      const url = "/guestAuth/requestCode";
      let data = {
        email
      };
      axios
        .post(url, data, {
          headers: {
            "X-Auth-Token": rootState.token
          }
        })
        .then(res => {
          if (res) {
            let message =
              "Enter the code you received on your email address here. Please make sure your email address is correct and also check all your email folders";
            let lang = localStorage.getItem("lang");
            if (lang === "1") {
              message =
                "دخل رمز التعريف الذي تلقيته على عنوان بريدك الإلكتروني هنا. يرجى التأكد من صحة عنوان بريدك الإلكتروني وكذلك البحث في البريد الوارد و أيضا جميع مجلدات بريدك الإلكتروني";
            }
            notie.alert({ type: "info", stay: true, text: message });
            commit("loginStatus", 1);
            commit("email", email);
          } else {
            commit("loginError", true);
          }
          commit("loading", false);
        })
        .catch(error => {
          console.log(error);
          commit("loading", false);
          console.log("send email error: " + error);
        });
    } else {
      commit("status", 400);
      router.push("/error");
      commit("loading", false);
    }
  },
  sendCode({ commit, rootState, state }, code) {
    commit("loading", true);
    if (typeof rootState.token !== "undefined") {
      const url = "/guestAuth/confirmCode";
      let data = {
        email: state.email,
        code
      };
      axios
        .post(url, data, { headers: { "X-Auth-Token": rootState.token } })
        .then(res => {
          if (res) {
            if (!res.data.error) {
              commit("userData", res.data.user);
              commit("token", res.data.token);
              localStorage.setItem("token", res.data.token);
              if (res.data.user.name) {
                let competetionId = localStorage.getItem("competitionId");
                if (competetionId) {
                  router.push({ name: "home", params: { id: competetionId } });
                } else {
                  router.push({ name: "competitions" });
                }
              } else {
                commit("loginStatus", 2);
              }
            } else {
              // commit("loginError", true);
            }
            commit("loading", false);
          }
        })
        .catch(error => {
          console.log(error);
          commit("loading", false);
          console.log("send code error: " + error);
        });
    } else {
      commit("status", 400);
      router.push("/error");
      commit("loading", false);
    }
  },
  MethodSelected({ commit }, data) {
    commit("loginStatus", data);
  },
  fbLogin({ commit, rootState, state }, fbData) {
    commit("loading", true);
    if (typeof rootState.token !== "undefined") {
      const url = "/guest/confirmFacebook";
      let data = { ...fbData };
      axios
        .post(url, data, { headers: { "X-Auth-Token": rootState.token } })
        .then(res => {
          if (!res.data.error) {
            commit("userData", res.data.user);
            commit("token", res.data.token);
            localStorage.setItem("token", res.data.token);
            if (res.data.user.email && res.data.user.name) {
              let competetionId = localStorage.getItem("competitionId");
              if (competetionId) {
                router.push({ name: "home", params: { id: competetionId } });
              } else {
                router.push("/");
              }
            } else {
              commit("loginStatus", 3);
            }
          } else {
            commit("loginError", true);
          }
          commit("loading", false);
        })
        .catch(error => {
          commit("loading", false);
          console.log("send code error: " + error);
        });
    } else {
      commit("status", 400);
      router.push("/error");
      commit("loading", false);
    }
  },
  sendInfo({ commit, rootState, dispatch }, data) {
    commit("loading", true);
    if (typeof rootState.token !== "undefined") {
      const url = "/profile";
      axios
        .put(url, data, { headers: { "X-Auth-Token": rootState.token } })
        .then(res => {
          let token = localStorage.getItem("token");
          dispatch("login", token);
          let competetionId = localStorage.getItem("competitionId");
          if (competetionId) {
            router.push({ name: "home", params: { id: competetionId } });
          } else {
            router.push({ name: "competitions" });
          }
          commit("loading", false);
        })
        .catch(error => {
          console.log("send info error: " + error);
          commit("loading", false);
          router.push("/error");
        });
    } else {
      commit("status", 400);
      router.push("/error");
      commit("loading", false);
    }
  },
  resetError({ commit }) {
    commit("loginError", false);
  }
};

const getters = {
  loginStatus: state => {
    return state.loginStatus;
  },
  loginError: state => {
    return state.loginError;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
