import axios from "axios";
import router from "../../router";
const state = {
  progress: 0,
};

const mutations = {
  progress(state, data) {
    state.progress = data;
  },
};

const actions = {
  checkGuest({ rootState }) {
    if (rootState.user.uuid === "guest") {
      let message = "";
      let submitText;
      let cancelText;
      let lang = localStorage.getItem("lang");
      if (lang === "0") {
        message = "you need to be logged in or to be a member";
        (submitText = "go to login"), (cancelText = "back to home");
      } else {
        message = "تحتاج إلى تسجيل الدخول أو أن تكون عضوا";
        (submitText = "تسجيل الدخول"), (cancelText = "الصفحة الرئيسية");
      }
      notie.setOptions({
        overlayClickDismiss: false,
      });
      notie.confirm(
        {
          text: message,
          submitText, // optional, default = 'Yes'
          cancelText, // optional, default = 'Cancel'
        },
        () => {
          let slug = localStorage.getItem("sl");
          if (slug !== "degla") {
            let dev = window.location.host.split(".")[0];
            if (dev !== "dev") {
              window.open(
                "https://" + slug + ".shaga3app.com/?resignin=true",
                "_top"
              );
            } else {
              window.open(
                "https://dev." + slug + ".shaga3app.com/?resignin=true",
                "_top"
              );
            }
          } else {
            router.push({ name: "login" });
          }
        },
        () => {
          let competetionId = localStorage.getItem("competitionId");
          if (competetionId) {
            router.push({ name: "home", params: { id: competetionId } });
          } else {
            router.push({ name: "competitions" });
          }
        }
      );
    } else {
      if (!rootState.user.member) {
        if (
          !rootState.user.membership_id ||
          rootState.user.membership_id === ""
        ) {
          let message = "";
          let submitText;
          let cancelText;
          let lang = localStorage.getItem("lang");
          if (lang === "0") {
            message =
              "You need to enter member ship number so you can upload. Go to profile & click edit & add your membership";
            (submitText = "go to profile"), (cancelText = "back to home");
          } else {
            message =
              "لازم تبقي عضو علشان ترفع ڤيديو..روح علي حسابك ودوس تعديل وحط رقم عضويتك";
            (submitText = "الذهب الى الحساب"), (cancelText = "الصفحة الرئيسية");
          }
          notie.setOptions({
            overlayClickDismiss: false,
          });
          notie.confirm(
            {
              text: message,
              submitText, // optional, default = 'Yes'
              cancelText, // optional, default = 'Cancel'
            },
            () => {
              let competetionId = localStorage.getItem("competitionId");
              if (competetionId) {
                router.push({
                  name: "me",
                  params: { id: competetionId, userId: rootState.user._id },
                });
              } else {
                router.push({ name: "competitions" });
              }
            },
            () => {
              let competetionId = localStorage.getItem("competitionId");
              if (competetionId) {
                router.push({ name: "home", params: { id: competetionId } });
              } else {
                router.push({ name: "competitions" });
              }
            }
          );
        }
      }
    }
  },
  uploadVideoHandler({ commit, rootState }, data) {
    if (typeof data.file === "undefined") {
      let message = "please choose a video";
      if (rootState.curLanguage.short === "ar") {
        message = "يرجى اختيار الفيديو";
      }
      notie.alert({ type: "error", text: message });
    } else if (typeof rootState.token !== "undefined") {
      const url = process.env.VUE_APP_API_URL + "videos/upload";
      commit("isUploading", true);
      let formData = new FormData();
      formData.append("video", data.file);
      let params = {
        competition_id: data.competition_id,
        caption: data.caption,
      };
      axios
        .post(url, formData, {
          params,
          headers: {
            "X-Auth-Token": rootState.token,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) =>
            commit(
              "progress",
              Math.floor((progressEvent.loaded * 100) / progressEvent.total)
            ),
        })
        .then((res) => {
          commit("isUploading", false);
          commit("progress", 101);
        })
        .catch((error) => {
          console.log("error upload video: " + error);
          commit("loading", false);
          commit("isUploading", false);
          commit("progress", 200);
          if (error.response.status === 469) {
            let message = "";
            let submitText;
            let cancelText;
            let lang = localStorage.getItem("lang");
            if (lang === "0") {
              message = "you need to be logged in or to be a member";
              (submitText = "go to login"), (cancelText = "back to home");
            } else {
              message = "تحتاج إلى تسجيل الدخول أو أن تكون عضوا";
              (submitText = "تسجيل الدخول"),
                (cancelText = "الرجوع الى الصفحة الرئيسية");
            }
            notie.setOptions({
              overlayClickDismiss: false,
            });
            notie.confirm(
              {
                text: message,
                submitText, // optional, default = 'Yes'
                cancelText, // optional, default = 'Cancel'
              },
              () => {
                router.push({ name: "login" });
              },
              () => {
                let competetionId = localStorage.getItem("competitionId");
                if (competetionId) {
                  router.push({
                    name: "home",
                    params: { id: competetionId },
                  });
                } else {
                  router.push({ name: "competitions" });
                }
              }
            );
          } else if (error.response.status === 470) {
            let message = "";
            let lang = localStorage.getItem("lang");
            if (lang === "0") {
              message = "only members has access to upload videos";
            } else {
              message = "تحتاج إلى أن تكون عضوا";
            }
            notie.alert({ type: "error", text: message });
            commit("progress", -1);
          } else if (error.response.status === 472) {
            let message =
              "you can not upload more than " +
              rootState.videosPerUser +
              " videos";
            if (rootState.curLanguage.short === "ar") {
              const arabicNums = [
                "٠",
                "١",
                "٢",
                "٣",
                "٤",
                "٥",
                "٦",
                "٧",
                "٨",
                "٩",
              ];
              let limit = rootState.videosPerUser
                .toString()
                .replace(/[0-9]/g, function (w) {
                  return arabicNums[+w];
                });
              message = "لا يمكنك تحميل أكثر من " + limit + "  فيديوهات";
            }
            notie.alert({ type: "error", text: message });
            commit("progress", -1);
          } else {
            let message = "something went wrong please try again";
            if (rootState.curLanguage.short === "ar") {
              message = "حدث خطأ ما. أعد المحاولة من فضلك";
            }
            notie.alert({ type: "error", text: message });
            commit("progress", -1);
          }
        });
    } else {
      commit("status", 400);
      commit("loading", false);
      router.push("/error");
    }
  },
  resetProgress({ commit }) {
    commit("progress", 0);
  },
};

const getters = {
  progress: (state) => {
    return state.progress;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
