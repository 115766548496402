import axios from '../../axios-auth'
import router from '../../router'
const state = {
  page: 0,
  pageCount: -1,
  searchVideos: []
}

const mutations = {
  resetSearch (state) {
    state.searchVideos = []
    state.page = 0
    state.pageCount = -1
  },
  searchVideos (state, data) {
    state.searchVideos = data.videos
    state.page = 2
    state.pageCount = data.pageCount
  },
  searchVideosAdd (state, data) {
    data.videos.map(d => {
      state.searchVideos.push(d)
    })
    state.page++
    state.pageCount = data.pageCount
  },
  // like
  likeHandlerSearch (state, data) {
    if (state.searchVideos !== 0) {
      let videos = [...state.searchVideos]
      videos.map(v => {
        if (v._id === data.videoId) {
          if (!v.isLiked) {
            v.isLiked = true
            v.likes++
          } 
        }
      })
      state.searchVideos = [...videos]
    }
  },
  // comment
  lastCommentSearch (state, data) {
    if (state.searchVideos.length !== 0) {
      let videos = [...state.searchVideos]
      videos.map(v => {
        if (v._id === data.id) {
          v.lastComment = data.value
          v.commentsCount++
        }
      })
      state.searchVideos = [...videos]
    }
  },
  deleteLastcommentSearch (state, data) {
    if (state.searchVideos.length !== 0) {
      let videos = [...state.searchVideos]
      videos.map((v, index) => {
        if (v._id === data.id) {
          v.lastComment = data.lastComment
          v.commentsCount--
        }
      })
      state.searchVideos = [...videos]
    }
  },
  // delete
  deleteVideoSearch (state, id) {
    if (state.searchVideos.length !== 0) {
      let videos = [...state.searchVideos]
      videos.map((v, index) => {
        if (v._id === id) {
          videos.splice(index, 1)
        }
      })
      state.searchVideos = [...videos]
    }
  },
  searchFollow (state, data) {
    if (state.searchVideos.length !== 0) {
      let videos = [...state.searchVideos]
      videos.map((v, index) => {
        if (v.user._id === data.id) {
          v.user.isFollowed = data.followed;
          if(data.followed){
            v.user.followerCount++
          }else {
            v.user.followerCount--
          }
        }
      })
      state.searchVideos = [...videos]
    }
  }

}

const actions = {
  fetchSearch ({ commit, rootState }, search) {
    if (typeof (rootState.token) !== 'undefined') {
      const competitionId = localStorage.getItem('competitionId')
      if (competitionId) {
        if (search.length >= 3) {
          commit('resetSearch')
          commit('loading', true)
          const url = '/search'
          const params = {
            s: search,
            page: 1,
            perPage: 10,
            competition_id: competitionId
          }
          axios.get(url, { params, headers: { 'X-Auth-Token': rootState.token } })
            .then(res => {
              let data = {
                videos: res.data.videos,
                pageCount: res.data.pageCount
              }
              commit('searchVideos', data)
              commit('loading', false)
            })
            .catch(error => {
              console.log('ERROR fetchSearch: ' + error)
              commit('loading', false)
            })
        } else {
          let message = 'You have to enter more then 2 charaters'
          if(rootState.curLanguage.short === 'ar'){
            message = 'يجب عليك إدخال أكثر من حرفين'
          }
          notie.alert({ type: 'error', text: message })// error message more than 3 
          console.log('error you have to enter more character')
          
        }
      } else {
        router.push({ name: 'competitons' })
      }
    } else {
      commit('status', 400)
      router.push('/error')
    }
  },
  infiniteLoadSearch ({ commit, rootState, state }, data) {
    if (typeof (rootState.token) !== 'undefined') {
      const competitionId = localStorage.getItem('competitionId')
      if (competitionId) {
        if (data.search.length >= 3) {
          if (state.pageCount <= 0 || state.pageCount < state.page) {
            if (state.pageCount === -1) {
              data.loaderState.reset()
            } else {
              data.loaderState.complete()
            }
          } else {
            let page = state.page
            const url = '/search'
            const params = {
              s: data.search,
              page,
              perPage: 10,
              competition_id: competitionId
            }
            axios.get(url, { params, headers: { 'X-Auth-Token': rootState.token } })
              .then(res => {
                let resData = {
                  videos: res.data.videos,
                  pageCount: res.data.pageCount
                }
                if (res.data.videos.length === 0) {
                  data.loaderState.complete()
                }
                commit('searchVideosAdd', resData)
                commit('loading', false)
                data.loaderState.loaded()
                data.loaderState.reset()
              })
              .catch(error => {
                console.log('ERROR fetchHome: ' + error)
                commit('loading', false)
              })
          }
        } else {
          data.loaderState.complete()
        }
      } else {
        router.push({ name: 'competitons' })
      }
    } else {
      commit('status', 400)
      router.push('/error')
    }
  },
  resetSearch ({ commit }) {
    commit('resetSearch')
  }
}

const getters = {
  searchVideos: state => {
    return state.searchVideos
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
