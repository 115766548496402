<template>
  <div class="content" :style="{ maxHeight: screenHeight + 'px' }">
    <div class="col-12" v-if="loadingWinners">
      <div
        :style="{
          textAlign: 'center',
          width: '100%',
          height: screenHeight - 45 + 'px'
        }"
      >
        <loading
          :active.sync="loadingWinners"
          background-color="#fff"
          :can-cancel="false"
          :is-full-page="true"
          :color="color"
        ></loading>
      </div>
    </div>
    <div
      v-else-if="winners && winners.length > 0"
      style="padding-bottom: 100px"
    >
      <div v-for="(round, index) in winners" :key="index">
        <pull-to
          v-if="index === 0"
          :on-refresh="onRefresh"
          style="width: 100%;"
          :config="{
            errorLabel: $t('pullToRefreshError'),
            startLabel: $t('pullToRefreshLabel'),
            readyLabel: $t('pullToRefreshReady'),
            loadingLabel: $t('pullToRefreshLoading'),
            pullDownHeight: 100
          }"
        >
          <div class="round-title">
            <svg
              width="14"
              height="17"
              viewBox="0 0 14 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="margin: 8px 5px;width: 12px;height:auto;"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.54545 0.772727C1.54545 0.345962 1.19949 0 0.772727 0C0.345962 0 0 0.345962 0 0.772727V16.2273C0 16.654 0.345962 17 0.772727 17C1.19949 17 1.54545 16.654 1.54545 16.2273V8.5H7.72727V9.27273C7.72727 9.6995 8.07322 10.0455 8.5 10.0455H12.3636C12.6233 10.0455 12.8658 9.91494 13.0087 9.69811C13.1517 9.48129 13.1762 9.20705 13.0738 8.96835L12.0453 6.56818L13.0738 4.16803C13.1762 3.92929 13.1517 3.6551 13.0087 3.43826C12.8658 3.22141 12.6233 3.09091 12.3636 3.09091H9.27273V2.31818C9.27273 1.89142 8.92678 1.54545 8.5 1.54545H1.54545V0.772727ZM7.72727 3.09091H1.54545V6.95455H7.72727V3.09091ZM9.27273 8.5H11.1918L10.4943 6.87257C10.411 6.67819 10.411 6.45817 10.4943 6.26379L11.1918 4.63636H9.27273V8.5Z"
                :fill="color"
              />
            </svg>
            <!-- <img :src="roundImg" alt="rounds" style="margin: 8px 5px;width: 12px;height:auto;"> -->
            <span v-if="lang !== 'ar'"
              >{{ "Round " }}{{ winners.length - index }}</span
            >
            <span v-else
              >{{ "جولة " }}{{ arabicize(winners.length - index) }}</span
            >
          </div>
          <div v-if="round.winners.length > 0">
            <winners-card
              :winners="round.winners"
              :round="winners.length - index"
              :logos="round.winnerLogos"
            ></winners-card>
          </div>
          <div style="margin: 10px 20px;width: 95%;text-align: center;" v-else>
            <span class="no-winners-title">{{ $t("noWinnersRound") }}</span>
            <!-- <img :src="noWinnersImg" alt="rounds" style="margin: 8px 5px;width:20%;height:auto;"> -->
            <!-- <svg width="104" height="120" viewBox="0 0 104 120" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin: 10px auto;width: 140px">
                <path d="M101.25 94.6875H76.5V78.375C76.5 77.4375 75.5625 76.5 74.625 76.5H28.5C27.5625 76.5 26.625 77.4375 26.625 78.375V94.6875H1.875C0.9375 94.6875 0 95.625 0 96.5625V118.125C0 119.25 0.9375 120 1.875 120H101.25C102.188 120 103.125 119.25 103.125 118.125V96.5625C103.125 95.625 102.375 94.6875 101.25 94.6875ZM62.0625 98.8125L59.25 100.875C58.3125 101.438 58.125 102.563 58.3125 103.5L59.4375 106.688C60.1875 108.938 57.5625 110.813 55.6875 109.313L52.875 107.25C51.9375 106.688 50.8125 106.688 50.0625 107.25L47.25 109.313C45.375 110.625 42.75 108.75 43.5 106.688L44.625 103.5C45 102.563 44.625 101.438 43.6875 100.875L40.875 98.8125C39 97.5 39.9375 94.5 42.375 94.5H45.9375C47.0625 94.5 48 93.75 48.1875 92.8125L49.3125 89.625C50.0625 87.375 53.25 87.375 54 89.625L55.125 92.8125C55.5 93.75 56.4375 94.5 57.375 94.5H60.75C63 94.5 63.9375 97.5 62.0625 98.8125Z" :fill="color"/>
                <path d="M51.5627 21.75C57.5688 21.75 62.4377 16.8811 62.4377 10.875C62.4377 4.8689 57.5688 0 51.5627 0C45.5566 0 40.6877 4.8689 40.6877 10.875C40.6877 16.8811 45.5566 21.75 51.5627 21.75Z" :fill="color"/>
                <path d="M66.9376 35.25C66.1876 29.4375 61.3126 25.125 55.5001 25.125H47.4376C41.6251 25.125 36.7501 29.4375 36.0001 35.25L34.8751 44.625C34.6876 45.9375 35.4376 47.0625 36.7501 47.25C38.4376 47.625 40.1251 47.8125 42.0001 48.1875L43.3126 69.9375C43.3126 71.625 44.8126 72.9375 46.5001 72.9375H56.4376C58.1251 72.9375 59.6251 71.625 59.6251 69.9375L60.9376 48.1875C62.6251 48 64.5001 47.625 66.1876 47.25C67.3126 47.0625 68.2501 45.9375 68.0626 44.625L66.9376 35.25ZM51.9376 52.3125C50.8126 52.3125 49.8751 51.375 49.8751 50.25C49.8751 49.125 50.8126 48.1875 51.9376 48.1875C53.0626 48.1875 54.0001 49.125 54.0001 50.25C54.0001 51.375 53.0626 52.3125 51.9376 52.3125ZM56.6251 42C55.8751 43.125 55.1251 43.6875 54.5626 44.0625C54.0001 44.4375 53.6251 44.625 53.6251 45.75C53.6251 46.125 53.2501 46.6875 52.6876 46.6875H51.1876C50.6251 46.6875 50.2501 46.3125 50.2501 45.75C50.4376 43.3125 51.3751 42.375 52.6876 41.4375C53.0626 41.0625 53.6251 40.875 54.0001 40.125C54.3751 39.5625 54.5626 38.625 54.1876 37.875C54.0001 37.3125 53.2501 36.5625 51.5626 36.5625C49.5001 36.5625 48.9376 37.875 48.5626 38.8125C48.5626 39.1875 48.1876 39.5625 47.6251 39.5625H46.1251C45.5626 39.5625 45.1876 39.1875 45.1876 38.4375C45.5626 35.8125 47.6251 33.1875 51.3751 33.1875C53.8126 33.1875 55.8751 34.3125 56.8126 36.375C57.9376 38.25 57.7501 40.5 56.6251 42Z" :fill="color"/>
              </svg>
              <div>
                <span :style="{color: color}">{{$t('noWinners')}}</span>
              </div> -->
          </div>
        </pull-to>
        <div v-else>
          <div class="round-title">
            <svg
              width="14"
              height="17"
              viewBox="0 0 14 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="margin: 8px 5px;width: 12px;height:auto;"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.54545 0.772727C1.54545 0.345962 1.19949 0 0.772727 0C0.345962 0 0 0.345962 0 0.772727V16.2273C0 16.654 0.345962 17 0.772727 17C1.19949 17 1.54545 16.654 1.54545 16.2273V8.5H7.72727V9.27273C7.72727 9.6995 8.07322 10.0455 8.5 10.0455H12.3636C12.6233 10.0455 12.8658 9.91494 13.0087 9.69811C13.1517 9.48129 13.1762 9.20705 13.0738 8.96835L12.0453 6.56818L13.0738 4.16803C13.1762 3.92929 13.1517 3.6551 13.0087 3.43826C12.8658 3.22141 12.6233 3.09091 12.3636 3.09091H9.27273V2.31818C9.27273 1.89142 8.92678 1.54545 8.5 1.54545H1.54545V0.772727ZM7.72727 3.09091H1.54545V6.95455H7.72727V3.09091ZM9.27273 8.5H11.1918L10.4943 6.87257C10.411 6.67819 10.411 6.45817 10.4943 6.26379L11.1918 4.63636H9.27273V8.5Z"
                :fill="color"
              />
            </svg>
            <span v-if="lang !== 'ar'"
              >{{ "Round " }}{{ winners.length - index }}</span
            >
            <span v-else
              >{{ "جولة " }}{{ arabicize(winners.length - index) }}</span
            >
          </div>
          <div v-if="round.winners.length > 0">
            <winners-card
              :winners="round.winners"
              :round="winners.length - index"
              :logos="round.winnerLogos"
            ></winners-card>
          </div>
          <div style="margin: 10px 20px;width: 90%;text-align: center;" v-else>
            <span class="no-winners-title">{{ $t("noWinnersRound") }}</span>
            <!-- <img :src="noWinnersImg" alt="rounds" style="margin: 8px 5px;width:20%;height:auto;"> -->
            <!-- <svg width="104" height="120" viewBox="0 0 104 120" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin: 10px auto;width: 140px">
                <path d="M101.25 94.6875H76.5V78.375C76.5 77.4375 75.5625 76.5 74.625 76.5H28.5C27.5625 76.5 26.625 77.4375 26.625 78.375V94.6875H1.875C0.9375 94.6875 0 95.625 0 96.5625V118.125C0 119.25 0.9375 120 1.875 120H101.25C102.188 120 103.125 119.25 103.125 118.125V96.5625C103.125 95.625 102.375 94.6875 101.25 94.6875ZM62.0625 98.8125L59.25 100.875C58.3125 101.438 58.125 102.563 58.3125 103.5L59.4375 106.688C60.1875 108.938 57.5625 110.813 55.6875 109.313L52.875 107.25C51.9375 106.688 50.8125 106.688 50.0625 107.25L47.25 109.313C45.375 110.625 42.75 108.75 43.5 106.688L44.625 103.5C45 102.563 44.625 101.438 43.6875 100.875L40.875 98.8125C39 97.5 39.9375 94.5 42.375 94.5H45.9375C47.0625 94.5 48 93.75 48.1875 92.8125L49.3125 89.625C50.0625 87.375 53.25 87.375 54 89.625L55.125 92.8125C55.5 93.75 56.4375 94.5 57.375 94.5H60.75C63 94.5 63.9375 97.5 62.0625 98.8125Z" :fill="color"/>
                <path d="M51.5627 21.75C57.5688 21.75 62.4377 16.8811 62.4377 10.875C62.4377 4.8689 57.5688 0 51.5627 0C45.5566 0 40.6877 4.8689 40.6877 10.875C40.6877 16.8811 45.5566 21.75 51.5627 21.75Z" :fill="color"/>
                <path d="M66.9376 35.25C66.1876 29.4375 61.3126 25.125 55.5001 25.125H47.4376C41.6251 25.125 36.7501 29.4375 36.0001 35.25L34.8751 44.625C34.6876 45.9375 35.4376 47.0625 36.7501 47.25C38.4376 47.625 40.1251 47.8125 42.0001 48.1875L43.3126 69.9375C43.3126 71.625 44.8126 72.9375 46.5001 72.9375H56.4376C58.1251 72.9375 59.6251 71.625 59.6251 69.9375L60.9376 48.1875C62.6251 48 64.5001 47.625 66.1876 47.25C67.3126 47.0625 68.2501 45.9375 68.0626 44.625L66.9376 35.25ZM51.9376 52.3125C50.8126 52.3125 49.8751 51.375 49.8751 50.25C49.8751 49.125 50.8126 48.1875 51.9376 48.1875C53.0626 48.1875 54.0001 49.125 54.0001 50.25C54.0001 51.375 53.0626 52.3125 51.9376 52.3125ZM56.6251 42C55.8751 43.125 55.1251 43.6875 54.5626 44.0625C54.0001 44.4375 53.6251 44.625 53.6251 45.75C53.6251 46.125 53.2501 46.6875 52.6876 46.6875H51.1876C50.6251 46.6875 50.2501 46.3125 50.2501 45.75C50.4376 43.3125 51.3751 42.375 52.6876 41.4375C53.0626 41.0625 53.6251 40.875 54.0001 40.125C54.3751 39.5625 54.5626 38.625 54.1876 37.875C54.0001 37.3125 53.2501 36.5625 51.5626 36.5625C49.5001 36.5625 48.9376 37.875 48.5626 38.8125C48.5626 39.1875 48.1876 39.5625 47.6251 39.5625H46.1251C45.5626 39.5625 45.1876 39.1875 45.1876 38.4375C45.5626 35.8125 47.6251 33.1875 51.3751 33.1875C53.8126 33.1875 55.8751 34.3125 56.8126 36.375C57.9376 38.25 57.7501 40.5 56.6251 42Z" :fill="color"/>
              </svg>
              <div>
                <span :style="{color: color}">{{$t('noWinners')}}</span>
              </div> -->
          </div>
        </div>
      </div>
    </div>
    <div
      :style="{
        textAlign: 'center',
        width: '100%',
        height: screenHeight - 45 + 'px'
      }"
      v-else
    >
      <!-- <img ="noFollowersImg" alt="no fans" style="width: 100%;width: 100%;height: 100%;object-fit: cover;"> -->
      <div class="no-winners-container">
        <span class="no-winners-title">{{ $t("noWinnersTitle") }}</span>
        <span class="no-winners-subtitle">{{ $t("noWinnersSub") }}</span>
      </div>
    </div>
    <!-- <div style="margin-top: 20px;text-align: center;width: 100%" v-else>
        <svg width="104" height="120" viewBox="0 0 104 120" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin: 10px auto;width: 140px">
          <path d="M101.25 94.6875H76.5V78.375C76.5 77.4375 75.5625 76.5 74.625 76.5H28.5C27.5625 76.5 26.625 77.4375 26.625 78.375V94.6875H1.875C0.9375 94.6875 0 95.625 0 96.5625V118.125C0 119.25 0.9375 120 1.875 120H101.25C102.188 120 103.125 119.25 103.125 118.125V96.5625C103.125 95.625 102.375 94.6875 101.25 94.6875ZM62.0625 98.8125L59.25 100.875C58.3125 101.438 58.125 102.563 58.3125 103.5L59.4375 106.688C60.1875 108.938 57.5625 110.813 55.6875 109.313L52.875 107.25C51.9375 106.688 50.8125 106.688 50.0625 107.25L47.25 109.313C45.375 110.625 42.75 108.75 43.5 106.688L44.625 103.5C45 102.563 44.625 101.438 43.6875 100.875L40.875 98.8125C39 97.5 39.9375 94.5 42.375 94.5H45.9375C47.0625 94.5 48 93.75 48.1875 92.8125L49.3125 89.625C50.0625 87.375 53.25 87.375 54 89.625L55.125 92.8125C55.5 93.75 56.4375 94.5 57.375 94.5H60.75C63 94.5 63.9375 97.5 62.0625 98.8125Z" :fill="color"/>
          <path d="M51.5627 21.75C57.5688 21.75 62.4377 16.8811 62.4377 10.875C62.4377 4.8689 57.5688 0 51.5627 0C45.5566 0 40.6877 4.8689 40.6877 10.875C40.6877 16.8811 45.5566 21.75 51.5627 21.75Z" :fill="color"/>
          <path d="M66.9376 35.25C66.1876 29.4375 61.3126 25.125 55.5001 25.125H47.4376C41.6251 25.125 36.7501 29.4375 36.0001 35.25L34.8751 44.625C34.6876 45.9375 35.4376 47.0625 36.7501 47.25C38.4376 47.625 40.1251 47.8125 42.0001 48.1875L43.3126 69.9375C43.3126 71.625 44.8126 72.9375 46.5001 72.9375H56.4376C58.1251 72.9375 59.6251 71.625 59.6251 69.9375L60.9376 48.1875C62.6251 48 64.5001 47.625 66.1876 47.25C67.3126 47.0625 68.2501 45.9375 68.0626 44.625L66.9376 35.25ZM51.9376 52.3125C50.8126 52.3125 49.8751 51.375 49.8751 50.25C49.8751 49.125 50.8126 48.1875 51.9376 48.1875C53.0626 48.1875 54.0001 49.125 54.0001 50.25C54.0001 51.375 53.0626 52.3125 51.9376 52.3125ZM56.6251 42C55.8751 43.125 55.1251 43.6875 54.5626 44.0625C54.0001 44.4375 53.6251 44.625 53.6251 45.75C53.6251 46.125 53.2501 46.6875 52.6876 46.6875H51.1876C50.6251 46.6875 50.2501 46.3125 50.2501 45.75C50.4376 43.3125 51.3751 42.375 52.6876 41.4375C53.0626 41.0625 53.6251 40.875 54.0001 40.125C54.3751 39.5625 54.5626 38.625 54.1876 37.875C54.0001 37.3125 53.2501 36.5625 51.5626 36.5625C49.5001 36.5625 48.9376 37.875 48.5626 38.8125C48.5626 39.1875 48.1876 39.5625 47.6251 39.5625H46.1251C45.5626 39.5625 45.1876 39.1875 45.1876 38.4375C45.5626 35.8125 47.6251 33.1875 51.3751 33.1875C53.8126 33.1875 55.8751 34.3125 56.8126 36.375C57.9376 38.25 57.7501 40.5 56.6251 42Z" :fill="color"/>
        </svg>
        <img :src="noWinnersImg" alt="no fans" style="margin: 10px auto;width: 140px">
        <h3 :style="{color:color}">{{$t('noWinners')}}</h3> 
      </div> -->
  </div>
</template>

<script>
import WinnersCard from "@/components/Winners/WinnersCard.vue";
import PullTo from "vue-pull-refresh";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "winners",
  data() {
    return {
      // roundImg:roundLogo,
      // noWinnersImg:noWinnerLogo
    };
  },
  created() {
    this.$store.dispatch("fetchWinners");
  },
  components: {
    WinnersCard,
    PullTo,
    Loading
  },
  computed: {
    screenHeight() {
      return this.$store.getters.screenHeight - 110;
    },
    lang() {
      return this.$store.getters.lang;
    },
    winners() {
      return this.$store.getters.winners;
    },
    color() {
      return this.$store.getters.primaryColor;
    },
    loadingWinners() {
      return this.$store.getters.loadingWinners;
    }
  },
  methods: {
    arabicize(value) {
      const arabicNums = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
      let newValue = value.toString().replace(/[0-9]/g, function(w) {
        return arabicNums[+w];
      });
      return newValue;
    },
    onRefresh() {
      this.$store.dispatch("fetchCountdown");
      this.$store.dispatch("fetchWinners");
    }
  }
};
</script>

<style scoped>
.content {
  overflow-y: scroll;
  /* -webkit-overflow-scrolling: touch; */
  margin: 110px 0 0 0;
  height: 50%;
  background-color: transparent;
  width: 100%;
}
.round-title {
  width: 95%;
  border-radius: 5px;
  margin: 0 auto;
  padding: 0 10px;
  text-align: start;
  background-color: #f6f6f6;
  color: #101010;
  font-family: Cairo;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37px;
}
.no-winners-container {
  position: absolute;
  bottom: 15%;
  left: 0;
  width: 100%;
}
.no-winners-title {
  display: block;
  color: #101010;
  font-family: Cairo;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37px;
  text-align: center;
  text-transform: capitalize;
}
.no-winners-subtitle {
  text-transform: capitalize;
  display: block;
  color: #6c6c6c;
  font-family: Cairo;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 19px;
  text-align: center;
}
</style>
