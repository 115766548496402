import axios from "../../axios-auth";
import router from "../../router";
const state = {
  winners: null,
  loadingWinners: false
};

const mutations = {
  winners(state, data) {
    state.winners = data;
  },
  loadingWinners(state, data) {
    state.loadingWinners = data;
  }
};

const actions = {
  fetchWinners({ commit, rootState }) {
    if (typeof rootState.token !== "undefined") {
      const competitionId = router.history.current.params.id;
      if (competitionId) {
        commit("loading", true);
        commit("loadingWinners", true);
        const url = "/winners/" + competitionId;
        axios
          .get(url, { headers: { "X-Auth-Token": rootState.token } })
          .then(res => {
            commit("winners", res.data.winners);
            commit("loading", false);
            commit("loadingWinners", false);
          })
          .catch(error => {
            console.log("ERROR fetchWinners: " + error);
            commit("loadingWinners", false);
            commit("loading", false);
          });
      }
    }
  }
};

const getters = {
  winners: state => {
    return state.winners;
  },
  loadingWinners: state => {
    return state.loadingWinners;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
